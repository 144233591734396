import { Component, Input } from "@angular/core";
import { CashRegisterUtilsService, CFSStatusInfo, ConnectionStatus, NetworkStatus } from "cfs-communication-pack";

@Component({
  selector: "app-terminal-status",
  templateUrl: "./terminal-status.component.html",
  styleUrls: ["./terminal-status.component.scss"]
})
export class TerminalStatusComponent {
  constructor(private cashRegisterUtils: CashRegisterUtilsService) {}

  private _status?: ConnectionStatus;
  private _networkStatus?: NetworkStatus;

  statusInfo: CFSStatusInfo | null = null;

  @Input() set status(status: ConnectionStatus) {
    this._status = status;

    if (this._networkStatus || this._status === ConnectionStatus.UNAVAILABLE) {
      this.statusInfo = this.cashRegisterUtils.getCashregisterStatusLabel(this._status, this._networkStatus);
    }
  }

  @Input() set networkStatus(networkStatus: NetworkStatus) {
    this._networkStatus = networkStatus;

    if (this._networkStatus || this._status === ConnectionStatus.UNAVAILABLE) {
      this.statusInfo = this.cashRegisterUtils.getCashregisterStatusLabel(this._status, this._networkStatus);
    }
  }
}
