export class VendastaProduct {
  public activationId: string;
  public activation: Date;
  public entryUrl: string;
  public iconUrl: string;
  public description: string;
  public name: string;
  public tagLine: string;
  public appId: string;
  public businessId: string;
  public partnerId: string;
  public productId: string;
}
