import { CashRegister } from "./cashregister";

export enum ConnectionStatus {
  PAIRED = "paired",
  NOT_PAIRED = "not_paired",
  UNAVAILABLE = "unavailable"
}

export enum NetworkStatus {
  ONLINE = "online",
  OFFLINE = "offline"
}

export interface CFSConnectionModalResult {
  closingReason: "confirm" | "dismiss";
  updatedCashRegister?: CashRegister;
  oldCode?: string;
  state?: CFSPairingState;
}

export interface CFSStatuses {
  status: ConnectionStatus;
  networkStatus?: NetworkStatus;
  pairingDate?: number;
}

export interface CFSStatusInfo {
  label: string;
  type: "successStatus" | "alertStatus" | "inertStatus";
}

export interface CFSConnectionMessage {
  type?: CFSMessageType;
  pinCode?: string;
  status?: ConnectionStatus;
  connectedCode?: string;
  generateQrCodeInfo?: CFSQrCodeInfo;
  hideQrCodeInfo?: DisplayQrCodeInfo;
  qrCodeScannedInfo?: DisplayQrCodeInfo;
  scanQrCodeInfo?: CFSScanQrCodeInfo;
  settings?: CFSConnectionSettings;
  templateInfo?: any; // should be ShowCfsTemplateInfo
  accountTemplateId?: number;
  result?: any;
  noEffect?: boolean;
  showWidgetSettings?: ShowWidgetSettings<number, unknown>; // TODO: probably CFSCP should known about Widget types?
}

export enum CFSCommunicationChannels {
  MESSAGE = "cfs-message-channel",
  PAIRING = "pairing",
  SET_STATUS = "status",
  RETURN_STATUS = "return-status",
  PIN_CODE = "cfs",
  CONNECTION_ESTABLISHED = "init-connection-established",
  SHOW_QR_CODE = "show-qr-code",
  HIDE_QR_CODE = "hide-qr-code",
  SCAN_QR_CODE = "scan-qr-code",
  UPDATE_CAROUSEL = "update-cfs-carousel",
  UPDATE_CFS = "update-cfs",
  SHOW_TEMPLATE = "show-template",
  RESET_CASH_REGISTER = "reset-cash-register"
}

export enum CMCCommunicationChannels {
  CONFIRM_PAIRING = "confirm-pairing",
  RETURN_STATUS = "return-status",
  SCAN_QR_CODE = "scan-qr-code",
  SCANNED_QR_CODE = "scanned-qr-code",
  CONFIRM_RESET = "confirm-reset",
  SHOW_WIDGET = "show-widget",
  HIDE_QR_CODE = "hide-qr-code"
}

export enum CFSMessageType {
  CONFIRM_PAIRING = "confirmPairing",
  CONFIRM_RESET = "confirmReset",
  RETURN_STATUS = "returnStatus",
  PAIR = "pair",
  SHOW_QR_CODE = "showQrCode",
  HIDE_QR_CODE = "hideQrCode",
  QR_CODE_SCANNED = "qrCodeScanned",
  SCAN_QR_CODE = "scanQrCode",
  SCANNED_QR_CODE = "scannedQrCode",
  UPDATE_CFS_CAROUSEL = "updateCFSCarousel",
  UPDATE_CFS = "updateCfs",
  SHOW_CFS_TEMPLATE = "showCfsTemplate",
  RESET_CASH_REGISTER = "resetCashRegister",
  SHOW_CMC_WIDGET = "showCmcWidget",
  CFS_MENU_UPDATE = "cfsMenuUpdate"
}

export interface CFSRequestResult {
  confirmed: boolean;
  code?: string;
  cashRegisterId?: number;
  message?: string;
  oldCode?: string;
  state?: CFSPairingState;
  errorType?: CFSRequestErrorType;
}

export type CFSRequestErrorType = "noCfsCode" | "noConnection";

export interface CFSConnectionSettings {
  locationId?: number;
  cashRegisterId?: number;
  resolve?: any;
  state?: CFSPairingState | null;
}

export interface CFSQrCodeInfo extends DisplayQrCodeInfo {
  shortLink?: string;
  timeout?: number;
  qrCodeHtmlLinkUrl?: string;
  widgetId?: number;
  couponName?: string;
}

export interface DisplayQrCodeInfo {
  sessionId: string;
}

export interface CFSScanQrCodeInfo {
  open?: boolean;
}

export interface CommunicatorMessageEvent {
  channel: string;
  subscription: string;
  timetoken: string;
  message: any;
  publisher: string;

  /**
   * deprecated:
   */
  actualChannel: string;

  /**
   * deprecated:
   */
  subscribedChannel: string;
}

export interface CFSPairingState extends CFSStatuses {
  cfsCode: string;
  cashRegisterId?: number;
  locationId?: number;
  pairingDate?: number;
  resetOthers?: boolean;
}

export interface ShowWidgetSettings<W, D> {
  widgetType: W;
  data: D;
}

export interface CFSMessageInfo {
  message: CFSConnectionMessage;
  cfsCode: string;
  cashRegisterId?: number;
  locationId?: number;
}
