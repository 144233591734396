import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  templateUrl: "dashboard-list-view-widget.component.html",
  selector: "app-dashboard-list-view-widget",
  styleUrls: ["dashboard-list-view-widget.component.scss"]
})
export class DashboardListViewWidgetComponent {
  @Input() text: string;
  @Input() icon: string;
  @Input() disabled = false;
  @Input() isManager = true;

  @Output() clicked = new EventEmitter();

  click() {
    if (!this.disabled) this.clicked.emit();
  }
}
