import { Injectable } from "@angular/core";
import { DataService } from "@app-cmc/core/services/data.service";
import { LocationWidgetSettings } from "@app-cmc/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class WidgetManagementService {
  private serviceUrl = "widgetManagement";

  constructor(private http: DataService) {}

  getLocationWidgetSettings(locationId: number, featureId: number): Observable<LocationWidgetSettings> {
    return this.http.get(`${this.serviceUrl}`, { locationId: locationId, defaultWidgetSettingsId: featureId });
  }
}
