import { CouponInfo } from "./coupon-info";
import { CouponSessionUser } from "./coupon-session-user";
import { CouponSessionStatus } from "./coupon-session-status";

export class CouponSession {
  public session: string;
  public customValidationCode: string;
  public user: CouponSessionUser;
  public campaign: CouponInfo;
  public status: CouponSessionStatus;
}
