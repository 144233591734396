import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ToastService } from "@app-cmc/shared/components/app-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AllowedDeliveryChannel, FeatureTypes, QRCodeSettings, WidgetMessageInfo, WidgetMessageResultDto } from "@app-cmc/models";
import { CompanyDataService, QrCodeService, WidgetConfirmType, WidgetService } from "@app-cmc/services";
import { FeatureModalBaseComponent } from "../../feature-modal-base.component";
import { Recipient, RecipientItem } from "@app-cmc/features/common/recipient-item";
import { BehaviorSubject, of, Subject } from "rxjs";
import { catchError, delay, finalize, take, tap } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { SpinnerService } from "@app-cmc/shared/components";

@Component({
  selector: "app-default-template-feature-modal",
  templateUrl: "./default-template-feature-modal.component.html",
  styleUrls: ["./default-template-feature-modal.component.scss"]
})
export class DefaultTemplateFeatureModalComponent extends FeatureModalBaseComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly showContentDelay = 1000;
  private unsubscribe$ = new Subject();

  featureId: FeatureTypes;

  contentReadyToDisplay$ = new BehaviorSubject<boolean>(false);
  qrCodeSettings$ = new BehaviorSubject<QRCodeSettings>(null);
  recipients: Recipient[] = [{} as Recipient];
  isOnlyQr = false;
  isSent = false;
  isLoadPage = false;
  loading = false;

  get isValid() {
    return (
      this.recipients.length &&
      this.recipients.every((recipient: Recipient) => recipient.valid) &&
      (this.recipients[0].email || this.recipients[0].phone)
    );
  }

  constructor(
    public translateSvc: TranslocoService,
    private cdr: ChangeDetectorRef,
    public widgetService: WidgetService,
    public activeModal: NgbActiveModal,
    public toastSvc: ToastService,
    private companyDataService: CompanyDataService,
    private qrCodeService: QrCodeService,
    private globalSpinnerService: SpinnerService
  ) {
    super(activeModal, FeatureTypes.RequestReview);
  }

  ngOnInit() {
    this.globalSpinnerService.show();
    this.hideBackDropOnGlobalLoading();

    const messageInfo: WidgetMessageInfo = this.widgetService.getWidgetMessageInfo({
      deliveryMethod: AllowedDeliveryChannel.qr,
      recipients: this.recipients
    });

    this.widgetService
      .getWidgetMessage({ messageType: "start", featureId: this.featureId, messageInfo })
      .pipe(
        tap((message: WidgetMessageResultDto) => {
          this.qrCodeSettings$.next(this.qrCodeService.getInitialQRCodeSettings(message));
        }),
        catchError((error) => {
          this.handleError(error, "generateQrCode");
          this.activeModal.dismiss();

          return of(null);
        }),
        delay(this.showContentDelay),
        take(1),
        finalize(() => {
          this.loading = false;

          this.displayBackDropAfterGlobalLoading();
          this.contentReadyToDisplay$.next(true);
          this.globalSpinnerService.hide();
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.isLoadPage = true;
    this.isOnlyQr = this.deliveryMethod === AllowedDeliveryChannel.qr;
    this.cdr.detectChanges();
  }

  updateItem(index: number, item: RecipientItem) {
    Object.assign(this.recipients[index], item);
  }

  deleteItem(index: number) {
    this.recipients.splice(index, 1);
  }

  confirmSend(): void {
    const sendDeliveryMethod: AllowedDeliveryChannel = AllowedDeliveryChannel.emailAndSms;

    this.setCompleteQrCodeSettings("send", sendDeliveryMethod);
  }

  toCloseModal(): void {
    this.dismiss();
  }

  openQrCode(): void {
    this.setCompleteQrCodeSettings("generateQrCode", AllowedDeliveryChannel.qr);
  }

  private hideBackDropOnGlobalLoading(): void {
    const backdropElement: HTMLElement = document.querySelector("ngb-modal-backdrop");
    const modalContentElement: HTMLElement = document.querySelector(".modal-content");

    if (backdropElement) {
      backdropElement.style.background = "transparent";
    }

    if (modalContentElement) {
      modalContentElement.style.border = "none";
      modalContentElement.style.background = "transparent";
    }
  }

  private displayBackDropAfterGlobalLoading(): void {
    const backdropElement: HTMLElement = document.querySelector("ngb-modal-backdrop");
    const modalContentElement: HTMLElement = document.querySelector(".modal-content");

    if (backdropElement) {
      backdropElement.style.background = "#000";
    }

    if (modalContentElement) {
      modalContentElement.style.border = "1px solid #f6f6f6";
      modalContentElement.style.background = "#fff";
    }
  }

  private setCompleteQrCodeSettings(action: WidgetConfirmType, deliveryMethod: AllowedDeliveryChannel) {
    if (!this.isValid && action === "send") return;

    this.loading = true;
    const messageInfo: WidgetMessageInfo = this.widgetService.getWidgetMessageInfo({
      deliveryMethod,
      recipients: this.recipients,
      sessionId: this.qrCodeSettings$.value?.sessionId
    });

    this.widgetService
      .getWidgetMessage({ widgetAction: action, messageType: "complete", featureId: this.featureId, messageInfo })
      .pipe(take(1))
      .subscribe(
        (message: WidgetMessageResultDto) => {
          if (action === "send") {
            this.dismiss();
            this.isSent = true;
          } else if (action === "generateQrCode") {
            const qrCodeSettings: QRCodeSettings = this.qrCodeService.getFinalQRCodeSettings(message, this.qrCodeSettings$.value);
            this.qrCodeSettings$.next(qrCodeSettings);
            this.companyDataService.retriveData();
            this.loading = false;
          }
        },
        (error) => {
          this.handleError(error, action);
          this.loading = false;
        }
      );
  }

  private handleError(response, action): void {
    const error = response?.error;
    if (error && error["ServerExceptionType"] === "ValidationException" && error["Message"]) {
      this.toastSvc.danger(error["Message"]);
    } else {
      let message = this.translateSvc.translateObject("features.send.sendErrorQRCode");

      if (error?.message) {
        message = error.message;
      }

      if (action === "send" && this.recipients[0].phone.length > 0) {
        message = this.translateSvc.translateObject("features.send.sendErrorSMS");
      }

      if (action === "send" && this.recipients[0].email.length > 0) {
        message = this.translateSvc.translateObject("features.send.sendErrorEmail");
      }

      this.toastSvc.danger(message);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
