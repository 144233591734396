<div class="row" *transloco="let t; read: 'cardVerify'">
  <div class="col-12">
    <div class="card card-content">
      <div class="card-body card-form-content">
        <div class="text-center mb-n5 mt-n3">
          <div class="row justify-content-center mt-5 img-center">
            <div class="col-sm-4">
              <img src="assets/images/card-verify.svg" alt="" />
            </div>
            <div class="col-sm-10">
              <h3 class="mt-5 font-size-24 font-bold">{{ t("messageCardVerify") }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
