import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { AccountService } from "./account.service";
import { RoleTypes } from "@app-cmc/models";

@Injectable()
export class AuthService {
  constructor(private router: Router, private oAuthService: OAuthService, private accountSvc: AccountService) {}

  public logout() {
    this.oAuthService.logOut();
    this.accountSvc.refreshUser();
    this.router.navigate(["/home"]);
  }

  public login() {
    this.oAuthService.logOut(true);
    this.oAuthService.initImplicitFlow("");
  }
  public get isLoggedIn(): boolean {
    return this.accountSvc.isLoggedIn;
  }
  public isUserInRole(role: string): boolean {
    return this.accountSvc.isLoggedIn && this.accountSvc.user.role === role;
  }
  public isUserInRoles(roles: string[]): boolean {
    return this.accountSvc.isLoggedIn && roles.includes(this.accountSvc.user.role) && this.accountSvc.user.role !== RoleTypes.Sales;
  }

  public requredMinimumRole(role: RoleTypes): boolean {
    switch (role) {
      case RoleTypes.PartnerAdmin:
        return this.isUserInRoles([RoleTypes.Admin, RoleTypes.PartnerAdmin]);
      case RoleTypes.Manager:
        return this.isUserInRoles([RoleTypes.Admin, RoleTypes.PartnerAdmin, RoleTypes.Manager]);
      case RoleTypes.LocationManager:
        return this.isUserInRoles([RoleTypes.Admin, RoleTypes.PartnerAdmin, RoleTypes.Manager, RoleTypes.LocationManager]);
      default:
        return this.isUserInRole(role);
    }
  }
  public refreshToken(): Promise<any> {
    if (!this.isLoggedIn) this.login();
    return this.oAuthService.silentRefresh().then(
      (r) => this.accountSvc.refreshUser(),
      (err) => {
        location.reload();
      }
    );
  }
}
