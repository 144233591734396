import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { CashRegisterInitInfo } from "@app-cmc/models/cash-register";
import { CashRegisterService } from "@app-cmc/services/cash-register.service";
import { auditTime, mergeMap, tap } from "rxjs/operators";
import { CashRegister, CFSPairingState, ClientCashRegister, ConnectionStatus, NetworkStatus } from "cfs-communication-pack";
import { BrandColorPalette } from "@app-cmc/models";
import { CFSConnectionService } from "@app-cmc/services/cfs-connection.service";
import { BrandColorPaletteService } from "@app-cmc/services/brand-color-palette.service";

interface SubscriptionInfo {
  [code: string]: boolean;
}

@Injectable({
  providedIn: "root"
})
export class CashRegistersService {
  constructor(
    private cashRegisterService: CashRegisterService,
    private cfsConnectionService: CFSConnectionService,
    private brandColorService: BrandColorPaletteService
  ) {}

  private readonly auditTimeDelay = 300;
  private subscriptionList: SubscriptionInfo = {};
  private brandColorsPaletteCache: BrandColorPalette;
  private update$ = new Subject<CashRegisterInitInfo>();
  private locationId: number;

  cashRegisters$ = new BehaviorSubject<CashRegister[]>([]);
  currentCashRegister$ = new BehaviorSubject<ClientCashRegister>(null);

  getCurrentCashRegister(): ClientCashRegister {
    return this.cashRegisterService.getClientCashRegisterFromStorage() || this.currentCashRegister$.value;
  }

  update(locationId: number): void {
    this.update$.next({ locationId });
  }

  clearCashRegisterSubscription(cfsCode: string, id: number): void {
    const key = this.getSubscriptionKey(cfsCode, id);

    if (this.subscriptionList.hasOwnProperty(key)) {
      this.subscriptionList[key] = false;
    }
  }

  runUpdateCashregisters(): Observable<CashRegister[]> {
    return this.update$.pipe(
      auditTime(this.auditTimeDelay),
      mergeMap(({ locationId }: CashRegisterInitInfo) => {
        return this.cashRegisterService.getAll([locationId]).pipe(
          tap((list: CashRegister[]) => {
            this.cashRegisters$.next(list);

            this.locationId = locationId;
            const cashRegisterId: number = this.cashRegisterService.getSelectedCashRegisterIdFromStorage();
            const baseCurrentCashRegister: CashRegister = list.find((item: CashRegister) => item.id === cashRegisterId);
            const clientCashRegister: ClientCashRegister = this.getDefaultClientCashRegister(baseCurrentCashRegister);

            this.cfsConnectionService.updateCashRegisterData(locationId, clientCashRegister.cfsCode, cashRegisterId);
            this.updateCurrentCashRegister(clientCashRegister);
            this.runWatchingCashregisterStatuses(clientCashRegister.cfsCode, clientCashRegister.id);
          })
        );
      })
    );
  }

  init(userId: string): void {
    this.cfsConnectionService.init(userId);
  }

  runStateBroadcast(): Observable<CFSPairingState> {
    return this.cfsConnectionService.getState().pipe(
      tap((state: CFSPairingState) => {
        let updatedItem: ClientCashRegister | undefined;
        const currentItem: ClientCashRegister = this.getCurrentCashRegister();

        if (!currentItem.cfsCode) {
          updatedItem = this.getUpdatedCashRegister(currentItem, {
            ...state,
            status: ConnectionStatus.NOT_PAIRED,
            networkStatus: NetworkStatus.ONLINE,
            pairingDate: null
          });
        } else if (currentItem.cfsCode === state.cfsCode) {
          const isItCurrentItem = this.isItCurrentCashRegisterByState(currentItem, state);

          if (isItCurrentItem && this.isCashRegisterStateChanged(currentItem, state)) {
            updatedItem = this.getUpdatedCashRegister(currentItem, state);
          } else if (!isItCurrentItem && this.isStatePairedWithReset(state)) {
            updatedItem = this.getUpdatedCashRegister(currentItem, {
              ...state,
              status: ConnectionStatus.NOT_PAIRED
            });
          }
        } else if (currentItem.id === state.cashRegisterId && currentItem.locationId === state.locationId) {
          updatedItem = this.getUpdatedCashRegister(currentItem, state);
        }

        if (updatedItem) {
          this.updateCurrentCashRegister(updatedItem);
        }
      })
    );
  }

  private updateCurrentCashRegister(cashRegister: ClientCashRegister): void {
    this.cashRegisterService.setClientCashRegisterToStorage(cashRegister);
    this.currentCashRegister$.next(cashRegister);
  }

  private runWatchingCashregisterStatuses(cfsCode: string, cashRegisterId: number): void {
    const key = this.getSubscriptionKey(cfsCode, cashRegisterId);
    const initialized = this.subscriptionList[key];

    if (!initialized) {
      this.subscriptionList[key] = true;

      this.cfsConnectionService.runWatchingStatuses(cfsCode);
    } else {
      this.cfsConnectionService.publishReturnStatus(cfsCode);
    }
  }

  private getBrandColors(brandId: number): Observable<BrandColorPalette> {
    if (this.brandColorsPaletteCache) {
      return of(this.brandColorsPaletteCache);
    }

    return this.brandColorService
      .getBrandColors(brandId)
      .pipe(tap((brandColorsPalette: BrandColorPalette) => (this.brandColorsPaletteCache = brandColorsPalette)));
  }

  private isItCurrentCashRegisterByState(item: ClientCashRegister, state: CFSPairingState): boolean {
    return (
      ((state.status === ConnectionStatus.NOT_PAIRED || state.status === ConnectionStatus.UNAVAILABLE) &&
        !state.cashRegisterId &&
        !state.locationId) ||
      (item.id === state.cashRegisterId && state.locationId === this.locationId)
    );
  }

  private isCashRegisterStateChanged(cashRegister: ClientCashRegister, state: CFSPairingState): boolean {
    return (
      cashRegister.clientStatus !== state.status ||
      cashRegister.clientNetworkStatus !== state.networkStatus ||
      cashRegister.pairingDate !== state.pairingDate
    );
  }

  private getDefaultClientCashRegister(cashRegister: CashRegister): ClientCashRegister {
    return { ...cashRegister, clientStatus: ConnectionStatus.NOT_PAIRED, clientNetworkStatus: undefined, pairingDate: undefined };
  }

  private getUpdatedCashRegister(cashRegister: ClientCashRegister, state: CFSPairingState): ClientCashRegister {
    return {
      ...cashRegister,
      clientStatus: state.status,
      clientNetworkStatus: state.networkStatus,
      pairingDate: state.pairingDate
    };
  }

  private isStatePairedWithReset(state: CFSPairingState): boolean {
    return state.status === ConnectionStatus.PAIRED && state.networkStatus === NetworkStatus.ONLINE && state.resetOthers;
  }

  private getSubscriptionKey(cfsCode: string, id: number): string {
    return `${cfsCode}-${id}`;
  }
}
