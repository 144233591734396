import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { RecipientItem, RecipientType, RecipientAllowedType } from "../recipient-item";
import { getRecepientValidator } from "../email-or-phone-validator";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-recipient-item",
  templateUrl: "./recipient-item.component.html",
  styleUrls: ["./recipient-item.component.scss"]
})
export class RecepientItemComponent implements OnInit {
  fieldType = new BehaviorSubject<RecipientType>("");

  @Input() allowedType: RecipientAllowedType = "phoneOrEmail";
  @Input() hideButtons = false;
  @Input() loading = false;
  @Output() changed = new EventEmitter<RecipientItem>();
  @Output() deleted = new EventEmitter();

  previousValidValue: string | undefined;
  isEditing = true;

  emailOrPhone: UntypedFormControl;

  get placeHolder() {
    const strings = this.translocoService.translateObject("features.common");
    switch (this.allowedType) {
      case "phoneOrEmail":
        return strings.enterPhoneOrEmailPlaceholder;
      case "phone":
        return strings.enterPhonePlaceholder;
      case "email":
        return strings.enterEmailPlaceholder;
    }
  }

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.emailOrPhone = new UntypedFormControl("", [Validators.required, getRecepientValidator(this.allowedType, this.fieldType)]);
  }

  edit() {
    if (this.loading) return;
    this.isEditing = true;
    this.update();
  }

  save() {
    if (!this.emailOrPhone.valid) return;
    this.previousValidValue = this.emailOrPhone.value;
    this.isEditing = false;
    this.update();
  }

  cancel() {
    if (this.previousValidValue) {
      this.emailOrPhone.setValue(this.previousValidValue);
      this.isEditing = false;
      this.update();
    } else {
      this.deleted.emit();
    }
  }

  update() {
    let val: string = this.emailOrPhone.value;
    if (this.fieldType.value === "phone" && val.length === 10) val = "1" + val;
    this.emailOrPhone.setValue(val);
    this.changed.emit({
      value: val,
      valid: this.emailOrPhone.valid && !this.isEditing,
      loading: this.loading,
      type: this.fieldType.value
    });
  }
}
