<div class="{{ classList }}" *ngIf="control">
  <label *ngIf="label" class="col-form-label {{ labelClassList }}">
    {{ label }}<span *ngIf="isRequired" class="text-danger"> *</span>

    <i
      *ngIf="tooltip"
      class="bx bx-question-mark font-size-18 mb-0 cursor-pointer text-info"
      [ngbTooltip]="tooltip">
    </i>
  </label>
  <div class="position-relative {{ innerClassList }}">
    <ng-container *ngIf="customMaskPatterns; else noPatterns">
      <input
        *ngIf="control"
        class="form-control"
        [class.icon-padding]="!!icon"
        [readonly]="readonly"
        [type]="type"
        [formControl]="control"
        [mask]="mask"
        [placeholder]="placeholder"
        [patterns]="customMaskPatterns"
        [class.is-invalid]="control.enabled && control.touched && !control.valid"
        (focusout)="onFocusOut()"
      />
    </ng-container>
    <ng-template #noPatterns >
      <input
        *ngIf="control"
        class="form-control"
        [class.icon-padding]="!!icon"
        [readonly]="readonly"
        [type]="type"
        [formControl]="control"
        [mask]="mask"
        [placeholder]="placeholder"
        [class.is-invalid]="control.enabled && control.touched && !control.valid"
        (focusout)="onFocusOut()"
      />
    </ng-template>
    <i *ngIf="!!icon && (!control.touched || !control.errors)" class="icon-container {{ icon }}" (click)="onIconClick()"></i>
    <app-form-field-error
      *ngIf="control.touched && !control.valid"
      [label]="label"
      [placeholder]="placeholder"
      [errors]="control.errors"
      class="{{ errorClassList }}"
    ></app-form-field-error>
  </div>
</div>
