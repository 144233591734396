<div *ngIf="withCompactPreview" class="qr-code-preview d-flex flex-column align-items-center py-3 px-4" [style.background-color]="qrCodeSettings?.background">
  <div  class="mb-3" [style.width]="qrCodeSize + 'px'" [style.height]="qrCodeSize + 'px'">
    <iframe *ngIf="qrCodeSettings?.safePreviewHtmlUrl" [src]="qrCodeSettings.safePreviewHtmlUrl" (load)="iframeLoads()" class="w-100 h-100"></iframe>
  </div>

  <ng-container *ngTemplateOutlet="openQrCodeBtn; context: { classList: 'btn-outline-primary w-100' }"></ng-container>
</div>

<ng-container *ngIf="!withCompactPreview">
  <ng-container *ngTemplateOutlet="openQrCodeBtn; context: { classList: 'btn-primary' }"></ng-container>
</ng-container>

<ng-template #openQrCodeBtn let-classList="classList">
  <button class="btn {{ classList }}" [disabled]="disabled" (click)="onClickOpenQrCodeModalBtn($event)">
    <ng-container *ngIf="withCompactPreview; else fullMode">
      {{ "qrcode.openQr" | transloco }}
    </ng-container>

    <ng-template #fullMode>
      {{ "qrcode.showQr" | transloco }}
    </ng-template>
  </button>
</ng-template>
