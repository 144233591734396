export class EnrollLoyaltyWidgetSettings {
  public loyaltyType: LoyaltyType;
  public maxStampsPoints: number;
  public isLimitPerClient: boolean;
  public loyaltyProgram: LoyaltyProgram;
  public enrollLoyaltyLink: string;
}

export enum LoyaltyType {
  AllLocations = 0,
  SingleLocation = 1,
  MultiLocations = 2
}
export enum LoyaltyProgram {
  DigitalStampCard = 0,
  PointsToPrize = 1,
  RewardsCard = 2
}
