import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { SpinnerService } from "./spinner.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent implements AfterViewInit, OnDestroy {
  isVisible$ = this.spinnerService.isVisible$;

  private unsubscribe$ = new Subject<void>();

  constructor(private spinnerService: SpinnerService, private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.isVisible$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
