<form [formGroup]="form" *transloco="let t; read: 'recipients'">
  <div class="row">
    <h6 *ngIf="withMessage && deliveryMessage" class="text-center mt-3">{{ deliveryMessage | transloco }}</h6>
    <div class="col-md-12">
      <div class="row">
        <div *ngIf="deliveryMethod | matchBitMask : AllowedDeliveryChannel.email" class="field mb-3">
          <app-input
            class="col-md-6 col-sm-12"
            [label]="t('labels.email')"
            [control]="getRecipientControl('email')"
            [placeholder]="t('labels.email')"
          ></app-input>
        </div>

        <div *ngIf="deliveryMethod | matchBitMask : AllowedDeliveryChannel.sms" class="field mb-3">
          <app-input
            class="col-md-6 col-sm-12"
            [mask]="phoneMask"
            [label]="t('labels.phone_number')"
            [control]="getRecipientControl('phone')"
            [placeholder]="t('labels.phone_number')"
          ></app-input>
        </div>

        <div>
          <div *ngIf="!showPersonalization" class="field">
            <p class="personalization-message">
              {{ t("personalization-message") }}
            </p>
            <button (click)="showPersonalization = true" class="btn btn-link p-0 personalization-btn">
              {{ t("buttons.message_personalization") }}
            </button>
          </div>

          <div *ngIf="showPersonalization">
            <div class="row">
              <div class="field mb-3">
                <app-input
                  class="col-md-6 col-sm-12"
                  [label]="t('labels.firstName')"
                  [control]="form.controls.firstName"
                  [placeholder]="t('labels.firstName')"
                ></app-input>
              </div>
              <div class="field mb-3">
                <app-input
                  class="col-md-6 col-sm-12"
                  [label]="t('labels.lastName')"
                  [control]="form.controls.lastName"
                  [placeholder]="t('labels.lastName')"
                ></app-input>
              </div>
            </div>

            <div class="mb-3" *ngIf="isProducts">
              <label class="col-form-label">Category</label>
              <ng-select
                (change)="changeCategories($event)"
                [placeholder]="'Category'"
                [class.is-invalid]="form.controls['productCategory'].touched && form.controls['productCategory'].errors"
                [items]="categories"
                bindLabel="productCategory"
                bindValue="productCategory"
                formControlName="productCategory"
                type="text"
              >
              </ng-select>
              <ng-container *ngIf="form.controls['productCategory'].touched && form.controls['productCategory'].errors">
                <ng-container *ngIf="form.controls['productCategory'].errors.required; else second">
                  <div class="invalid-feedback"></div>
                </ng-container>
              </ng-container>
            </div>

            <div class="mb-3" *ngIf="isProducts">
              <label class="col-form-label">Product</label>
              <ng-select
                [placeholder]="'Product'"
                (change)="productSelected($event)"
                [class.is-invalid]="form.controls['product'].touched && form.controls['product'].errors"
                [items]="products"
                bindLabel="productName"
                bindValue="productName"
                formControlName="product"
                type="text"
              >
              </ng-select>
              <ng-container *ngIf="form.controls['product'].touched && form.controls['product'].errors">
                <ng-container *ngIf="form.controls['product'].errors.required; else second">
                  <div class="invalid-feedback"></div>
                </ng-container>
              </ng-container>
            </div>

            <div class="field">
              <button (click)="showPersonalization = false" class="btn btn-link p-0 personalization-btn">
                {{ t("buttons.message_less-personalization") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #second>
    <div class="invalid-feedback position-absolute">
      {{ t("invalid_phone") }}
    </div>
  </ng-template>
</form>
