import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IssueCouponModule } from "./issue-coupon/issue-coupon.module";
import { DefaultTemplateFeatureModule } from "./default-template-feature/default-template-feature.module";

import { ValidateCouponModule } from "./validate-coupon/validate-coupon.module";
import { SharedModule } from "@app-cmc/shared/shared.module";
import { IssuePointsModule } from "./issue-points/issue-points.module";

@NgModule({
  imports: [CommonModule, SharedModule, DefaultTemplateFeatureModule, IssueCouponModule, ValidateCouponModule, IssuePointsModule],
  declarations: []
})
export class FeaturesModule {}
