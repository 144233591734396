import { Component, Input } from "@angular/core";
import { CouponSessionUser } from "@app-cmc/models/coupons";

@Component({
  selector: "app-validate-coupon-additional-details",
  templateUrl: "./validate-coupon-additional-details.component.html",
  styleUrls: ["./validate-coupon-additional-details.component.scss"]
})
export class ValidateCouponAdditionalDetailsComponent {
  constructor() {}

  @Input() user: CouponSessionUser;
}
