import { Injectable } from "@angular/core";
import { DataService } from "@app-cmc/core/services/data.service";
import { ApplicationStyles, BrandColorPalette } from "@app-cmc/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class BrandColorPaletteService {
  private serviceUrl = "applicationStyles";

  constructor(private http: DataService) {}

  /* Obsolete: Use getBrandStyles instead*/
  getBrandColors(brandId: number): Observable<BrandColorPalette> {
    return this.getBrandStyles(brandId).pipe(
      map((style: ApplicationStyles) => {
        return ApplicationStyles.toBrandColorPalette(brandId, style);
      })
    );
  }
  getBrandStyles(brandId: number): Observable<ApplicationStyles> {
    return this.http.get(`${this.serviceUrl}/brand/${brandId}`);
  }
}
