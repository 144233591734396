export interface BrandColorPalette {
  id: number;
  brandId: number;
  primary: string;
  secondary: string;
  tertiary: string;
}
export enum DisplayQrCodeLogoTypes {
  None = 0,
  BrandLogo = 1,
  CustomLogo = 2
}

export class QrCodeConfiguration {
  public primaryColor: string;
  public secondaryColor: string;
  public backgroundColor: string;
  public logo: string;
  public qrLogoType: DisplayQrCodeLogoTypes;
  public hasFrame: boolean;
}

export enum ApplicationAccessLevel {
  Private = "private",
  Public = "public"
}

export class ApplicationStyles {
  id: number;
  name: string;
  applicationAccessLevel: ApplicationAccessLevel;
  qrConfiguration: QrCodeConfiguration;
  static toBrandColorPalette(brandId: number, styles: ApplicationStyles): BrandColorPalette {
    return {
      id: styles.id,
      brandId: brandId,
      primary: styles.qrConfiguration.primaryColor,
      secondary: styles.qrConfiguration.secondaryColor,
      tertiary: styles.qrConfiguration.backgroundColor
    };
  }
}
