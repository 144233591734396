<div class="modal-header" *transloco="let t; read: 'features.issueCoupon'">
  <h5 class="modal-title">
    {{ t("title") }}
  </h5>

  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    [title]="'common.controls.close' | transloco"
    (click)="dismiss()"
  ></button>
</div>

<div
  class="modal-body"
  [class.spinner-border-wrapper]="!couponsIsLoading"
  [class.loading]="loading && !couponsIsLoading">
  <lib-wizard
    [steps]="steps"
    [templates]="[step1, step2]"
    (stepperReady)="onStepperReady($event)"
    [options]="wizardOptions"
    (confirm)="confirm()"
  >
  </lib-wizard>

  <ng-template #step1>
    <div class="row filter">
      <div class="input-group d-flex" *transloco="let t; read: 'features.issueCoupon'">
        <input
          [placeholder]="'features.issueCoupon.search' | transloco"
          type="text"
          class="form-control with-action"
          (keyup.enter)="clickSearchCoupons()"
          [(ngModel)]="couponsFilter.keyword"
        />

        <div class="form-control-action">
          <img (click)="clickSearchCoupons()" alt="" src="assets/images/filter-icon.svg" width="14" height="14" />
        </div>
      </div>
    </div>

    <div class="row justify-content-center" *ngIf="false && categories.length">
      <app-badgets-selection class="col-md-10" (selectChanged)="onBadgetChanged($event)" [selectMany]="false" [data]="categories">
      </app-badgets-selection>
    </div>

    <div
      class="coupon-carousel-wrapper centered-content"
      [class.spinner-border-wrapper]="couponsIsLoading"
      [class.loading]="loading && couponsIsLoading">
      <app-coupon-carousel
        class="coupon-carousel"
        [couponsLoading]="couponsIsLoading"
        [selectedCoupon]="selectedCoupon"
        [coupons]="coupons"
        (selectCoupon)="select($event)"
        (closeRestModals)="onCloseModal()"
        (setLoading)="loading = $event;">
      </app-coupon-carousel>

      <span *ngIf="loading && couponsIsLoading" class="spinner-border spinner-border-sm mr-1"></span>
    </div>
  </ng-template>

  <ng-template #step2>
    <div *ngIf="selectedCoupon && wizard?.selectedIndex === 1" class="second-step">
      <div class="simple-coupon-wrapper">
        <app-qr-code-coupon
          class="coupon"
          [couponInfo]="selectedCoupon"
          [type]="'simple'"
          (qrCodeSessionId)="setSessionId($event)"
          (closeRestModals)="onCloseModal()"
          (contentLoaded)="onContentReadyToLoad()">
        </app-qr-code-coupon>
      </div>

      <div class="coupon-recipients">
        <app-recipients
          [deliveryMethod]="deliveryMethod"
          [recipient]="recipients[0]"
          [withMessage]="false"
          (changed)="updateItem(0, $event)"
          [contentReadyToLoad]="contentReadyToLoad$ | async">
        </app-recipients>
      </div>
    </div>
  </ng-template>

  <span *ngIf="loading && !couponsIsLoading" class="spinner-border spinner-border-sm mr-1"></span>
</div>
