import { TransferState, makeStateKey } from "@angular/platform-browser";
import { Injectable, Inject } from "@angular/core";

const APP_DATA_KEY = makeStateKey<IApplicationConfig>("appData");
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class AppConfigService {
  constructor(@Inject("BASE_URL") private baseUrl: string, private transferState: TransferState, private dataService: HttpClient) {}

  private get apiUrl() {
    const i = location.host.indexOf(".");
    const url = location.origin;
    if (environment.apiName.includes("://")) {
      return environment.apiName;
    } else if (i < 0) {
      return url.replace(environment.baseName, environment.apiName);
    } else {
      return window.location.protocol + "//" + environment.apiName + window.location.host.substring(i);
    }
  }
  public get appData(): IApplicationConfig {
    return this.transferState.get<IApplicationConfig>(APP_DATA_KEY, null as IApplicationConfig);
  }
  getAppData(): Promise<IApplicationConfig> {
    const transferredAppData = this.transferState.get<IApplicationConfig>(APP_DATA_KEY, null as IApplicationConfig);
    if (!transferredAppData) {
      return this.dataService
        .get(this.apiUrl + "/Configuration")
        .toPromise()
        .then((config: { data: IApplicationConfig }) => {
          const data: IApplicationConfig = config.data;
          data.baseUrl = this.baseUrl;
          const authConfig = environment.authConfig as IAuthConfig;
          if (data.domainId) {
            authConfig.clientId = authConfig.clientId + "-" + data.domainId;
          }
          authConfig.identityServerUrl = data.identityUrl;
          authConfig.logoutUrl = data.landingUrl + authConfig.logoutUrl;
          authConfig.redirectUrl = data.marketingCenterUrl + authConfig.redirectUrl;
          data.authConfig = authConfig;
          data.selfServicePortalUrl = environment.selfServicePortalUrl;
          this.transferState.set<IApplicationConfig>(APP_DATA_KEY, data);
          return data;
        });
    }
    return new Promise((resolve, reject) => {
      resolve(transferredAppData);
    });
  }
}
