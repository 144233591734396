import { Location } from "@app-cmc/models";
import { ExternalLocation } from "../external-location";
export class LoyaltyCard {
  id: string;
  code: string;
  url: string;
  status: string;
  name: string;
  title: string;
  description: string;
  type: string;
  amountOfStamps: number;
  amountOfPoints: number;
  frontImage: string;
  logo: string;
  locations: ExternalLocation[];
}
