import { NgModule } from "@angular/core";
import {
  ValidateCouponAdditionalDetailsComponent,
  ValidateCouponDetailsComponent
} from "@app-cmc/features/validate-coupon/validate-coupon-modal";
import { ArchwizardModule } from "angular-archwizard";
import { QrCodeReaderModule } from "ui-kit";
import { CommonFeatureModule } from "../common/common-feature.module";
import { ValidateCouponModalComponent } from "./validate-coupon-modal";

@NgModule({
  declarations: [ValidateCouponModalComponent, ValidateCouponDetailsComponent, ValidateCouponAdditionalDetailsComponent],
  imports: [CommonFeatureModule, QrCodeReaderModule, ArchwizardModule],
  exports: [ValidateCouponModalComponent]
})
export class ValidateCouponModule {}
