import { Injectable } from "@angular/core";
import { take } from "rxjs/operators";
import { CashRegisterPairingStatusPostDto, CashRegisterParingStatuses } from "cfs-communication-pack";
import { CashRegisterService } from "@app-cmc/services";

@Injectable({
  providedIn: "root"
})
// todo: replace to common project after data and api Services will be moved to common project
export class CashRegisterStatusLoggerService {
  constructor(private cashRegisterService: CashRegisterService) {}

  logSuccessPairing(cashRegisterId: number, message = ""): void {
    this.logPairingStatus(cashRegisterId, CashRegisterParingStatuses.Paired, message);
  }

  logFailedPairing(cashRegisterId: number, message = ""): void {
    this.logPairingStatus(cashRegisterId, CashRegisterParingStatuses.NotPaired, message);
  }

  private logPairingStatus(cashRegisterId: number, status: CashRegisterParingStatuses, message = ""): void {
    const postDto: CashRegisterPairingStatusPostDto = { paringStatus: status, message };

    this.cashRegisterService.setCashRegisterPairingStatus(cashRegisterId, postDto).pipe(take(1)).subscribe();
  }
}
