<!-- Load translation for APP: -->
<div id="layout-wrapper" *transloco="let t">
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

  <div class="container-lg container-sm">
    <div class="page-content">
      <div class="container-fluid">
        <div class="">
          <app-header></app-header>
          <app-spinner></app-spinner>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <!-- End Page-content -->

    <footer class="footer app-footer">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            {{ t("footer", { year: year }) }}
          </div>
        </div>
      </div>
    </footer>
  </div>
  <!-- end main content-->
</div>
