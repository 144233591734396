import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Badget } from "../badget";

@Component({
  selector: "app-badgets-selection",
  templateUrl: "./badgets-selection.component.html",
  styleUrls: ["./badgets-selection.component.scss"]
})
export class BadgetsSelectionComponent<T> implements OnInit {
  @Output() selectChanged = new EventEmitter<T | T[]>();
  @Input() data: T[];
  @Input() selectMany = false;
  public badgets: Badget<T>[];
  private _selectedItems: Badget<T>[] = [];

  constructor() {}

  ngOnInit(): void {
    this.initialize();
  }
  select(baget: Badget<T>) {
    if (this.selectMany) {
      if (baget.isSelected == false) {
        this._selectedItems.push(baget);
        this.selectChanged.emit(this._selectedItems.map((item) => item.data));
        baget.isSelected = true;
      } else {
        baget.isSelected = false;
        const index = this._selectedItems.indexOf(baget);
        this._selectedItems.splice(index);
        this.selectChanged.emit(this._selectedItems.map((item) => item.data));
      }
    } else {
      if (baget.isSelected == false) {
        this.badgets.map((item) => (item.isSelected = false));
        baget.isSelected = true;
        this.selectChanged.emit(baget.data);
      } else {
        this.badgets.map((item) => (item.isSelected = false));
        this.selectChanged.emit(null);
      }
    }
  }
  disabled(badget: Badget<T>) {
    return this.selectMany ? false : !badget.isSelected && Object.keys(this._selectedItems).length;
  }

  private initialize() {
    this._selectedItems = [];
    this.badgets = this.data.map((item) => {
      const badget = {
        name: item.hasOwnProperty("name") ? item["name"] : null,
        id: item.hasOwnProperty("id") ? item["id"] : null,
        data: item,
        isSelected: false
      } as Badget<T>;

      return badget;
    });
  }
}
