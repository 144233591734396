import { Inject, Injectable } from "@angular/core";
import { CFSCommunicationChannels, CFSPairingState, CommunicatorConfig, ConnectionStatus, NetworkStatus } from "../models";
import { Subject } from "rxjs";
import { CommunicatorService } from "./communicator.service";
import { HereNowResponse, PresenceEvent } from "pubnub";
import { COMMUNICATOR_CONFIG_TOKEN } from "../cfs-communication-pack.module";
import { extractCodeFromChannel, getFullChannelName } from "../utils";

interface HereNowResponseChannels {
  [channel: string]: HereNowResponseChannel;
}

interface HereNowResponseChannel {
  name: string;
  occupancy: number;
  occupants: HereNowResponseOccupant[];
}

interface HereNowResponseOccupant {
  uuid: string;
  state?: any;
}

interface StateInfo {
  [key: string]: boolean;
}

@Injectable()
export class StateService {
  constructor(@Inject(COMMUNICATOR_CONFIG_TOKEN) private config: CommunicatorConfig, private communicatorService: CommunicatorService) {}

  state$ = new Subject<CFSPairingState>();

  private statesHereNowAfterPresence: StateInfo = {};
  private statesHereNowOnDelay: StateInfo = {};

  updateStateOnConnect(state: CFSPairingState): void {
    this.state$.next(state);
  }

  isCFSActive(code: string): Promise<boolean> {
    const channel = getFullChannelName(CFSCommunicationChannels.RETURN_STATUS, code);

    return this.communicatorService.hereNow(channel).then((data) => {
      const { totalOccupancy, channels } = data;
      let isActive = false;

      if (channels.hasOwnProperty(channel) && totalOccupancy > 0) {
        const filteredOccupants: HereNowResponseOccupant[] = this.getHereNowFilteredOccupants(channels, channel);
        isActive = filteredOccupants.length > 0;
      }

      console.log("is cashregister active:", code, isActive); // todo: add ConsoleLogHandlerService to common project
      return isActive;
    });
  }

  hereNowOnDelay(code: string, delay = 1000): void {
    const channel = getFullChannelName(CFSCommunicationChannels.RETURN_STATUS, code);

    console.log("hereNowOnDelay", code, !this.isStatesHereNowOnDelayFinished(channel)); // todo: add ConsoleLogHandlerService to common project
    if (!this.isStatesHereNowOnDelayFinished(channel)) {
      this.startStatesHereNowOnDelay(channel);
      this.startStatesHereNowAfterPresence(channel);

      setTimeout(() => {
        if (this.isStatesHereNowAfterPresenceFinished(channel)) {
          console.log("hereNowReturnStatus on delay", channel); // todo: add ConsoleLogHandlerService to common project
          this.hereNowReturnStatus(code);
        }
      }, delay);
    }
  }

  onPresenceEvent(event: PresenceEvent): void {
    if (this.config.cfsUuid && event.uuid.startsWith(this.config.cfsUuid)) {
      const { action } = event;
      const state: CFSPairingState = event.state;
      console.log("pubnub handlePresenceEvent", event); // todo: add ConsoleLogHandlerService to common project

      if (action === "join" && !state) {
        return;
      }

      this.presenceEventOnFilter(event);
    }
  }

  private presenceEventOnFilter(event: PresenceEvent): void {
    const { action, occupancy, channel } = event;
    const state: CFSPairingState = event.state;

    if (state && action !== "leave" && action !== "timeout") {
      this.resetStatesHereNowAfterPresence(channel);
      this.startStatesHereNowOnDelay(channel);
      console.log("this.state$.next(state)", this.state$, state); // todo: add ConsoleLogHandlerService to common project
      this.state$.next(state);
    } else if ((action === "leave" || action === "timeout") && !occupancy) {
      this.startStatesHereNowOnDelay(channel);
      this.handleOfflineState(channel, action === "timeout");
    } else {
      this.startStatesHereNowAfterPresence(channel);
      this.startStatesHereNowOnDelay(channel);

      this.communicatorService.hereNow(channel).then((data: HereNowResponse) => {
        const { totalChannels, totalOccupancy } = data;
        console.log("pubnub hereNow after presence without state", channel, action, data); // todo: add ConsoleLogHandlerService to common project
        if (this.isStatesHereNowAfterPresenceFinished(channel) && totalChannels > 0 && totalOccupancy > 0) {
          this.resetStatesHereNowAfterPresence(channel);
          const { channels } = data;

          Object.keys(channels).forEach((hereNowChannel: string) => {
            const filteredOccupants: HereNowResponseOccupant[] = this.getHereNowFilteredOccupants(channels, hereNowChannel);
            const cfsCode = extractCodeFromChannel(hereNowChannel);

            if (filteredOccupants.length > 0 && action === "state-change") {
              this.setStateFromOccupants(filteredOccupants, cfsCode);
            } else if (!filteredOccupants.length && (action === "leave" || action === "timeout")) {
              this.handleOfflineState(hereNowChannel, action === "timeout");
            }
          });
        }
      });
    }
  }

  private startStatesHereNowOnDelay(channel: string): void {
    this.statesHereNowOnDelay[channel] = true;
  }

  resetStatesHereNowOnDelay(channel: string): void {
    this.statesHereNowOnDelay[channel] = false;
  }

  private isStatesHereNowOnDelayFinished(channel: string): boolean {
    return this.statesHereNowOnDelay[channel];
  }

  private startStatesHereNowAfterPresence(channel: string): void {
    this.statesHereNowAfterPresence[channel] = true;
  }

  private resetStatesHereNowAfterPresence(channel: string): void {
    if (this.statesHereNowAfterPresence[channel]) {
      this.statesHereNowAfterPresence[channel] = false;
    }
  }

  private isStatesHereNowAfterPresenceFinished(channel: string): boolean {
    return this.statesHereNowAfterPresence[channel];
  }

  private hereNowReturnStatus(code: string): void {
    const channel = getFullChannelName(CFSCommunicationChannels.RETURN_STATUS, code);

    this.communicatorService.hereNow(channel).then((data) => {
      console.log("pubnub hereNowReturnStatus", channel, data); // todo: add ConsoleLogHandlerService to common project
      const { totalOccupancy, channels } = data;

      if (channels.hasOwnProperty(channel)) {
        if (totalOccupancy > 0) {
          const filteredOccupants: HereNowResponseOccupant[] = this.getHereNowFilteredOccupants(channels, channel);

          if (filteredOccupants.length > 0) {
            this.setStateFromOccupants(filteredOccupants, code);
          } else {
            this.setOfflineState(code);
          }
        } else {
          this.setOfflineState(code);
        }
      } else {
        this.setUnavailableState(code);
      }
    });
  }

  private handleOfflineState(channel: string, timeout = false): void {
    const cfsCode = extractCodeFromChannel(channel);

    if (timeout) {
      this.isCFSActive(cfsCode).then((isActive: boolean) => {
        if (!isActive) {
          this.setOfflineState(cfsCode);
        }
      });
    } else {
      this.setOfflineState(cfsCode);
    }
  }

  private setStateFromOccupants(occupants: HereNowResponseOccupant[], code: string): void {
    occupants.forEach((item: HereNowResponseOccupant) => {
      if (item.state) {
        this.state$.next(item.state);
      } else {
        this.setUnavailableState(code);
      }
    });
  }

  private getHereNowFilteredOccupants(channels: HereNowResponseChannels, channel: string): HereNowResponseOccupant[] {
    const channelInfo: HereNowResponseChannel = channels[channel];

    return channelInfo.occupants.filter((item: HereNowResponseOccupant) => !item.uuid.startsWith(this.config.uuid));
  }

  private setOfflineState(cfsCode: string, pairingDate?: number): void {
    console.log("setOfflineState"); // todo: add ConsoleLogHandlerService to common project
    this.state$.next({
      cfsCode,
      status: ConnectionStatus.NOT_PAIRED,
      networkStatus: NetworkStatus.OFFLINE,
      pairingDate
    });
  }

  private setUnavailableState(cfsCode: string, pairingDate?: number): void {
    console.log("setUnavailableState"); // todo: add ConsoleLogHandlerService to common project
    this.state$.next({
      cfsCode,
      status: ConnectionStatus.UNAVAILABLE,
      networkStatus: undefined,
      pairingDate
    });
  }
}
