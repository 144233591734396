import { InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { CFSMessagingService, CommunicatorService, StateService } from "./services";
import { CommunicatorConfig } from "./models";
import { TerminalPairingStepsComponent, TerminalStatusComponent } from "./components";
import { TranslocoModule } from "@ngneat/transloco";

export const COMMUNICATOR_CONFIG_TOKEN = new InjectionToken<string>("communicator_config_token");

@NgModule({
  declarations: [TerminalPairingStepsComponent, TerminalStatusComponent],
  imports: [CommonModule, TranslocoModule],
  exports: [TerminalPairingStepsComponent, TerminalStatusComponent],
  providers: [CommunicatorService, StateService, DatePipe]
})
export class CfsCommunicationPackModule {
  public static forRoot(config: CommunicatorConfig): ModuleWithProviders<CfsCommunicationPackModule> {
    return {
      ngModule: CfsCommunicationPackModule,
      providers: [
        CFSMessagingService,
        {
          provide: COMMUNICATOR_CONFIG_TOKEN,
          useValue: config
        }
      ]
    };
  }
}
