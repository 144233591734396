import { Component, Input, OnInit } from "@angular/core";
import { StepItem } from "../step-item";

@Component({
  selector: "app-step-header",
  templateUrl: "./step-header.component.html",
  styleUrls: ["./step-header.component.scss"]
})
export class StepHeaderComponent implements OnInit {
  @Input() step: StepItem;
  constructor() {}

  ngOnInit(): void {}
}
