export class ApiResponseModel<T> {
  data: T;
}
export class PageModel {
  total: number;
  page: number;
  pageSize: number;
}
export class ApiListResponseModel<T> {
  data: T;
  page: PageModel;
}
