export enum SetReminderType {
  SMSDefaultSettings = 1,
  SMSFromLocalNumber = 2,
  SMSFromTollFreeNumber = 3,
  SMSFromMyStore = 4
}

export enum SetReminderExpiration {
  Default = 0,
  ThreeMonth = 3,
  SixMonth = 6,
  TwelveMonth = 12,
  TwentyFour = 24,
  ThirtySix = 36,
  FortyEight = 48
}

export class SetReminderWidgetSettings {
  public setReminderType: SetReminderType;
  public setReminderExpiration: SetReminderExpiration;
  public setReminderUrl: string;
}
