import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { DashboardComponent, DashboardIconViewWidgetComponent, DashboardListViewWidgetComponent } from "./dashboard";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "@app-cmc/core";
import { CardVerifyComponent } from "@app-cmc/pages/card-verify/card-verify.component";
import { ChooseLocationComponent } from "@app-cmc/pages/choose-location/choose-location.component";
import { SharedModule } from "@app-cmc/shared";
import { NgbAccordionModule, NgbNavModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { FeaturesModule } from "./features/features.module";
import { LandingModule } from "./landing/landing.module";
import { CfsCommunicationPackModule, ManagerMessagingService } from "cfs-communication-pack";
import { CFSConnectionService } from "@app-cmc/services";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CoreModule.forRoot(),
    SharedModule,
    FeaturesModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    NgSelectModule,
    LandingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000"
    }),
    CfsCommunicationPackModule.forRoot(environment.pubNubConfig)
    // ScrollToModule.forRoot()
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    DashboardIconViewWidgetComponent,
    DashboardListViewWidgetComponent,
    ChooseLocationComponent,
    CardVerifyComponent
  ],
  providers: [ManagerMessagingService, CFSConnectionService],
  bootstrap: [AppComponent]
})
export class AppModule {}
