import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Recipient, RecipientItem } from "@app-cmc/features/common";
import { FeatureModalBaseComponent } from "@app-cmc/features/feature-modal-base.component";
import { AllowedDeliveryChannel, Category, CouponInfo, FeatureTypes, WidgetMessageInfo } from "@app-cmc/models";
import { CategoryService, WidgetService } from "@app-cmc/services";
import { CouponService } from "@app-cmc/services/coupon.service";
import { ToastService } from "@app-cmc/shared/components/app-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { BehaviorSubject, Subject } from "rxjs";
import { switchMap, take, takeUntil, tap } from "rxjs/operators";
import { WizardComponent, WizardOptions, WizardStepItem } from "ui-kit";
import { CouponSearchModel } from "../coupon-search-model";

@Component({
  selector: "app-issue-coupon-modal",
  templateUrl: "./issue-coupon-modal.component.html",
  styleUrls: ["./issue-coupon-modal.component.scss"]
})
export class IssueCouponModalComponent extends FeatureModalBaseComponent implements OnInit, OnDestroy {
  wizard: WizardComponent;
  public selectedCoupon: CouponInfo;
  public coupons: Array<CouponInfo> = [];
  public categories: Array<any> = [];
  public couponsFilter = {} as CouponSearchModel;
  public selectedBadge = new Category();
  isOnlyQr = false;
  recipients: Recipient[] = [{} as Recipient];
  loading = true;
  couponsIsLoading = false;
  sessionId = "";
  isSent = false;
  contentReadyToLoad$ = new BehaviorSubject<boolean>(false);
  wizardOptions: WizardOptions = {
    isModalWindow: true,
    button: {
      next: { isWide: true },
      complete: { label: this.transloco.translate("common.controls.send") },
      previous: { label: this.transloco.translate("common.controls.back") }
    }
  };
  steps: WizardStepItem[] = [
    {
      label: this.transloco.translateObject("features.issueCoupon.step1"),
      stepControl: new FormControl<boolean>(false, Validators.requiredTrue)
    }
  ];

  private search$ = new Subject();
  private unsubscribe$ = new Subject();

  get isValid() {
    return this.recipients.length && this.recipients.every((el) => el.valid);
  }
  get isValidFirst() {
    return !!this.recipients[0].email || !!this.recipients[0].phone;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public toastSvc: ToastService,
    private transloco: TranslocoService,
    private couponService: CouponService,
    private widgetService: WidgetService,
    private categoryService: CategoryService
  ) {
    super(activeModal, FeatureTypes.IssueCoupon);
  }

  ngOnInit(): void {
    this.isOnlyQr = this.deliveryMethod === AllowedDeliveryChannel.qr;

    if (!this.isOnlyQr) {
      this.steps.push({
        label: this.transloco.translateObject("features.issueCoupon.step2"),
        stepControl: new FormControl<boolean>(false, Validators.requiredTrue)
      });
    }

    this.wizardOptions = { ...this.wizardOptions, hiddenFooterButtons: this.isOnlyQr };

    this.getCategories();
    this.handleSearchCoupons();

    this.search$.next();
  }

  onCloseModal(): void {
    this.activeModal.dismiss();
  }

  onStepperReady(wizard: WizardComponent): void {
    this.wizard = wizard;
  }

  clickSearchCoupons(): void {
    this.search$.next();
  }

  select(coupon: CouponInfo) {
    this.updateSelectedCoupon(coupon);
  }

  setSessionId(sessionId: string) {
    this.sessionId = sessionId;
  }

  onContentReadyToLoad(): void {
    this.contentReadyToLoad$.next(true);
    this.contentReadyToLoad$.complete();
  }

  confirm() {
    if (!this.isValid) return;
    this.loading = true;
    const messageInfo: WidgetMessageInfo = this.widgetService.getWidgetMessageInfo({
      deliveryMethod: AllowedDeliveryChannel.emailAndSms,
      recipients: this.recipients,
      sessionId: this.sessionId
    });

    this.widgetService
      .getCouponWidgetMessage({ messageType: "complete", messageInfo }, this.selectedCoupon)
      .pipe(take(1))
      .subscribe(
        () => {
          this.dismiss();
          this.isSent = true;
          this.loading = false;
        },
        (error) => {
          if (error.message) {
            this.toastSvc.danger(error.message);
          }
          this.loading = false;
        }
      );
  }

  updateItem(index: number, item: RecipientItem) {
    Object.assign(this.recipients[index], item);
    this.steps[1].stepControl.setValue(this.isValidFirst && this.isValid && !this.loading);
  }

  onBadgetChanged(category: any) {
    this.selectedBadge = category;
    this.search$.next();
  }

  private getCategories() {
    this.categoryService.getCategories().subscribe((categories: Array<any>) => {
      this.categories = categories;
    });
  }

  private handleSearchCoupons(): void {
    this.search$
      .pipe(
        tap(() => {
          this.loading = true;
          this.couponsIsLoading = true;
          this.coupons = null;
          this.couponsFilter.category = this.selectedBadge?.id ? this.selectedBadge?.id : "";
        }),
        switchMap(() => this.couponService.searchCoupon(this.couponsFilter)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (coupons: Array<CouponInfo>) => {
          coupons.sort((a, b) => a.status.localeCompare(b.status));
          this.coupons = coupons;
          this.updateSelectedCoupon(null);
          this.loading = false;
          this.couponsIsLoading = false;
        },
        () => {
          this.loading = false;
          this.couponsIsLoading = false;
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateSelectedCoupon(coupon: CouponInfo) {
    this.selectedCoupon = coupon;
    this.steps[0].stepControl.setValue(!!coupon && !this.isOnlyQr && !this.loading);
  }
}
