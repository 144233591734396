<div class="rewards-block" *transloco="let t; read: 'features.issuePoints'">
  <h4 *ngIf="rewards?.length" class="content-title">
    {{ t("rewards") }}
  </h4>

  <div class="content-text">
    {{ t("redeemWarning") }}
  </div>

  <div class="coupon-carousel-wrapper">
    <div class="coupon-carousel" *ngIf="rewards?.length">
      <div class="coupon-container">
        <ngx-simplebar [options]="scrollbarOptions">
          <app-card-reward *ngFor="let item of rewards" class="coupon" [item]="item"> </app-card-reward>
        </ngx-simplebar>
      </div>
    </div>
  </div>
</div>
