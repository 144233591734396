<div
  *transloco="let t; read: 'features.common'"
  class="card-widget opacity"
  [ngClass]="{ 'cursor-pointer': !disabled, disabled: disabled }"
  [ngbTooltip]="!disabled ? text : isManager ? t('appNotActiveManager') : t('appNotActive')"
  [tooltipClass]="disabled ? 'disabled' : ''"
  #tooltip="ngbTooltip"
  (click)="click(); tooltip.close()"
>
  <div class="icon-view">
    <i class="ri-3x" [ngClass]="icon"></i>
  </div>

  <div class="text-icon-view">
    {{ text }}
  </div>
</div>
