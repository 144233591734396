<div class="coupon-carousel">
  <div *ngIf="coupons?.length" class="coupon-container">
    <ngx-simplebar [options]="scrollbarOptions">
      <app-qr-code-coupon
        *ngFor="let item of coupons; index as i"
        class="coupon"
        [ngClass]="{ 'last-child': i === coupons.length - 1 }"
        [couponInfo]="item"
        [type]="'full'"
        [selectedCoupon]="selectedCoupon"
        (selectCoupon)="clickSelectCoupon($event)"
        (closeRestModals)="onCloseRestModals()"
        (setLoading)="setLoading.emit($event)"
      >
      </app-qr-code-coupon>
    </ngx-simplebar>
  </div>

  <div *ngIf="!coupons?.length && !couponsLoading">
    <h5 class="text-truncate pb-1" *transloco="let t; read: 'features.issueCoupon'">
      {{ t("nothing_found") }}
    </h5>
  </div>
</div>
