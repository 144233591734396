import { Component, Input } from "@angular/core";

@Component({
  selector: "app-widget-message",
  templateUrl: "./widget-message.component.html",
  styleUrls: ["./widget-message.component.scss"]
})
export class WidgetMessageComponent {
  constructor() {}

  @Input() type: "success" | "fail" = "success";
  @Input() successMessage: string;
  @Input() failMessage: string;
}
