import { Injectable } from "@angular/core";
import { DataService } from "@app-cmc/core/services/data.service";
import { Brand, Location, LocationLink, LocationUser, SearchLocationVm } from "@app-cmc/models";

import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class MarketService {
  private serviceUrl = "Market";
  constructor(private http: DataService) {}
  getBrands(): Observable<Brand[]> {
    return this.http.get(`${this.serviceUrl}/brands`);
  }
  getUserBrands(userId: number): Observable<Brand[]> {
    return this.http.get(`${this.serviceUrl}/brand/user/${userId}`);
  }
  getLocationUsersIds(locationId: number): Observable<number[]> {
    return this.getLocationsUsers().pipe(map((uls) => uls.filter((ul) => ul.locationId === locationId).map((ul) => ul.userId)));
  }
  getLocationsUsers(): Observable<LocationUser[]> {
    return this.http.get(`${this.serviceUrl}/userlocation/all`);
  }
  getLocations(): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}/locations`);
  }
  searchLocations(searchLocationVm: SearchLocationVm): Observable<Location[]> {
    return this.http.post(`${this.serviceUrl}/locations/search`, searchLocationVm);
  }
  getLocationLinks(): Observable<LocationLink[]> {
    return this.http.get(`${this.serviceUrl}/locationLinks`);
  }
  getBrandLocations(brandId: number): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}/locations/${brandId}`);
  }
  getUserLocations(userId: number): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}/locations/user/${userId}`);
  }

  getBrandsByLocation(locationId: number): Observable<Brand[]> {
    return this.http.get(`${this.serviceUrl}/brandsByLocation/${locationId}`);
  }
}
