import { Component, OnInit, TemplateRef } from "@angular/core";
import { ToastService } from "./toast.service";
import { Toast } from "./toaster";

@Component({
  selector: "app-toasts",
  templateUrl: "./app-toaster.component.html",
  styleUrls: ["./app-toaster.component.scss"],
  host: { "[class.ngb-toasts]": "true" }
})
export class ToasterComponent implements OnInit {
  constructor(public toastService: ToastService) {}

  isTemplate(toast: Toast) {
    return toast.content instanceof TemplateRef;
  }
  ngOnInit(): void {}
}
