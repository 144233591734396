import { Injectable } from "@angular/core";
import {
  CFSConnectionMessage,
  CFSConnectionSettings,
  CFSMessageInfo,
  CFSPairingState,
  CFSQrCodeInfo,
  CFSRequestResult,
  CFSScanQrCodeInfo,
  ClientCashRegister,
  CMCCommunicationChannels,
  ConnectionStatus,
  DisplayQrCodeInfo,
  getFullChannelName,
  ManagerMessagingService
} from "cfs-communication-pack";
import { ToastService } from "@app-cmc/shared/components/app-toaster";
import { filter, map, take, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";
import { CashRegisterService } from "@app-cmc/services/cash-register.service";
import { CashRegisterStatusLoggerService } from "@app-cmc/services/cash-register-status-logger.service";

@Injectable()
export class CFSConnectionService {
  constructor(
    private messagingService: ManagerMessagingService,
    public toastSvc: ToastService,
    private translateService: TranslocoService,
    private cashRegisterService: CashRegisterService,
    private statusLoggerService: CashRegisterStatusLoggerService
  ) {}

  readonly qrCodeModalInfoStorageKey = "QR_code_opened_modal_info";
  readonly scanQrCodeModalInfoStorageKey = "scan_QR_code_modal_info";

  private localItems = {};
  private cfsCode: string;
  private cashRegisterId: number;
  private locationId: number;

  get connectedCode(): string {
    const cashRegister: ClientCashRegister = this.cashRegisterService.getClientCashRegisterFromStorage();

    return cashRegister?.clientStatus === ConnectionStatus.PAIRED ? cashRegister.cfsCode : "";
  }

  get qrCodeModalInfo(): CFSQrCodeInfo {
    return this.getQrCodeModalInfoFromStorage();
  }

  get scanQrCodeModalInfo(): CFSScanQrCodeInfo {
    return this.getScanQrCodeModalInfoFromStorage();
  }

  init(userId: string): void {
    // todo: remove condition after fixing CMC user reload issue
    if (!this.messagingService.isUserIdApplied(userId)) {
      this.messagingService.init(userId);
    }
  }

  getState(): Observable<CFSPairingState> {
    // should be run only one on init
    return this.messagingService.state$.pipe(
      tap((state: CFSPairingState) => {
        const { cfsCode, status } = state;

        if (cfsCode && cfsCode === this.cfsCode && status === ConnectionStatus.PAIRED) {
          const subscribedChannels: string[] = this.messagingService.getSubscribedChannels();
          const showWidgetChannel: string = getFullChannelName(CMCCommunicationChannels.SHOW_WIDGET, cfsCode);

          console.log("SubscribedChannels: ", subscribedChannels); // do not remove, id needed for testing

          if (!subscribedChannels?.includes(showWidgetChannel)) {
            console.log("Current cashregister with status paired has successful condition to run subscribeOnShowWidget method", cfsCode); // fixme: remove after testing
            this.messagingService.subscribeOnShowWidget(cfsCode);
          }
        }
      })
    );
  }

  updateCashRegisterData(locationId: number, cfsCode: string, cashRegisterId: number): void {
    this.cfsCode = cfsCode;
    this.cashRegisterId = cashRegisterId;
    this.locationId = locationId;
  }

  runWatchingStatuses(cfsCode: string): void {
    // should be run only one on init
    this.messagingService.runWatchingStatuses(
      {
        cfsCode,
        id: this.cashRegisterId,
        locationId: this.locationId
      },
      true
    );
  }

  publishReturnStatus(cfsCode: string): void {
    this.messagingService.publishReturnStatus(cfsCode, this.cashRegisterId, this.locationId);
  }

  showQrCodeModal(sessionId: string): void {
    const info: CFSQrCodeInfo = { sessionId };

    this.setQrCodeModalInfoToStorage(info);
  }

  hideQrCodeOnCmc(): Observable<CFSConnectionMessage> {
    return this.messagingService.shareHideQrCode$;
  }

  onShowWidget(): Observable<CFSConnectionMessage> {
    return this.messagingService.onShowWidget$.pipe(
      filter((info: CFSMessageInfo) => {
        const { cfsCode, cashRegisterId, locationId } = info || {};

        return cfsCode && cfsCode === this.cfsCode && cashRegisterId === this.cashRegisterId && locationId === this.locationId;
      }),
      map((info: CFSMessageInfo) => info.message)
    );
  }

  publishHideQrCodeMessage(): void {
    const qrCodeModal = this.getQrCodeModalInfoFromStorage();

    if (this.connectedCode) {
      const cashRegisterId: number = this.cashRegisterService.getSelectedCashRegisterIdFromStorage();
      const settings: CFSConnectionSettings = this.getCFSConnectionSettings(cashRegisterId);
      const qrHideCodeInfo: DisplayQrCodeInfo = { sessionId: qrCodeModal.sessionId };

      this.messagingService.publishHideQrCode(qrHideCodeInfo, this.connectedCode, settings);
    }
  }

  toggleScanQrCodeModal(open: boolean = true): Observable<string> {
    if (this.connectedCode) {
      const info: CFSScanQrCodeInfo = { open };
      const cashRegisterId: number = this.cashRegisterService.getSelectedCashRegisterIdFromStorage();
      const settings: CFSConnectionSettings = this.getCFSConnectionSettings(cashRegisterId);

      this.messagingService.publishScanQrCode(info, this.connectedCode, settings);

      if (open) {
        this.setScanQrCodeModalInfoToStorage(info);

        return this.messagingService.scannedQrCode$;
      } else {
        this.setScanQrCodeModalInfoToStorage(null);
        this.messagingService.resetScannedQrCodeResult();
      }
    }

    return of(null);
  }

  destroySubscriptions(): void {
    this.messagingService.destroyMessages(true);
  }

  handleOnBeforeLoad(): void {
    this.clearQrCodeModalsInfo();
  }

  pairing(currentCashRegisterId: number, currentCode: string, oldCode: string = "") {
    // todo: add to common project
    const settings: CFSConnectionSettings = this.getCFSConnectionSettings(currentCashRegisterId);
    this.messagingService.startPairing(currentCode, currentCashRegisterId, settings, oldCode, true);

    return this.messagingService.pairingResult$.pipe(
      filter((result: CFSRequestResult) => result?.code === currentCode),
      tap((result: CFSRequestResult) => {
        const { confirmed, code } = result;

        if (confirmed) {
          this.statusLoggerService.logSuccessPairing(currentCashRegisterId);
          this.confirmCodeSuccess(result);
        } else {
          const message: string = this.translateService.translateObject("cfs.mainModal.notifications.not_paired", {
            code: code
          });
          this.statusLoggerService.logFailedPairing(currentCashRegisterId, message);
          this.toastSvc.danger(message);
        }
      }),
      take(1)
    );
  }

  publishUpdateCarousel(settings: CFSConnectionSettings, pinCode: string): void {
    this.messagingService.publishUpdateCarousel(settings, pinCode);
  }

  getCFSConnectionSettings(cashRegisterId: number): CFSConnectionSettings {
    return {
      locationId: this.locationId,
      cashRegisterId
    };
  }

  private clearQrCodeModalsInfo(): void {
    this.setQrCodeModalInfoToStorage();
    this.setScanQrCodeModalInfoToStorage();
  }

  private setQrCodeModalInfoToStorage(info: CFSQrCodeInfo = null): void {
    if (info) {
      this.localItems[this.qrCodeModalInfoStorageKey] = JSON.stringify(info);
    } else {
      this.localItems[this.qrCodeModalInfoStorageKey] = null;
    }
  }

  private setScanQrCodeModalInfoToStorage(info: CFSScanQrCodeInfo = null): void {
    if (info) {
      this.localItems[this.scanQrCodeModalInfoStorageKey] = JSON.stringify(info);
    } else {
      this.localItems[this.scanQrCodeModalInfoStorageKey] = null;
    }
  }

  private confirmCodeSuccess(result: CFSRequestResult): void {
    const { code, oldCode } = result;
    const isItReconnect = code && oldCode && code !== oldCode;

    if (isItReconnect) {
      const message: string = this.translateService.translateObject("cfs.mainModal.notifications.reconnected", { code: code });
      this.toastSvc.success(message);
    } else {
      const message: string = this.translateService.translateObject("cfs.mainModal.notifications.paired", { code: code });
      this.toastSvc.success(message);
    }
  }

  private getQrCodeModalInfoFromStorage(): CFSQrCodeInfo {
    if (this.localItems[this.qrCodeModalInfoStorageKey]) return JSON.parse(this.localItems[this.qrCodeModalInfoStorageKey]);
    else return null;
  }

  private getScanQrCodeModalInfoFromStorage(): CFSScanQrCodeInfo {
    if (this.localItems[this.scanQrCodeModalInfoStorageKey]) return JSON.parse(this.localItems[this.scanQrCodeModalInfoStorageKey]);
    else return null;
  }
}
