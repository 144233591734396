export class LandingPage {
  public id: number;
  public name: string;
  public publishedDate: Date;
  public purpose: LandingPurposes;
  public content: string;
}
export enum LandingPurposes {
  None = 0,
  IssueCoupon = 1,
  ValidateCoupon = 2,
  EnrollToLoyalty = 3,
  IssuePointsOrStamps = 4,
  RequestReview = 5,
  SetReminder = 6,
  AddPromo = 7,
  DownloadMobileApp = 8,
  ConnectSocialNetwork = 9,
  OneTimePayment = 10,
  AnniversaryClub = 11,
  SendGiftCard = 12,
  SendSurvey = 13,
  AddToWaitlist = 14,
  AddToReferralProgram = 15,
  ScheduleAppointment = 16
}

export enum LandingVariableType {
  Text = 1,
  Img = 2,
  Url = 3,
  ImgOrUrl = Img | Url
}
