export enum NotificationSeverity {
  info = 0,
  warning = 1,
  error = 2
}

export enum NotificationAction {
  informUser = 1,
  blockAccount = 2,
  addActionLink = 4
}

export class NotificationModel {
  public severity: NotificationSeverity;
  public messageText: string;
  public action: NotificationAction;
}
