import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";
import { Category } from "../models/category/category";

@Injectable({
  providedIn: "root"
})
export class CategoryService {
  private serviceUrl = "category";

  constructor(private http: DataService) {}

  getCategories(): Observable<Array<Category>> {
    return this.http.get(`${this.serviceUrl}`, {});
  }
}
