<div
  class="validate-coupon-modal"
  [ngClass]="{ wide: wizard?.currentStep?.stepTitle === t('title') }"
  *transloco="let t; read: 'features.validateCoupon'"
>
  <div class="modal-header">
    <h5 class="modal-title">
      {{ t("title") }}
    </h5>

    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
      <aw-wizard-step class="wizard-step" [stepTitle]="t('searchCoupon')">
        <ng-template awWizardStepSymbol>
          <span>1</span>
        </ng-template>

        <div class="content wizard-content" *ngIf="!loading; else spinner">
          <form>
            <div class="search-coupon-block">
              <label class="col-form-label"> {{ t("couponCode") }}</label>

              <div class="position-relative">
                <input
                  type="text"
                  class="form-control with-action"
                  [formControl]="code"
                  [class.is-invalid]="code.touched && !isUsed && (!isValid || invalidCoupon)"
                />

                <ng-container *ngIf="code.touched && code.errors?.required">
                  <div class="invalid-feedback position-absolute" *transloco="let t; read: 'features.common'">
                    {{ t("requiredField") }}
                  </div>
                </ng-container>

                <ng-container *ngIf="invalidCoupon">
                  <div class="invalid-feedback position-absolute">{{ t("invalidCoupon") }} {{ t("tryAnother") }}</div>
                </ng-container>

                <div class="form-control-action">
                  <app-qr-code-reader-button
                    class="form-control-action-btn"
                    (scanModalOpened)="onScanModalOpened()"
                    (scanResult)="scan($event)"
                  ></app-qr-code-reader-button>
                </div>
              </div>
            </div>
          </form>

          <div *ngIf="invalidCoupon && !loading" class="search-empty-block">
            <span class="content-text" *transloco="let t; read: 'features.issueCoupon'">
              {{ t("nothing_found") }}
            </span>
          </div>
        </div>

        <div class="modal-footer inner one-action" *transloco="let t; read: 'common.controls'">
          <button
            type="button"
            class="btn btn-main"
            [ngbTooltip]="t('search')"
            [class.disabled]="!isValid || invalidCoupon || loading"
            #tooltip="ngbTooltip"
            (click)="search(); tooltip.close()"
          >
            {{ t("search") }}
          </button>
        </div>
      </aw-wizard-step>

      <aw-wizard-step class="wizard-step" [stepTitle]="t('title')">
        <ng-template awWizardStepSymbol>
          <span>2</span>
        </ng-template>

        <div class="content validate-coupon-block" *ngIf="!loading && coupon; else spinner">
          <app-widget-message
            *ngIf="isValidated || isUsed"
            class="validate-message"
            [type]="isValidated ? 'success' : 'fail'"
            [successMessage]="'features.validateCoupon.validated' | transloco"
            [failMessage]="'features.validateCoupon.used' | transloco"
          >
          </app-widget-message>

          <div class="valid-coupon">
            <div class="valid-coupon__column valid-coupon__img-content">
              <ng-template #couponpopContent>
                <div [innerHtml]="coupon.description"></div>
              </ng-template>

              <div class="valid-coupon__img-wrapper">
                <img
                  alt=""
                  class="valid-coupon__img"
                  width="100%"
                  placement="right"
                  container="body"
                  [ngbPopover]="couponpopContent"
                  [popoverTitle]="t('description')"
                  triggers="mouseenter:mouseleave"
                  [src]="coupon.couponImage"
                />
              </div>
            </div>

            <app-validate-coupon-details
              class="valid-coupon__column valid-coupon__content"
              [isUsed]="isUsed"
              [coupon]="coupon"
              [couponStatus]="couponStatus"
            >
            </app-validate-coupon-details>
          </div>

          <app-validate-coupon-additional-details class="valid-coupon__content" [user]="user"> </app-validate-coupon-additional-details>
        </div>

        <div class="modal-footer inner one-action" *transloco="let t; read: 'common.controls'">
          <button
            *ngIf="!hideValidateButton"
            type="button"
            class="btn btn-main"
            [ngbTooltip]="t('validate')"
            [class.disabled]="!isValid || loading"
            #tooltip="ngbTooltip"
            (click)="validate(); tooltip.close()"
          >
            {{ t("validate") }}
          </button>
        </div>
      </aw-wizard-step>
    </aw-wizard>

    <ng-template #spinner>
      <div class="spinner-wrapper">
        <span class="spinner-border spinner-border-sm mr-1"> </span>
      </div>
    </ng-template>
  </div>
</div>
