import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { AuthService } from "../../core/services/auth.service";
import { AccountService } from "@app-cmc/core/services/account.service";
import { LocationService, BrandService } from "@app-cmc/services";
import { Location, Brand } from "@app-cmc/models";
import { ProfileModel } from "@app-cmc/core/models/profile-model";

@Component({
  selector: "app-choose-location",
  templateUrl: "./choose-location.component.html",
  styleUrls: ["./choose-location.component.scss"]
})
export class ChooseLocationComponent implements OnInit {
  locations: Location[] = [];
  locationId: number;
  logo: string;

  constructor(
    private router: Router,
    public locationService: LocationService,
    private accountService: AccountService,
    private oauthService: OAuthService,
    private authService: AuthService,
    private brandService: BrandService
  ) {}

  ngOnInit(): void {
    this.locationService.getAll().subscribe((res) => {
      this.locations = res;
      this.locationId = this.locationService.getSelectedLocationLocalStorage();
      if (!this.locationId) {
        this.locationId = res[0].id;
      }
    });

    this.accountService.userChanged.subscribe((u: ProfileModel) => {
      if (u) {
        this.brandService.getBrandById(u.brandId).subscribe((brand: Brand) => {
          brand !== null ? (this.logo = brand.logo) : (this.logo = "assets/clearline-logo.png");
        });
      }
    });
  }

  locationRepresentation(loc: Location): string {
    return `${loc.companyName}, ${loc.address}, ${loc.city}, ${loc.state}, ${loc.zip}`;
  }

  goToDashboard() {
    this.router.navigate(["/"]);
  }

  goToAccessDenied() {
    this.router.navigate(["/access-denied"]);
  }

  selectLocation(id: number) {
    this.locationId = id;
    this.locationService.setSelectedLocationLocalStorage(id);
  }

  accept() {
    (this.oauthService.customQueryParams as any).location = this.locationId;
    this.authService.refreshToken().then((_) => {
      this.accountService.userChanged.subscribe((u: ProfileModel) => {
        if (u && u.locationId) {
          this.goToDashboard();
        }
      });
    });
  }
}
