<div *transloco="let t; read: 'features.validateCoupon'" class="table-custom-wrapper">
  <h4 class="coupon-detail-title">{{ t("details") }}</h4>

  <table class="table-custom table-nowrap mb-0">
    <tbody>
      <tr class="table-custom__row">
        <th class="table-custom__title" scope="row">{{ t("details.name") }}</th>
        <td class="table-custom__value">
          <span class="table-custom__value-text text-uppercase">
            {{ coupon.name }}
          </span>
        </td>
      </tr>

      <tr class="table-custom__row">
        <th class="table-custom__title" scope="row">{{ t("details.title") }}</th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ couponStatus.value }}
          </span>
        </td>
      </tr>

      <tr class="table-custom__row">
        <th class="table-custom__title" scope="row">{{ t("claimDate") }}</th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ couponStatus.claimDateUtc }}
          </span>
        </td>
      </tr>

      <tr class="table-custom__row">
        <th class="table-custom__title" scope="row">{{ t("value") }}</th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ couponStatus.value }}
          </span>
        </td>
      </tr>

      <tr *ngIf="isUsed" class="table-custom__row">
        <th class="table-custom__title" scope="row">{{ t("validateDate") }}</th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ couponStatus.validationDateUtc }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
