import { Injectable, TemplateRef } from "@angular/core";
import { Toast, ToastOptions } from "./toaster";

@Injectable({ providedIn: "root" })
export class ToastService {
  toasts: Toast[] = [];
  public delay = 5000;
  constructor() {}

  info(content: string | TemplateRef<any>) {
    this.show(content, { delay: this.delay, className: "bg-info text-light" });
  }
  success(content: string | TemplateRef<any>) {
    this.show(content, { delay: this.delay, className: "bg-success text-light" });
  }
  danger(content: string | TemplateRef<any>) {
    this.show(content, { delay: this.delay, className: "bg-danger text-light" });
  }

  show(content: string | TemplateRef<any>, options: ToastOptions = { delay: this.delay, className: "" }) {
    this.toasts.push({ content, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
