export class BaseAuditModel {
  className: string;
}
export class AuditMessage<T extends BaseAuditModel> {
  public auditMessageType: string;
  public data: T;

  constructor(data: T) {
    this.auditMessageType = data.className;
    this.data = data;
  }
}
