<div class="my-5 pt-sm-5">
  <div class="container" *transloco="let t; read: 'pendingConfig'">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <a routerLink="/" class="d-block auth-logo">
            <img [src]="brand?.logo" alt="" height="50" class="auth-logo-dark mx-auto" />
          </a>
          <div class="row justify-content-center mt-5">
            <div class="col-sm-4">
              <div class="maintenance-img">
                <img src="assets/images/coming-soon.svg" alt="" class="img-fluid mx-auto d-block" />
              </div>
            </div>
          </div>
          <h4 class="mt-5">{{ t("beingConfigured") }}</h4>
          <p class="text-muted">{{ t("standBy") }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
