import { AllowedDeliveryChannel } from ".";

export enum FeatureTypes {
  IssueCoupon = 1,
  ValidateCoupon = 2,
  EnrollLoyalty = 3,
  IssuePoints = 4,
  RequestReview = 5,
  SetReminder = 6,
  AddPromo = 7,
  DownloadMobileApp = 8,
  ConnectSocialMedia = 9,
  SendPaymentLink = 10,
  AnniversaryClub = 11,
  SendGiftCard = 12,
  SendSurvey = 13,
  Waitlist = 14,
  ReferralProgram = 15,
  ScheduleAppointment = 16,
  CustomerFacingScreen = 30,
  FAQ = "faq",
  SmartPage = 34,
  SeeMenu = 37
}
export enum FeatureCodes {
  EnrollLoyalty = "enrollLoyalty",
  RequestReview = "requestReview",
  IssueCoupon = "issueCoupon",
  IssuePoints = "issuePoints",
  ValidateCoupon = "validateCoupon",
  ScheduleAppointment = "scheduleAppoitment",
  Waitlist = "waitlist",
  SendSurvey = "sendSurvey",
  DownloadMobileApp = "downloadMobileApp",
  SendGiftCard = "sendGiftCard",
  SetReminder = "setReminder",
  ConnectSocialMedia = "connectSocialMedia",
  AddPromo = "addPromo",
  SendPaymentLink = "sendPaymentLink",
  AnniversaryClub = "anniversaryClub",
  ReferralProgram = "referralProgram",
  FAQ = "faq",
  SmartPage = "smartPage",
  SeeMenu = "seeMenu"
}

export class FeatureModel {
  get code() {
    return this.id.toString();
  }
  public deliveryMethod?: AllowedDeliveryChannel;
  constructor(public id: FeatureTypes, public name: string, public icon: string, public disabled = false) {}
}

export interface FeatureData {
  userCardCode?: string;
}
