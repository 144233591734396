export class VendastaAccount {
  companyName: string;
  youtubeUrl: string;
  address2: string;
  contactLastName: string;
  contactFirstName: string;
  cellNumber: number;
  address: string;
  contactEmail: string;
  email: string;
  accountId: string;
}
