import { Component, Input } from "@angular/core";
import { LoyaltyCardReward } from "@app-cmc/models";

@Component({
  selector: "app-card-rewards-block",
  templateUrl: "./card-rewards-block.component.html",
  styleUrls: ["./card-rewards-block.component.scss"]
})
export class CardRewardsBlockComponent {
  constructor() {}

  @Input() rewards: Array<LoyaltyCardReward>;

  scrollbarOptions: any = {
    forceVisible: false,
    autoHide: false
  };
}
