export class RecipientProfile {
  public id?: string;
  public customerEmail?: string;
  public customerPhone?: string;
  public firstName? = "";
  public lastName? = "";
  public gender?: boolean;

  public salesPerson: string;
  public purchaseName = "";
  public purchaseId = "";
  public purchaseCategory = "";
  public purchasePrice = "";
}
