import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";
import { CouponSearchModel } from "../features/issue-coupon/coupon-search-model";
import { CouponInfo } from "../models/coupons/coupon-info";
import { CouponSession, CouponSessionUpdateResult, UpdateCouponSession } from "../models/coupons";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CouponService {
  private serviceUrl = "Coupon";

  constructor(private http: DataService) {}

  searchCoupon(filter: CouponSearchModel): Observable<Array<CouponInfo>> {
    return this.http.get(`${this.serviceUrl}/search`, filter);
  }
  getCouponSessionByCode(code: string): Observable<CouponSession> {
    return this.http.post(`${this.serviceUrl}/searchSessionByCode/${code}`);
  }
  validateCoupon(updateCouponSession: UpdateCouponSession): Observable<CouponSessionUpdateResult> {
    return this.http.post(`${this.serviceUrl}/validateSession/`, updateCouponSession);
  }
}
