import { AuthConfig } from "angular-oauth2-oidc";

export function authConfig(authconf: IAuthConfig): AuthConfig {
  const conf: AuthConfig = {
    // Url of the Identity Provider
    issuer: authconf.identityServerUrl,
    // URL of the SPA to redirect the user to after login
    redirectUri: authconf.redirectUrl,
    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: authconf.clientId,
    requireHttps: false,
    // set the scope for the permissions the client should request
    scope: authconf.scope,
    skipIssuerCheck: true,
    useSilentRefresh: true,
    postLogoutRedirectUri: authconf.logoutUrl,
    strictDiscoveryDocumentValidation: false,
    clearHashAfterLogin: true
  };
  return conf;
}
