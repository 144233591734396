export class LoyaltyCardUser {
  public id: string;
  public firstName: string;
  public profilePicture: string;
  public gender: string;
  public phone: string;
  public email: string;
  public stamps: number;
  public points: number;
  public lastName: string;
}
