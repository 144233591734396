<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <p [innerHTML]="body"></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="dismiss()">
    {{ cancelBtnText }}
  </button>
  <button type="button" [class.btn-danger]="isDanger" [class.btn-primary]="!isDanger" class="btn" (click)="confirm()">
    {{ confirmBtnText }}
  </button>
</div>
