<div class="default-widget-modal">
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    [title]="'common.controls.close' | transloco"
    (click)="dismiss()"
  ></button>

  <iframe [src]="settings.safeHtmlUrl" class="qr-code-iframe"></iframe>
</div>
