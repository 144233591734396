import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class SpinnerService {
  private isVisibleSrc = new BehaviorSubject<boolean>(false);

  isVisible$ = this.isVisibleSrc.asObservable();

  show() {
    this.isVisibleSrc.next(true);
  }

  hide() {
    this.isVisibleSrc.next(false);
  }
}
