import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AccountService } from "@app-cmc/core";
import { FeatureModalBaseComponent } from "@app-cmc/features/feature-modal-base.component";
import {
  FeatureTypes,
  Location,
  LoyaltyCard,
  LoyaltyCardReward,
  LoyaltyCardUpdateAmount,
  LoyaltyCardUpdateResult,
  LoyaltyCardUser,
  LoyaltyCardUserResult,
  LoyaltyCardUserSearchModel
} from "@app-cmc/models";
import { CFSConnectionService, LocationService, LoyaltyCardService } from "@app-cmc/services";
import { ToastService } from "@app-cmc/shared/components/app-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { Subject } from "rxjs";
import { finalize, switchMap, take, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "app-issue-points-modal",
  templateUrl: "./issue-points-modal.component.html",
  styleUrls: ["./issue-points-modal.component.scss"]
})
export class IssuePointsModalComponent extends FeatureModalBaseComponent implements OnInit, OnDestroy {
  isValidated = false;
  isUsed = false;
  loading = false;
  nothingFound = false;
  newAmount: number;
  serverError: string;

  points: FormControl;
  form: FormGroup;

  loyaltyCard: LoyaltyCard;
  loyaltyUser: LoyaltyCardUser;
  selectedCard: LoyaltyCard;
  rewards: Array<LoyaltyCardReward>;

  get isValid() {
    return this.form.valid;
  }

  get cardSelected() {
    return !!this.selectedCard;
  }

  get searchControl(): FormControl {
    return this.form.controls.search as FormControl;
  }

  private currentLocation: Location;
  private unsubscribe$ = new Subject();

  private get loyaltyCardSearchModel(): LoyaltyCardUserSearchModel {
    const model = new LoyaltyCardUserSearchModel();
    model.cardId = this.selectedCard.id;
    model.search = this.form.controls.search?.value?.trim();

    return model;
  }

  constructor(
    public activeModal: NgbActiveModal,
    public accountService: AccountService,
    private locationService: LocationService,
    public translateSvc: TranslocoService,
    public toastSvc: ToastService,
    private loyaltyCardService: LoyaltyCardService,
    private cfsService: CFSConnectionService
  ) {
    super(activeModal, FeatureTypes.IssuePoints);
  }

  ngOnInit(): void {
    this.setForms();
    this.setLoyaltyCards();
  }

  onScanModalOpened(): void {
    this.cfsService.toggleScanQrCodeModal(true).pipe(take(1)).subscribe();
  }

  scan(userCardLink: string) {
    this.onScanModalClose();
    const invalidUrlMessage = this.translateSvc.translateObject("features.issuePoints.notValidUrl");

    if (!userCardLink || !userCardLink.startsWith("http")) {
      this.toastSvc.danger(invalidUrlMessage);
      return;
    }

    this.loyaltyCard ??= this.selectedCard; // fixme (the same?)

    const params = userCardLink.split("/");
    const userCode = params.pop();
    const cardCode = params.pop();
    const card = this.loyaltyCard.code === cardCode ? this.loyaltyCard : null;

    if (!card) {
      this.toastSvc.danger(invalidUrlMessage);
      return;
    }

    this.onSelectChanged(card);
    this.form.controls.search.setValue(userCode);
    this.search();
  }

  search() {
    this.resetBeforeSearch();
    this.resetPointsForm();

    this.loading = true;

    this.loyaltyCardService
      .getUserLoyaltyCard(this.loyaltyCardSearchModel)
      .pipe(take(1))
      .subscribe(
        (result: LoyaltyCardUserResult) => {
          this.loading = false;

          if (result?.loyaltycard) {
            this.loyaltyCard = result.loyaltycard;
            this.loyaltyUser = result.user;
          } else {
            this.nothingFound = true;
          }
        },
        () => {
          this.loading = false;
          this.nothingFound = true;
        }
      );
  }

  submit() {
    this.serverError = null;
    this.loading = true;
    const location = this.loyaltyCard.locations.find((l) => l.name === this.currentLocation.companyName);

    if (location) {
      const payload: LoyaltyCardUpdateAmount = {
        merchantid: `${location.id}`,
        amount: this.points.value,
        user: this.loyaltyUser.id,
        loyaltycard: this.loyaltyCard.id,
        locationId: this.currentLocation.id
      };

      this.loyaltyCardService
        .addUserLoyaltyCardAmount(payload)
        .pipe(
          take(1),
          finalize(() => (this.loading = false))
        )
        .subscribe(
          (result: LoyaltyCardUpdateResult) => {
            this.loading = false;

            if (result?.loyaltycard) {
              this.isUsed = true;
              this.loyaltyCard.type === "stampcard"
                ? (this.loyaltyUser.stamps = result.newAmount)
                : (this.loyaltyUser.points = result.newAmount);
              this.newAmount = result.addedAmount;
              this.rewards = result.rewardsAdded;
            } else {
              this.toastSvc.danger("Loyalty card not found");
            }
          },
          (error) => {
            this.serverError = error.error.Message;
          }
        );
    } else {
      this.loading = false;

      this.toastSvc.danger("Location doesn't match");
    }
  }

  onSelectChanged(loyaltyCard: LoyaltyCard) {
    this.selectedCard = loyaltyCard;
  }

  private onScanModalClose(): void {
    this.cfsService
      .toggleScanQrCodeModal(false)
      .pipe(take(1))
      .subscribe(() => {});
  }

  private resetBeforeSearch(): void {
    this.serverError = null;
    this.nothingFound = false;
    this.newAmount = null;
  }

  private setForms(): void {
    this.points = new FormControl(null, [Validators.required]);
    this.form = new FormGroup({
      search: new FormControl("", [Validators.required, Validators.minLength(5)])
    });

    this.handleFormsValueChange();
  }

  private handleFormsValueChange(): void {
    this.points.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.serverError = null;
    });

    this.form.controls.search.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.serverError = null;
    });
  }

  private setLoyaltyCards(): void {
    this.loading = true;

    const locationId = +this.accountService.user.locationId;
    this.locationService
      .getAll()
      .pipe(
        tap((locations) => {
          this.currentLocation = locations.find((l) => l.id === locationId);
        }),
        switchMap(() => this.loyaltyCardService.getAccountLoyaltyCard())
      )
      .subscribe(
        (loyaltyCard) => {
          this.onSelectChanged(loyaltyCard);
          this.applyAutoSearch();
        },
        () => {
          this.loading = false;
        }
      );
  }

  private applyAutoSearch(): void {
    if (this.data?.userCardCode) {
      this.form.controls.search.setValue(this.data.userCardCode, { emitEvent: false });
      this.search();
    } else {
      this.loading = false;
    }
  }

  private resetPointsForm() {
    this.points.setValue("");
    this.clearPointsFormValidators();
  }

  private clearPointsFormValidators(): void {
    this.points.markAsUntouched();
    this.points.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
