import { NgModule } from "@angular/core";
import { DefaultTemplateFeatureModalComponent } from "./default-template-feature-modal/default-template-feature-modal.component";

import { CommonFeatureModule } from "../common/common-feature.module";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule, IConfig } from "ngx-mask";

const maskConfig: Partial<IConfig> = {
  validation: false
};

@NgModule({
  declarations: [DefaultTemplateFeatureModalComponent],
  imports: [NgxMaskModule.forRoot(maskConfig), CommonFeatureModule, NgbAccordionModule]
})
export class DefaultTemplateFeatureModule {}
