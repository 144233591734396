import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ProfileModel } from "@app-cmc/core/models";
import { AccountService } from "@app-cmc/core/services/account.service";
import { AuthService } from "@app-cmc/core/services/auth.service";
import { Location } from "@app-cmc/models";
import { CashRegisterService, CashRegistersService, LocationService } from "@app-cmc/services";
import { OAuthService } from "angular-oauth2-oidc";
import { Subject } from "rxjs";
import { IFilterSelectItem } from "ui-kit";
import { filter, take, takeUntil } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { CashRegister } from "cfs-communication-pack";

@Component({
  selector: "app-header-select-location",
  templateUrl: "./header-select-location.component.html",
  styleUrls: ["./header-select-location.component.scss"]
})
export class HeaderSelectLocationComponent implements OnInit, OnDestroy {
  location = new Location();
  locations: Location[] = [];
  cashRegister: CashRegister;
  cashRegisters: CashRegister[] = [];
  @Input() user: ProfileModel;
  controlLocation = new FormControl<number>(null);
  locationsList?: IFilterSelectItem<number>[] = [];

  controlCashRegister = new FormControl<number>(null);
  cashRegistersList?: IFilterSelectItem<number>[] = [];

  private unsubscribe$ = new Subject<void>();

  private get brandId() {
    return this.accountService.user?.brandId;
  }

  constructor(
    public locationService: LocationService,
    public cashRegisterService: CashRegisterService,
    private accountService: AccountService,
    private oauthService: OAuthService,
    private authService: AuthService,
    private cashRegistersService: CashRegistersService,
    private translateService: TranslocoService
  ) {}

  ngOnInit() {
    this.setCashRegistersData();

    this.controlLocation.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      this.setLocations(x);
    });

    this.controlCashRegister.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((x) => {
      this.setCashRegisters(x);
    });

    this.locationService
      .getAll()
      .pipe(take(1))
      .subscribe((res) => {
        if (res && res.length) {
          this.locations = res;
          this.location = res[0];
          this.locationsList = this.locations?.map((x) => ({
            value: +x.id,
            title: this.getInfoLocation(x)
          }));
          const locationId = this.locationService.getSelectedLocationLocalStorage();

          if (locationId && this.locations.some((f) => f.id === locationId)) {
            this.location = this.locations.find((f) => f.id === locationId);
            this.reloadCashRegisters();
          } else {
            this.controlLocation.setValue(this.location?.id);
          }
        }
      });
  }

  private setCashRegistersData() {
    this.cashRegistersService.cashRegisters$
      .pipe(
        filter((list: CashRegister[]) => list.length > 0),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((cashRegisters) => {
        this.cashRegisters = cashRegisters;
        this.cashRegister = cashRegisters[0];
        this.cashRegistersList = this.cashRegisters?.map((x) => ({
          value: +x.id,
          title: this.getInfoCashRegister(x)
        }));
        const cashRegisterId = this.cashRegisterService.getSelectedCashRegisterIdFromStorage();

        if (cashRegisterId && this.cashRegisters.some((f) => f.id === cashRegisterId)) {
          this.cashRegister = this.cashRegisters.find((f) => f.id === cashRegisterId);
          const currentUser = this.accountService.user;

          if (+currentUser?.locationId !== this.location?.id || +currentUser?.cashRegisterId !== this.cashRegister?.id) {
            this.accept(this.location, this.cashRegister);
          }
        } else {
          this.controlCashRegister.setValue(this.cashRegister?.id);
        }
      });
  }

  private setLocations(id: number) {
    this.location = this.locations?.find((o) => o?.id === id);
    this.locationService.setSelectedLocationLocalStorage(this.location?.id);
    this.reloadCashRegisters();
  }

  private setCashRegisters(id: number) {
    this.cashRegister = this.cashRegisters?.find((o) => o?.id === id);
    this.cashRegisterService.setSelectedCashRegisterIdToStorage(this.cashRegister?.id);
    this.accept(this.location, this.cashRegister);
  }

  private accept(location: Location, cashRegister: CashRegister) {
    if (location && location.id > 0 && cashRegister && cashRegister.id > 0) {
      let queryParameters = this.oauthService.customQueryParams as any;

      queryParameters.locationId = location.id;
      queryParameters.cashRegisterId = cashRegister.id;

      if (this.accountService.isLoggedIn) {
        this.authService.refreshToken().then((_) => {
          this.accountService.userChanged.pipe(take(1)).subscribe((u: ProfileModel) => {
            if (u && u.locationId) {
              // this.location=item;
              this.reloadCashRegisters();
            }
          });
        });
      } // wait for login
      else
        setTimeout((_) => {
          this.authService.refreshToken().then((_) => {
            this.accountService.userChanged.pipe(take(1)).subscribe((u: ProfileModel) => {
              if (u && u.locationId) {
                // this.location=item;
                this.reloadCashRegisters();
              }
            });
          });
        }, 200);
    }
  }

  private reloadCashRegisters(): void {
    console.log("reloadCashRegisters");
    this.cashRegistersService.update(this.location.id);
  }

  // getters:
  private getInfoLocation(loc: Location) {
    return `${loc.companyName}, ${loc.address}, ${loc.city}, ${loc.state}, ${loc.zip}`;
  }

  private getInfoCashRegister(cashRegister: CashRegister) {
    let result = cashRegister.name;

    if (cashRegister.cfsCode && cashRegister.cfsCode !== "") {
      const translatedText = this.translateService.translateObject("cfs.cashregisters_dropdown.cfs_code", { code: cashRegister.cfsCode });
      result = `${result}; ${translatedText}`;
    }

    if (cashRegister.terminalId && cashRegister.terminalId !== "") {
      const translatedText = this.translateService.translateObject("cfs.cashregisters_dropdown.terminal_id", {
        terminalId: cashRegister.terminalId
      });
      result = `${result}; ${translatedText}`;
    }

    return result;
  }

  // destroy:
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
