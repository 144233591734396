<div class="dropdown d-inline-block btn-location" ngbDropdown *ngIf="location && location.id">
  <button ngbDropdownToggle id="dropdown-locations" type="button" class="btn header-item waves-effect">
    <div class="d-xl-inline-block font-bold height-xs pt-md-2">
      <span class="noti-icon">
        <i class="bx bx-map icon-map"></i>
      </span>
      <span>{{ location.companyName }}</span>
      <i class="mdi mdi-chevron-down"></i>
    </div>
  </button>
  <div class="dropdown-menu p-0" aria-labelledby="dropdown-locations" ngbDropdownMenu>
    <!-- item-->

    <!-- <a  href="javascript:void(0);"
      class="dropdown-item"
      *ngFor="let item of locations"
      (click)="setLocations(item)"
      [ngClass]="{ active: location.id === item.id }"
    >
      <span class="dropdown-item-text">
        {{ getInfoLocation(item) }}
      </span>
    </a>
  -->
    <lib-filter-select *ngIf="locationsList?.length" [formControl]="controlLocation" [items]="locationsList"> </lib-filter-select>
  </div>
</div>

<div class="dropdown d-inline-block btn-cash-register" ngbDropdown *ngIf="cashRegister && cashRegister.id">
  <button ngbDropdownToggle id="dropdown-cash-registers" type="button" class="btn header-item waves-effect">
    <div class="d-xl-inline-block font-bold height-xs pt-md-2">
      <span class="noti-icon">
        <i class="bx bx-desktop icon-desktop"></i>
      </span>
      <span>{{ cashRegister.name }} </span>
      <i class="mdi mdi-chevron-down"></i>
    </div>
  </button>
  <div class="dropdown-menu p-0" aria-labelledby="dropdown-cash-register" ngbDropdownMenu>
    <!-- item-->
    <!-- <a
      href="javascript:void(0);"
      class="dropdown-item"
      *ngFor="let item of cashRegisters"
      (click)="setCashRegisters(item)"
      [ngClass]="{ active: cashRegister.id === item.id }"
    >
      <span class="dropdown-item-text">
        {{ getInfoCashRegister(item) }}
      </span>
    </a>
-->

    <lib-filter-select [formControl]="controlCashRegister" [items]="cashRegistersList"> </lib-filter-select>

    <!-- item-->
  </div>
</div>
