import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";
import { FeatureModel, FeatureTypes, WidgetModel } from "@app-cmc/models";
import { DataService } from "@app-cmc/core/services/data.service";
import { AccountService } from "@app-cmc/core/services/account.service";

@Injectable({ providedIn: "root" })
export class FeaturesService {
  private features = new BehaviorSubject<FeatureModel[]>([]);
  private widgets: WidgetModel[] = [];
  private serviceUrl = "widgetManagement";
  private faqFeature: FeatureModel;

  constructor(private http: DataService, private translocoService: TranslocoService, private accSvc: AccountService) {
    this.setFeatures();
  }

  getUserWidgets(locationId: number): Observable<WidgetModel[]> {
    return this.http.get(`${this.serviceUrl}/userWidgets/${locationId}`);
  }

  getAvailableFeatures() {
    const locationId = this.accSvc.user.locationId;

    return this.getUserWidgets(locationId).pipe(
      mergeMap((wgs) => {
        this.widgets = wgs;

        return this.features.pipe(
          map((fs) => {
            fs = fs.filter((f) => {
              const wg = wgs.find((w) => w.id === f.id);
              if (!wg) return false;
              f.deliveryMethod = wg.deliveryMethod;
              f.disabled = !wg.configured;
              return !wg.disabled;
            });

            return fs;
          })
        );
      })
    );
  }

  getById(id: FeatureTypes) {
    return this.features.pipe(map((features) => features.find((f) => f.id === id)));
  }

  getWidgetByFeatureId(featureId: FeatureTypes): WidgetModel {
    return this.widgets.find((w) => w.id === featureId);
  }

  add(feature: FeatureModel) {
    this.features.next([...this.features.value, feature]);
  }

  private setFeatures(): void {
    this.translocoService.selectTranslateObject("features").subscribe((t) => {
      this.features.next([
        new FeatureModel(FeatureTypes.SmartPage, t.smartPage.title, "ri-settings-line"),
        new FeatureModel(FeatureTypes.IssueCoupon, t.issueCoupon.title, "ri-coupon-line"),
        new FeatureModel(FeatureTypes.ValidateCoupon, t.validateCoupon.title, "ri-qr-scan-line"),
        new FeatureModel(FeatureTypes.EnrollLoyalty, t.enrollLoyalty.title, "ri-service-line"),
        new FeatureModel(FeatureTypes.IssuePoints, t.issuePoints.title, "ri-hand-coin-line"),
        new FeatureModel(FeatureTypes.RequestReview, t.requestReview.title, "ri-chat-forward-line"),
        new FeatureModel(FeatureTypes.SetReminder, t.setReminder.title, "ri-alarm-line"),
        new FeatureModel(FeatureTypes.AddPromo, t.addPromo.title, "ri-file-list-3-line"),
        new FeatureModel(FeatureTypes.DownloadMobileApp, t.downloadMobileApp.title, "ri-smartphone-line"),
        new FeatureModel(FeatureTypes.ConnectSocialMedia, t.connectSocialMedia.title, "ri-link"),
        new FeatureModel(FeatureTypes.SendPaymentLink, t.sendPaymentLink.title, "ri-refund-2-line"),
        new FeatureModel(FeatureTypes.ScheduleAppointment, t.scheduleAppointment.title, "ri-calendar-todo-line"),
        new FeatureModel(FeatureTypes.Waitlist, t.waitlist.title, "ri-user-add-line"),
        new FeatureModel(FeatureTypes.SendSurvey, t.sendSurvey.title, "ri-question-answer-line"),
        new FeatureModel(FeatureTypes.SendGiftCard, t.sendGiftCard.title, "ri-gift-2-line"),
        new FeatureModel(FeatureTypes.AnniversaryClub, t.anniversaryClub.title, "ri-cake-2-line"),
        new FeatureModel(FeatureTypes.ReferralProgram, t.referralProgram.title, "ri-medal-line"),
        new FeatureModel(FeatureTypes.SeeMenu, t.seeMenu.title, "ri-list-check-3")
      ]);
    });
  }
}
