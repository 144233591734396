import { Injectable } from "@angular/core";
import { ProductItem } from "@app-cmc/models";
import { DataService } from "@app-cmc/core/services/data.service";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class ProductItemService {
  serviceUrl = "productitems";

  constructor(private http: DataService) {}

  getAll(): Observable<ProductItem[]> {
    return this.http.get(`${this.serviceUrl}`);
  }

  getProductsByLocationId(locationId: number): Observable<ProductItem[]> {
    return this.http.get(`${this.serviceUrl}/bylocation/${locationId}`);
  }
}
