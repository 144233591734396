<div *transloco="let t; read: 'features.validateCoupon'" class="table-custom-wrapper">
  <h4 class="coupon-detail-title">{{ t("additionalDetails") }}</h4>

  <table class="table-custom table-nowrap mb-0">
    <tbody>
      <tr class="table-custom__row">
        <th class="table-custom__title" scope="row">{{ t("user.firstName") }}</th>
        <td class="table-custom__value text-uppercase">
          <span class="table-custom__value-text">
            {{ user.firstName }}
          </span>
        </td>
      </tr>
      <tr class="table-custom__row">
        <th class="table-custom__title" scope="row">{{ t("user.lastName") }}</th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ user.lastName }}
          </span>
        </td>
      </tr>
      <tr class="table-custom__row">
        <th class="table-custom__title" scope="row">{{ t("user.phone") }}</th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ user.phone }}
          </span>
        </td>
      </tr>
      <tr class="table-custom__row">
        <th class="table-custom__title" scope="row">{{ t("user.email") }}</th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ user.email }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
