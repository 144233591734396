import { Component, HostListener, OnInit } from "@angular/core";

import { AccountService, AppConfigService, ProfileModel } from "@app-cmc/core";
import { Router } from "@angular/router";
import * as waves from "node-waves";
import { authConfig } from "./auth.config";
import { OAuthService } from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import { CFSConnectionService } from "@app-cmc/services";

@Component({ selector: "app-clearline-marketing", templateUrl: "app.component.html", styleUrls: ["app.component.scss"] })
export class AppComponent implements OnInit {
  user: ProfileModel;

  get year() {
    return new Date().getFullYear();
  }

  @HostListener("window:beforeunload") beforeunload() {
    this.cfsConnectionService.handleOnBeforeLoad();
  }

  constructor(
    private router: Router,
    private oauthService: OAuthService,
    private accountService: AccountService,
    private configSvc: AppConfigService,
    private cfsConnectionService: CFSConnectionService
  ) {}

  ngOnInit() {
    this.configureOidc();
    //  this.authService.refreshToken();
    waves.init();
  }

  private configureOidc() {
    const authConf: IAuthConfig = this.configSvc.appData.authConfig;
    this.oauthService.configure(authConfig(authConf));
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    const culture = "en-US";
    this.oauthService.customQueryParams = {
      ui_locales: culture,
      location: ""
    };

    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(
      () => {
        this.accountService.refreshUser();
        const redirectPath = localStorage.getItem("redirectPath");
        if (redirectPath && this.accountService.isLoggedIn) {
          this.router.navigateByUrl(redirectPath);
          localStorage.removeItem("redirectPath");
        }
      },
      () => null
    );
  }
}
