import { AllowedDeliveryChannel } from "../widget";

export class Campaign {
  public id;
  public name: string;
  public description: string;
  public isWidget: boolean;
  public isCampaign: boolean;
  public deliveryMethod: AllowedDeliveryChannel;
  public isEditing: boolean;
}
