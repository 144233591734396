export enum CourierEvents {
  AddToWaitlist = 1,
  IssueCoupon = 2,
  DownloadMobileApp = 3,
  EnrollToLoyalty = 4,
  OneTimePayment = 5,
  RequestReview = 6,
  AddPromo = 7,
  AnniversaryClub = 8,
  ConnectSocialNetwork = 9,
  ScheduleAppointment = 12,
  SendSurvey = 13,
  SendGiftCard = 14,
  SetReminder = 15,
  AddToReferralProgram = 16,
  ConfirmEmail = 17
}
