import { Component, OnInit } from "@angular/core";
import { AuthService } from "@app-cmc/core/services/auth.service";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"]
})
export class LandingComponent implements OnInit {
  loading = false;
  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  login() {
    this.loading = true;
    this.authService.login();
  }
}
