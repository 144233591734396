import { Injectable } from "@angular/core";
import { ShortLink } from "@app-cmc/models";
import { Observable } from "rxjs";
import { DataService } from "../core/services/data.service";

@Injectable({
  providedIn: "root"
})
export class ShortLinkService {
  private serviceUrl = "ShortLink";

  constructor(private http: DataService) {}

  getShorlLink(shortLink: ShortLink): Observable<string> {
    return this.http.post(`${this.serviceUrl}/generate`, shortLink);
  }
}
