import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { ClickOutsideModule } from "ng-click-outside";

import { PagetitleComponent } from "./pagetitle/pagetitle.component";
import { LoaderComponent } from "./loader/loader.component";

@NgModule({
  declarations: [PagetitleComponent, LoaderComponent],
  imports: [CommonModule, FormsModule, ClickOutsideModule, NgbCollapseModule, NgbDatepickerModule, NgbTimepickerModule, NgbDropdownModule],
  exports: [PagetitleComponent, LoaderComponent]
})
export class UIModule {}
