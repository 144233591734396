export enum DefaultTemplateType {
  None = 0,
  Cfs = 1,
  Print = 2,
  CfsAndPrint = 3
}

export enum TemplateInfoEnum {
  imageForCFSId = 17,
  minUrlLength = 10,
  maxUrlLength = 100
}

export class Template {
  id?: number;
  name: string;
  templateName?: string;
  widgetId?: number;
  defaultTemplateId?: number;
  templateType?: DefaultTemplateType;
  isDefault?: boolean;
  canEnable?: boolean;
  enabled: boolean;
  templateProperties?: any;
  generatedBody?: string;
}
