export class ExternalLocation {
  id: number;
  name: string;
  city: string;
  state: string;
  address: string;
  website: string;
  phone: string;
  street: string;
  email: string;
  longitude: number;
  latitude: number;
  zip: number;
  password: string;
}
