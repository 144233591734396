export function getFullChannelName(channel: string, code: string): string {
  return `${channel}-${code}`;
}

export function extractCodeFromChannel(channel: string): string {
  const splitParts: string[] = channel?.split("-");

  return splitParts?.length ? splitParts[splitParts.length - 1] : "";
}

export function distinctUntilChangedCallBack(a: Object, b: Object): boolean {
  return JSON.stringify(a).split("").sort().join("") === JSON.stringify(b).split("").sort().join("");
}
