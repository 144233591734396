import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"]
})
export class ConfirmModalComponent implements OnInit {
  @Input()
  public title = "";
  @Input()
  public body = "";
  @Input()
  public confirmBtnText = "";
  @Input()
  public cancelBtnText = "";
  @Input()
  public isDanger = false;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (!this.confirmBtnText) {
      this.confirmBtnText = "Confirm";
    }

    if (!this.cancelBtnText) {
      this.cancelBtnText = "Cancel";
    }
  }

  dismiss() {
    this.activeModal.dismiss("cancel");
  }

  confirm() {
    this.activeModal.close("confirm");
  }
}
