import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "@app-cmc/core";
import { Cfs } from "@app-cmc/models";
import { CashRegister } from "cfs-communication-pack";

@Injectable({
  providedIn: "root"
})
export class CfsService {
  private serviceUrl = "cashregister";

  constructor(private http: DataService) {}

  addCfs(id: number, cfs: Cfs): Observable<Cfs> {
    return this.http.put(`${this.serviceUrl}/cfs/${id}`, cfs);
  }

  getCurrent(): Observable<CashRegister> {
    return this.http.get(`${this.serviceUrl}/current`);
  }
}
