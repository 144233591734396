import { Component } from "@angular/core";

@Component({
  selector: "app-terminal-pairing-steps",
  templateUrl: "./terminal-pairing-steps.component.html",
  styleUrls: ["./terminal-pairing-steps.component.scss"]
})
export class TerminalPairingStepsComponent {
  readonly steps = [1, 2, 3];
}
