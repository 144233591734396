import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "@app-cmc/core";
import { Brand } from "@app-cmc/models";
import { MarketService } from "@app-cmc/services";
import { interval } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-pending-configuration",
  templateUrl: "./pending-configuration.component.html",
  styleUrls: ["./pending-configuration.component.scss"]
})
export class PendingConfigurationComponent implements OnInit {
  constructor(private markerService: MarketService, private accountService: AccountService, private router: Router) {}

  brand: Brand;

  ngOnInit(): void {
    if (this.accountService.user.configured) this.router.navigate(["/"]);
    this.markerService.getBrandsByLocation(+this.accountService.user.locationId).subscribe((res: Brand[]) => {
      this.brand = res[0];
    });
  }

  getSeconds(t) {
    return Math.floor((t / 1000) % 60);
  }
}
