<div *ngIf="qrCodeSettings$ | async as qrCodeSettings;" [class.hide-opacity]="!(contentReadyToDisplay$ | async)">
  <div *transloco="let t; read: 'features.defaultTemplateFeature'">
    <div
      class="default-widget-modal spinner-border-wrapper"
      [class.loading]="loading">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ title }}
        </h5>

        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          [title]="'common.controls.close' | transloco"
          (click)="dismiss()"
        ></button>
      </div>

      <div class="modal-body card-body pb-0">
        <div class="col-md-12 feature-content d-flex content-space-between">
          <div class="feature-content-column">
            <ng-container *ngFor="let recipient of recipients; index as i">
              <app-recipients
                [deliveryMethod]="deliveryMethod"
                [recipient]="recipient"
                (changed)="updateItem(i, $event)"
                (deleted)="deleteItem(i)"
                [contentReadyToLoad]="true"
              >
              </app-recipients>
            </ng-container>
          </div>

          <div class="feature-content-column spinner-border-wrapper">
            <app-qr-code-preview
              class="w-100"
              [withCompactPreview]="true"
              [settings]="qrCodeSettings"
              [disabled]="loading"
              (openModal)="toCloseModal()"
              (clickButton)="openQrCode()">
            </app-qr-code-preview>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="card-body row px-2 g-3">
          <div class="col-md-6 col-6">
            <button type="button" class="btn btn-outline w-100" (click)="dismiss()">
              {{ (isSent ? "common.controls.close" : "common.controls.cancel") | transloco }}
            </button>
          </div>

          <div class="col-md-6 col-6" *ngIf="!isOnlyQr">
            <button
              *ngIf="!isSent"
              type="button"
              class="btn btn-primary w-100"
              [class.disabled]="!isValid || loading"
              (click)="confirmSend()"
            >
              {{ "common.controls.send" | transloco }}
            </button>
          </div>
        </div>
      </div>

      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    </div>
  </div>
</div>
