import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CouponInfo } from "@app-cmc/models";

@Component({
  selector: "app-coupon-carousel",
  templateUrl: "./coupon-carousel.component.html",
  styleUrls: ["./coupon-carousel.component.scss"]
})
export class CouponCarouselComponent {
  @Input() couponsLoading = true;
  @Input() selectedCoupon: CouponInfo;
  @Input() coupons: Array<CouponInfo> = [];

  @Output() setLoading = new EventEmitter<boolean>();
  @Output() selectCoupon = new EventEmitter<CouponInfo>();
  @Output() closeRestModals = new EventEmitter<null>();

  scrollbarOptions: any = {
    forceVisible: false,
    autoHide: false
  };

  clickSelectCoupon(coupon: CouponInfo): void {
    this.selectCoupon.emit(coupon);
  }

  onCloseRestModals(): void {
    this.closeRestModals.emit(null);
  }
}
