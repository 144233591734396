import { Injectable } from "@angular/core";
import { Subscription } from "@app-cmc/models";
import { BehaviorSubject, Observable } from "rxjs";
import { DataService } from "../core/services/data.service";
import { NotificationModel } from "../models/notification";

@Injectable({
  providedIn: "root"
})
export class CompanyDataService {
  private serviceUrl = "company";

  public notifications: Array<NotificationModel> = [];
  public subscription: Subscription = new Subscription();

  $notificationsSbj: BehaviorSubject<Array<NotificationModel>> = new BehaviorSubject<Array<NotificationModel>>(this.notifications);

  $subscriptionSbj: BehaviorSubject<Subscription> = new BehaviorSubject<Subscription>(this.subscription);

  public notificationsChanged: Observable<Array<NotificationModel>> = this.$notificationsSbj.asObservable();

  public subscriptionChanged: Observable<Subscription> = this.$subscriptionSbj.asObservable();

  constructor(private http: DataService) {}

  private getNotifications(): Observable<Array<NotificationModel>> {
    return this.http.get(`${this.serviceUrl}/notifications`, {});
  }

  private getSubscription(): Observable<Subscription> {
    return this.http.get(`${this.serviceUrl}/subscription`, {});
  }

  retriveData(onlySubscription: boolean = false) {
    if (!onlySubscription) {
      this.getNotifications().subscribe((x) => {
        this.notifications = x;
        this.$notificationsSbj.next(this.notifications);
      });
    }

    this.getSubscription().subscribe((x) => {
      this.subscription = x;
      this.$subscriptionSbj.next(this.subscription);
    });
  }
}
