<div
  class="coupon {{ type }}"
  [class.selected]="item == selectedCard"
  [class.blured]="selectedCard && item !== selectedCard"
  (click)="clickSelect(item)"
>
  <div class="coupon-row coupon-image" [ngStyle]="{ 'background-image': 'url(' + item.frontImage + ')' }"></div>

  <div class="coupon-row coupon-text coupon-title" [innerHTML]="item.name"></div>
</div>
