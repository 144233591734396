import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { take } from "rxjs/operators";
import { QRCodeSettings } from "../../../models";
import { QrCodeFullPreviewModalComponent } from "@app-cmc/features/common/qr-code-preview/qr-code-full-preview-modal";

@Component({
  selector: "app-qr-code-preview",
  templateUrl: "./qr-code-preview.component.html",
  styleUrls: ["./qr-code-preview.component.scss"]
})
export class QrCodePreviewComponent {
  constructor(private modalSvc: NgbModal) {}

  readonly qrCodeSize = 180;
  private isButtonClicked = false;
  private isFrameLoaded = false;
  private featureModal: NgbModalRef;
  qrCodeSettings: QRCodeSettings;

  @Input() withCompactPreview = false;
  @Input() disabled = false;

  @Input() set settings(settings: QRCodeSettings) {
    this.qrCodeSettings = settings;

    if (this.isButtonClicked && !this.featureModal && settings?.safeHtmlUrl) {
      this.showFullQrCode();
    }
  }

  @Output() clickButton = new EventEmitter<ClipboardEvent>();
  @Output() openModal = new EventEmitter();
  @Output() contentLoaded = new EventEmitter<boolean>();

  iframeLoads(): void {
    if (!this.isFrameLoaded) {
      this.isFrameLoaded = true;

      this.contentLoaded.emit(true);
    }
  }

  onClickOpenQrCodeModalBtn(event: ClipboardEvent): void {
    this.isButtonClicked = true;

    this.clickButton.emit(event);
  }

  private showFullQrCode(): void {
    this.openModal.emit(null);

    this.featureModal = this.modalSvc.open(QrCodeFullPreviewModalComponent, {
      backdrop: "static",
      size: "default",
      centered: true,
      windowClass: "custom"
    });
    const comp: QrCodeFullPreviewModalComponent = this.featureModal.componentInstance;
    comp.settings = this.qrCodeSettings;

    this.featureModal.dismissed.pipe(take(1)).subscribe(() => {
      this.featureModal = null;
    });
  }
}
