<div *transloco="let t; read: 'features.issuePoints'">
  <div class="modal-header">
    <h5 class="modal-title">
      {{ t("title") }}
    </h5>

    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <div class="modal-main-content">
      <div *ngIf="cardSelected && !loading; else spinner">
        <app-widget-message *ngIf="newAmount && loyaltyCard" class="success-message" [type]="'success'">
          {{ newAmount + " " }}
          {{ loyaltyCard.type == "stampcard" ? (newAmount == 1 ? "stamp" : "stamps") : newAmount == 1 ? "point" : "points" }}
          successfully issued<ng-container *ngIf="loyaltyCard.type !== 'rewardcard'">.</ng-container>

          <ng-container *ngIf="loyaltyCard.type !== 'rewardcard'"
            >{{ " " + t("amount") + ":" }}
            <h5 class="m-0 badget-td">
              <span class="badge bg-success ms-1 lh-base"
                >{{ loyaltyCard.type == "stampcard" ? loyaltyUser.stamps : loyaltyUser.points }}/{{
                  loyaltyCard.type == "stampcard" ? loyaltyCard.amountOfStamps : loyaltyCard.amountOfPoints
                }}</span
              >
            </h5>
            <span class="inline-text">
              {{ " " + loyaltyCard.type == "stampcard" ? "stamps" : "points" }}
            </span>
          </ng-container>
        </app-widget-message>

        <div class="coupon-container">
          <app-loyalty-card class="coupon" [item]="selectedCard" [type]="'simple'"> </app-loyalty-card>
        </div>

        <form class="modal-form" [formGroup]="form" *transloco="let terror; read: 'features.common'">
          <app-input
            [control]="searchControl"
            [isRequired]="true"
            [placeholder]="t('placeholder.search_user')"
            [icon]="'mdi mdi-magnify cursor-pointer'"
            (iconClick)="search()"
            (keyup.enter)="search()"
          ></app-input>
        </form>
      </div>

      <div *ngIf="!loyaltyUser && !nothingFound && !loading" class="search-block">
        <img alt="" class="search-block__img" src="assets/images/load-img.svg" />

        <div class="search-block__text">
          {{ t("search_text") }}
        </div>
      </div>

      <div *ngIf="nothingFound && !loading" class="search-empty-block">
        <span class="content-text" *transloco="let t; read: 'features.issueCoupon'">
          {{ t("nothing_found") }}
        </span>
      </div>

      <div *ngIf="loyaltyUser && !nothingFound && !loading" class="modal-table-content">
        <app-issue-points-additional-details
          [loyaltyCard]="loyaltyCard"
          [loyaltyUser]="loyaltyUser"
          [points]="points"
          [serverError]="serverError"
        >
        </app-issue-points-additional-details>

        <app-card-rewards-block class="card-rewards-block" [rewards]="rewards"> </app-card-rewards-block>
      </div>
    </div>

    <div class="modal-footer inner two-actions" *transloco="let t; read: 'common.controls'">
      <app-qr-code-reader-button
        class="btn p-0"
        [buttonText]="'Scan'"
        (scanModalOpened)="onScanModalOpened()"
        (scanResult)="scan($event)"
      ></app-qr-code-reader-button>

      <button *ngIf="!isUsed" (click)="submit()" type="button" class="btn btn-main" [class.disabled]="!points.valid || loading">
        {{ t("submit") }}
      </button>

      <button *ngIf="isUsed" (click)="dismiss()" type="button" class="btn btn-main">
        {{ t("done") }}
      </button>
    </div>

    <ng-template #spinner>
      <div class="spinner-wrapper">
        <span class="spinner-border spinner-border-sm mr-1"> </span>
      </div>
    </ng-template>
  </div>
</div>
