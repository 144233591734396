import { AllowedDeliveryChannel } from "../widget";

export class ShortLink {
  public targetUrl: string;
  public code: string;
  public brandId: number;
  public locationId: number;
  public deliveryMethod: AllowedDeliveryChannel;

  public validTo: Date;
  public usageLimit: number;
}
