import { Injectable } from "@angular/core";
import { DataService } from "@app-cmc/core/services/data.service";
import { Brand } from "@app-cmc/models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BrandService {
  private serviceUrl = "brand";

  constructor(private http: DataService) {}

  getBrandById(id: number): Observable<Brand> {
    return this.http.get(`${this.serviceUrl}/` + id);
  }
}
