export enum PromoPreferences {
  Undefined = 0,
  Sms = 1,
  Email = 2 | Sms
}

export enum PromoMethods {
  Undefined = 0,
  Inbound = 1,
  Outbound = 2 | Inbound
}

export class AddPromoWidgetSettings {
  public promoListUrl: string;
  public promoPreferences: PromoPreferences;
  public promoMethods: PromoMethods;
}
