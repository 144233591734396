import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { RecipientType, RecipientAllowedType } from "./recipient-item";

/** @deprecated Use import from `ui-kit`*/
export function isValidPhone(value: string) {
  const phoneRegex: RegExp = /^\+?[0-9]{10,11}$/; // todo check phone format
  const isValid = phoneRegex.test(value);
  return isValid;
}

/** @deprecated Use import from `ui-kit`*/
export function isValidEmail(value: string) {
  const emailRegex: RegExp = /^([_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z0-9-]{2,5}))$/;
  const isValid = emailRegex.test(value);
  return isValid;
}

/** @deprecated Use import from `ui-kit`*/
export function getRecepientValidator(type: RecipientAllowedType, typeSubject: BehaviorSubject<RecipientType>): ValidatorFn {
  switch (type) {
    case "phoneOrEmail":
      return emailOrPhoneValidator(typeSubject);
    case "phone":
      typeSubject.next("phone");
      return phoneValidator();
    case "email":
      typeSubject.next("email");
      return emailValidator();
  }
}

/** @deprecated Use import from `ui-kit`*/
export function emailOrPhoneValidator(typeSubject: BehaviorSubject<RecipientType>): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value.toString() as string;

    if (value === "") return null;
    let isValid = false;
    let type: RecipientType = "";
    if (value.includes("@")) {
      isValid = isValidEmail(value);
      type = "email";
    } else if (Number.isInteger(Number(value))) {
      isValid = isValidPhone(value);
      type = "phone";
    }

    typeSubject.next(type);

    return isValid ? null : { type };
  };
}

/** @deprecated Use import from `ui-kit`*/
export function phoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.toString() as string;

    const isValid = isValidPhone(value);

    return isValid ? null : { isValid: { value: control.value }, type: "phone" };
  };
}

/** @deprecated Use import from `ui-kit`*/
export function emailValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.toString() as string;

    const isValid = isValidEmail(value);
    return isValid ? null : { isValid: { value: control.value }, type: "email" };
  };
}

/** @deprecated Use import from `ui-kit`*/
export class Patterns {
  // tslint:disable-next-line:max-line-length
  public static phone = RegExp(/^\+?[0-9]{10,11}$/);

  public static email = RegExp(/^([_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z0-9-]{2,5}))$/);
}

/** @deprecated Use import from `ui-kit`*/
export function emailUniqueValidator(values: string[]) {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.toString() as string;

    return values && values.indexOf(value) === -1 ? null : { unique: { value: control.value }, type: "email" };
  };
}
