<div>
  <h2 class="flex-wrap d-flex justify-content-center">
    <span
      [class.bg-secondary]="!item.isSelected"
      (click)="select(item)"
      *ngFor="let item of badgets; index as i"
      class="cursor-p m-lg-1 badge bg-primary"
      >{{ item.name }}</span
    >
  </h2>
</div>
