export class ConnectSocialNetworkWidgetSettings {
  public facebook: string;
  public instagram: string;
  public linkedin: string;
  public twitter: string;
  public google: string;
  public snapchat: string;
  public youtube: string;
  public tiktok: string;

  public isFromBrand: boolean;
}
