import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AllowedDeliveryChannel, ProductItem } from "@app-cmc/models";
import { LocationService, ProductItemService } from "@app-cmc/services";
import { Subject } from "rxjs";
import { debounceTime, take, takeUntil, tap } from "rxjs/operators";
import { atLeastOneRequiredValidator, emailValidator, notOnlyNumbersValidator, phoneMask, phoneValidator } from "ui-kit";
import { Recipient } from "..";

@Component({
  selector: "app-recipients",
  templateUrl: "./recipients.component.html",
  styleUrls: ["./recipients.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecipientsComponent implements OnInit, OnDestroy {
  AllowedDeliveryChannel = AllowedDeliveryChannel;
  defaultAllProducts: ProductItem[];
  defaultAllCategories: ProductItem[];
  allProducts: ProductItem[];
  categories: ProductItem[];
  products: ProductItem[];

  isEditing = false;
  loading = false;
  showPersonalization = false;
  formIsEmpty = true;
  isProducts = false;
  previousValidValue: Recipient | undefined;
  isOnlyQr = false;
  isContentLoaded = false;

  form = new FormGroup({
    recipient: new FormGroup(
      {
        email: new FormControl("", [emailValidator()]),
        phone: new FormControl("", [phoneValidator(true)])
      },
      [atLeastOneRequiredValidator()]
    ),
    firstName: new FormControl("", [notOnlyNumbersValidator()]),
    lastName: new FormControl("", [notOnlyNumbersValidator()]),
    product: new FormControl(),
    productId: new FormControl(),
    productCategory: new FormControl()
  });

  @Input() withMessage = true;
  @Input() deliveryMethod: AllowedDeliveryChannel;
  @Input() set contentReadyToLoad(ready: boolean) {
    if (ready && !this.isContentLoaded) {
      this.isContentLoaded = true;

      this.productSvc
        .getProductsByLocationId(this.locationService.getSelectedLocationLocalStorage())
        .pipe(take(1))
        .subscribe((data: ProductItem[]) => {
          this.setProducts(data);
          this.handleFormValueChanges();
        });
    }
  }

  @Input() private set recipient(value: Recipient) {
    if (value && (value.email || value.phone)) {
      const { email, phone, firstName, lastName, purchaseName, purchaseId, purchaseCategory } = value;

      this.form.patchValue(
        {
          recipient: { email, phone },
          firstName,
          lastName,
          product: purchaseName,
          productId: purchaseId,
          productCategory: purchaseCategory
        },
        { emitEvent: false }
      );
      this.previousValidValue = JSON.parse(JSON.stringify(value));
      this.showPersonalization = !!(value.firstName || value.lastName || value.purchaseName);

      if (!this.isEditing) {
        this.isEditing = true;
        this.edit();
      }
    }
  }

  @Output() changed = new EventEmitter<Recipient>();
  @Output() deleted = new EventEmitter();

  readonly phoneMask = phoneMask;

  private selectedProduct: ProductItem;
  private unsubscribe$ = new Subject();

  private get recipient() {
    const { recipient, firstName, lastName, product, productCategory, productId } = this.form.getRawValue();
    const { email, phone } = recipient;

    return {
      email,
      phone,
      firstName,
      lastName,
      purchaseName: product,
      purchaseId: productId,
      purchaseCategory: productCategory,
      purchasePrice: this.selectedProduct?.price
    } as Recipient;
  }

  deliveryMessage = "";

  constructor(private locationService: LocationService, private productSvc: ProductItemService) {}

  ngOnInit(): void {
    this.isOnlyQr = this.deliveryMethod === AllowedDeliveryChannel.qr;
    this.deliveryMessage =
      this.deliveryMethod === AllowedDeliveryChannel.sms
        ? "features.common.enterPhonePlaceholder"
        : this.deliveryMethod === AllowedDeliveryChannel.email
        ? "features.common.enterEmailPlaceholder"
        : this.deliveryMethod === AllowedDeliveryChannel.emailAndSms
        ? "features.common.enterPhoneOrEmailPlaceholder"
        : "";
  }

  getRecipientControl(controlName: string): FormControl {
    return this.form.get(`recipient.${controlName}`) as FormControl;
  }

  productSelected(product: ProductItem) {
    if (product) {
      this.categories = this.categories.filter((o) => o.productCategory === product.productCategory);
      this.products = this.allProducts.filter((o) => o.productCategory === product.productCategory);
      this.selectedProduct = product;
      this.form.controls.productCategory.setValue(product.productCategory);
      this.form.controls.productId.setValue(product.id);
    }

    this.resetSelect();
  }

  changeCategories(product: ProductItem) {
    if (product) {
      this.products = [];
      this.products = this.allProducts.filter((o) => o.productCategory === product.productCategory);
      this.form.controls.product.setValue(null);
      this.form.controls.productId.setValue(null);
      this.selectedProduct = null;
    }

    if (!this.form.controls.productCategory.value) {
      this.categories = this.defaultAllCategories;
      this.products = this.defaultAllProducts;
    }

    this.resetSelect();
  }

  private resetSelect() {
    if (!this.form.controls.product.value && !this.form.controls.productCategory.value) {
      this.categories = this.defaultAllCategories;
      this.products = this.defaultAllProducts;
    }
  }

  private update() {
    let phone: string = this.recipient.phone;
    if (phone?.length === 10) phone = "1" + phone;

    this.changed.emit({
      value: "",
      valid: this.form.valid && !this.isEditing,
      email: this.recipient.email,
      phone: phone,
      firstName: this.recipient.firstName,
      lastName: this.recipient.lastName,
      purchaseName: this.recipient.purchaseName,
      purchaseId: this.recipient.purchaseId,
      purchaseCategory: this.recipient.purchaseCategory,
      purchasePrice: this.selectedProduct?.price
    } as Recipient);
  }

  private edit() {
    if (this.loading) return;
    this.update();
  }

  private setProducts(data: ProductItem[]): void {
    if (data && data.length > 0) {
      this.isProducts = true;
      this.defaultAllCategories = [...new Map(data.map((item) => [item["productCategory"], item])).values()];
      this.defaultAllProducts = [...new Map(data.map((item) => [item["productName"], item])).values()];

      this.allProducts = data;
      this.categories = this.defaultAllCategories;
      const productId = this.form.controls.productId.value;

      if (productId) {
        this.selectedProduct = data.find((p) => p.id === productId);
        this.products = this.allProducts.filter((o) => o.productCategory === this.selectedProduct.productCategory);
      } else {
        const purchaseCategory = this.form.controls.productCategory.value;

        if (purchaseCategory) {
          this.products = this.allProducts.filter((o) => o.productCategory === purchaseCategory);
        } else {
          this.products = this.defaultAllProducts;
        }
      }
    }
  }

  private handleFormValueChanges(): void {
    const delay = 300;

    this.form.valueChanges
      .pipe(
        debounceTime(delay),
        tap(() => {
          this.formIsEmpty = false;

          this.update();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
