<div class="row" *ngIf="isLoadPage">
  <div class="col-12">
    <div class="card card-content">
      <div class="card-body">
        <div *transloco="let t; read: 'dashboard'">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box lowercase d-flex align-items-center justify-content-between">
                <h4 class="font-bold mb-0">{{ t("title") }}</h4>
                <div class="page-title-right">
                  <span class="icons-menu">
                    <button
                      class="btn btn-outline-light brl-0"
                      (click)="clickMenuView(1)"
                      ngbTooltip="Icon View"
                      [ngClass]="menuIconActive ? 'active' : ''"
                    >
                      <i class="dripicons-view-thumb icon-menu"></i>
                    </button>
                    <button
                      class="btn btn-outline-light brr-0"
                      (click)="clickMenuView(0)"
                      ngbTooltip="List View"
                      [ngClass]="!menuIconActive ? 'active' : ''"
                    >
                      <i class="dripicons-view-list icon-menu"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <notification-message
              *ngFor="let notification of notifications"
              [messageText]="notification.messageText"
              [actionText]="t('updateSubscription')"
              [actionLink]="getActionLink(notification.action)"
              [severity]="notification.severity"
            ></notification-message>
          </div>
          <div class="row" *ngIf="notUserLocation">
            <notification-message
              [messageText]="t('notificationNotUserLocation', { user: userName })"
              [severity]="2"
            ></notification-message>
          </div>
          <div class="row mb-3 justify-content-center" hidden>
            <div class="col-md-3 col-sm-4">
              <div class="">
                <ng-select [placeholder]="t('selectWidget')" (change)="runFeature($event)" type="text">
                  <ng-option *ngFor="let f of enabledFeatures" [value]="f"> {{ f.name }}</ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div *ngIf="!notUserLocation">
            <div class="row row-eq-height icons-view" *ngIf="menuIconActive">
              <app-dashboard-icon-view-widget
                *ngFor="let feature of features"
                [text]="feature?.name"
                (clicked)="runFeature(feature)"
                [icon]="feature?.icon"
                [disabled]="feature?.disabled"
                [isManager]="isManagerType"
                class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 item-icon-view"
              ></app-dashboard-icon-view-widget>
            </div>
            <div class="row" *ngIf="!menuIconActive">
              <div class="col-md-12 list-view" *ngIf="features && features.length > 0">
                <div class="header-list-view p-3">Name</div>
                <div class="row header-list-body">
                  <app-dashboard-list-view-widget
                    *ngFor="let feature of features"
                    [text]="feature?.name"
                    (clicked)="runFeature(feature)"
                    [icon]="feature?.icon"
                    [disabled]="feature?.disabled"
                    [isManager]="isManagerType"
                    class="col-xl-6 col-lg-6 col-md-6 col-12 brb-1 p-2"
                  ></app-dashboard-list-view-widget>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
