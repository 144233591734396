export enum RoleTypes {
  Sales = "sales",
  Manager = "manager",
  Admin = "admin",
  LocationManager = "location-manager",
  PartnerAdmin = "partner-admin",
}
export class Role {
  id: number;
  name: string;
}
