import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { QRCodeSettings } from "@app-cmc/models";
import { CFSConnectionService } from "@app-cmc/services";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-qr-code-full-preview-modal",
  templateUrl: "./qr-code-full-preview-modal.component.html",
  styleUrls: ["./qr-code-full-preview-modal.component.scss"]
})
export class QrCodeFullPreviewModalComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public activeModal: NgbActiveModal,
    private cfsConnectionService: CFSConnectionService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  private unsubscribe$ = new Subject<void>();

  @Input() settings: QRCodeSettings;

  ngOnInit(): void {
    this.cfsConnectionService.showQrCodeModal(this.settings.sessionId);

    this.cfsConnectionService
      .hideQrCodeOnCmc()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.activeModal.dismiss();
      });
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  dismiss() {
    this.activeModal.dismiss();
    this.cfsConnectionService.publishHideQrCodeMessage();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
