import { Component, Input } from "@angular/core";
import { LoyaltyCard, LoyaltyCardUser } from "@app-cmc/models";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-issue-points-additional-details",
  templateUrl: "./issue-points-additional-details.component.html",
  styleUrls: ["./issue-points-additional-details.component.scss"]
})
export class IssuePointsAdditionalDetailsComponent {
  constructor() {}

  @Input() loyaltyCard: LoyaltyCard;
  @Input() loyaltyUser: LoyaltyCardUser;
  @Input() points: UntypedFormControl;
  @Input() serverError: string;
}
