import { LoyaltyCardReward } from ".";
import { LoyaltyCard } from "./loyalty-card";
import { LoyaltyCardUser } from "./loyalty-card-user";

export class LoyaltyCardUpdateResult {
  public loyaltycard: LoyaltyCard;
  public user: LoyaltyCardUser;
  public action: string;
  public oldAmount: number;
  public addedAmount?: number;
  public newAmount?: number;
  public removedAmount?: number;
  public rewardsAdded: Array<LoyaltyCardReward>;
}
