<div class="row mb-2" *transloco="let t; read: 'features.common'">
  <ng-container *ngIf="isEditing; else elseBlock">
    <form class="form-inline editableform row" [class.flex]="hideButtons">
      <div class="d-flex" [class.col-9]="!hideButtons" [class.col]="hideButtons">
        <div [class.col-lg-12]="hideButtons" class="editable-input">
          <input
            type="text"
            class="form-control"
            [formControl]="emailOrPhone"
            [class.is-invalid]="emailOrPhone.touched && emailOrPhone.errors"
            [placeholder]="placeHolder"
          />
          <ng-container *ngIf="emailOrPhone.touched && emailOrPhone.errors">
            <ng-container *ngIf="emailOrPhone.errors.required; else second">
              <div class="invalid-feedback">
                {{ t("requiredField") }}
              </div>
            </ng-container>
            <ng-template #second>
              <div class="invalid-feedback" *ngIf="emailOrPhone.errors.type == 'email'">
                {{ t("invalidEmail") }}
              </div>
              <div class="invalid-feedback" *ngIf="emailOrPhone.errors.type == 'phone'">
                {{ t("invalidPhone") }}
              </div>
            </ng-template>
          </ng-container>
        </div>
        <div class="m-2">
          <i
            *ngIf="fieldType.value == 'email'"
            class="mdi mdi-email fa-lg"
            [class.text-danger]="emailOrPhone.touched && emailOrPhone.errors"
          ></i>
          <i
            *ngIf="fieldType.value == 'phone'"
            class="bx bx-mobile fa-lg"
            [class.text-danger]="emailOrPhone.touched && emailOrPhone.errors"
          ></i>
        </div>
      </div>
      <div [class.col]="!hideButtons" [class.col-lg-4]="hideButtons" *transloco="let t; read: 'common.controls'">
        <button type="submit" [title]="t('save')" [class.disabled]="emailOrPhone.errors" (click)="save()" class="btn btn-success">
          <i class="mdi mdi-check"></i>
        </button>
        <button type="button" [title]="t('cancel')" (click)="cancel()" class="btn btn-danger ms-2">
          <i class="mdi mdi-close"></i>
        </button>
      </div>
    </form>
  </ng-container>

  <ng-template #elseBlock>
    <ng-container *transloco="let t; read: 'common.controls'">
      <div [class.col-9]="!hideButtons" [class.col-lg-8]="hideButtons">
        <div class="editable-link">
          <a
            href="#"
            id="inline-username"
            [title]="t('edit')"
            class="editable editable-click editable-open"
            [hidden]="isEditing"
            (click)="edit()"
            >{{ emailOrPhone.value }}</a
          >
          <i *ngIf="fieldType.value == 'email'" class="mdi ms-2 mdi-email icon-align"></i>
          <i *ngIf="fieldType.value == 'phone'" class="bx bx-mobile icon-align"></i>
          <span *ngIf="loading" class="spinner-border spinner-border-sm mb-2 ms-2"></span>
        </div>
      </div>
      <div class="col" [class.col-sm-2]="hideButtons">
        <button
          *ngIf="!isEditing"
          type="button"
          class="btn btn-danger"
          [class.disabled]="loading"
          (click)="deleted.emit()"
          [title]="t('delete')"
        >
          <i class="mdi mdi-trash-can-outline"></i>
        </button>
      </div>
    </ng-container>
  </ng-template>
</div>
