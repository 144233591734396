import { Injectable } from "@angular/core";
import { QRCodeSettings, WidgetMessageResultDto } from "@app-cmc/models";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class QrCodeService {
  constructor(private sanitizer: DomSanitizer) {}

  getInitialQRCodeSettings(resultMessage: WidgetMessageResultDto): QRCodeSettings {
    const qrCodePreviewLinkUrl = resultMessage.qrCode.previewHtmlLinkUrl;
    const safePreviewHtmlUrl: SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(qrCodePreviewLinkUrl);
    const paramsList: string[] = decodeURIComponent(qrCodePreviewLinkUrl).split("&");
    const qrCodeBackground = paramsList.find((item: string) => item.split("=")[0] === "backgroundColor").split("=")[1];

    return {
      sessionId: resultMessage.sessionId,
      safePreviewHtmlUrl: safePreviewHtmlUrl,
      background: qrCodeBackground
    };
  }

  getFinalQRCodeSettings(resultMessage: WidgetMessageResultDto, initialMessage: QRCodeSettings): QRCodeSettings {
    if (!initialMessage) {
      initialMessage = {} as QRCodeSettings;
    }

    const qrCodeHtmlLinkUrl = resultMessage.qrCode.htmlLinkUrl;
    const safeHtmlUrl: SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(qrCodeHtmlLinkUrl);

    return {
      ...initialMessage,
      safeHtmlUrl
    };
  }
}
