export class Subscription {
  subscriptionPlanId: number;
  isTrial: boolean;
  startDate: Date;
  endDate: Date;
  dailyLimit: number;
  dailyWarningCap: number;
  periodLimit: number;
  periodOverCapacity: number;
  periodWarningCap: number;
  dailyCount: number;
  count: number;
}
