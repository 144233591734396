<div *transloco="let t; read: 'features.issuePoints'" class="table-custom-wrapper">
  <table class="table-custom table-nowrap mb-0">
    <tbody>
      <tr class="table-custom__row">
        <th scope="row" class="table-custom__title">
          {{ t("card_title") + ":" }}
        </th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ loyaltyCard.title }}
          </span>
        </td>
      </tr>

      <tr class="table-custom__row">
        <th scope="row" class="table-custom__title">
          {{ t("user.firstName") }}
        </th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ loyaltyUser.firstName }}
          </span>
        </td>
      </tr>

      <tr class="table-custom__row">
        <th scope="row" class="table-custom__title">
          {{ t("user.lastName") }}
        </th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ loyaltyUser.lastName }}
          </span>
        </td>
      </tr>

      <tr *ngIf="loyaltyUser.email" class="table-custom__row">
        <th scope="row" class="table-custom__title">
          {{ t("user.email") }}
        </th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ loyaltyUser.email }}
          </span>
        </td>
      </tr>
      <tr></tr>

      <tr *ngIf="loyaltyUser.phone" class="table-custom__row">
        <th scope="row" class="table-custom__title">
          {{ t("user.phone") }}
        </th>
        <td class="table-custom__value">
          <span class="table-custom__value-text">
            {{ loyaltyUser.phone }}
          </span>
        </td>
      </tr>
      <tr>
        <div class="card-points">
          <h4 class="content-title">
            {{ loyaltyCard.type == "stampcard" ? t("add_stamps") : t("add_points") }}
          </h4>

          <div class="position-relative">
            <input
              *ngIf="loyaltyCard.type !== 'stampcard'"
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              class="form-control card-points__input-number"
              [formControl]="points"
              [class.is-invalid]="points.touched && points.errors"
              [placeholder]="t('placeholder.add_points')"
            />

            <ng-select
              *ngIf="loyaltyCard.type == 'stampcard'"
              class="card-points__select"
              [placeholder]="t('placeholder.add_stamps')"
              appendTo=".modal-body"
              [formControl]="points"
            >
              <ng-option [value]="1">+1 stamp</ng-option>
              <ng-option [value]="2">+2 stamps</ng-option>
              <ng-option [value]="3">+3 stamps</ng-option>
              <ng-option [value]="4">+4 stamps</ng-option>
              <ng-option [value]="5">+5 stamps</ng-option>
            </ng-select>

            <ng-container *ngIf="(points.touched && points.errors) || serverError">
              <ng-container *ngIf="points.errors?.required">
                <div class="invalid-feedback position-absolute" *transloco="let error; read: 'features.common'">
                  {{ error("requiredField") }}
                </div>
              </ng-container>

              <ng-container *ngIf="serverError && !points.errors?.required">
                <div class="invalid-feedback position-absolute">
                  {{ serverError }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </tr>
    </tbody>
  </table>
</div>
