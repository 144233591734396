import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  templateUrl: "dashboard-icon-view-widget.component.html",
  selector: "app-dashboard-icon-view-widget",
  styleUrls: ["dashboard-icon-view-widget.component.scss"]
})
export class DashboardIconViewWidgetComponent {
  @Input() text: string;
  @Input() icon: string;
  @Input() disabled = false;
  @Input() isManager = true;
  @Output() clicked = new EventEmitter();

  click() {
    if (!this.disabled) this.clicked.emit();
  }
}
