import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NotificationSeverity } from "@app-cmc/models";

@Component({
  selector: "notification-message",
  templateUrl: "notification-message.component.html",
  styleUrls: ["notification-message.component.scss"]
})
export class NotificationMessageComponent {
  @Input() messageText: string;
  @Input() actionLink: string;
  @Input() actionText: string;
  @Input() severity: NotificationSeverity;

  get severityClass() {
    return NotificationSeverity[this.severity];
  }
}
