import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormControl, Validators } from "@angular/forms";
import { CFSConnectionModalResult, CFSRequestResult, ClientCashRegister, ConnectionStatus, NetworkStatus } from "cfs-communication-pack";
import { CashRegistersService, CFSConnectionService, CfsService } from "@app-cmc/services";
import { catchError, filter, mergeMap, take, takeUntil } from "rxjs/operators";
import { of, Subject } from "rxjs";
import { Cfs } from "@app-cmc/models";

type ConnectionModalInfo = {
  [status in ConnectionStatus]?: {
    title: string;
    text?: string;
  };
};

@Component({
  selector: "app-cfs-connection-modal",
  templateUrl: "./cfs-connection-modal.component.html",
  styleUrls: ["./cfs-connection-modal.component.scss"]
})
export class CFSConnectionModalComponent implements OnInit, OnDestroy {
  constructor(
    public activeModal: NgbActiveModal,
    private cfsConnectionService: CFSConnectionService,
    private cfsService: CfsService,
    private cashRegistersService: CashRegistersService
  ) {}

  @Input() status: ConnectionStatus = ConnectionStatus.NOT_PAIRED;
  @Input() networkStatus?: NetworkStatus;
  @Input() connectedCode = "";
  @Input() cashregister: ClientCashRegister;
  @Input() cashregisterId: number;

  readonly connectionStatusEnum = ConnectionStatus;
  readonly modalInfo: ConnectionModalInfo = {
    [ConnectionStatus.NOT_PAIRED]: {
      title: "cfs.mainModal.title_not_paired",
      text: "cfs.mainModal.text_disconnected"
    },
    [ConnectionStatus.PAIRED]: {
      title: "cfs.mainModal.title_paired",
      text: "cfs.mainModal.text_paired"
    }
  };

  readonly maxCodeSymbolNumber = 8;

  pinCodeControl: UntypedFormControl;
  loading = false;

  private unsubscribe$ = new Subject();

  get pinCode(): string {
    return this.pinCodeControl.value;
  }

  ngOnInit(): void {
    this.initFormControl();

    this.cashRegistersService.currentCashRegister$
      .pipe(
        filter((item) => !!item),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((item: ClientCashRegister) => {
        if (item.clientStatus !== this.status || item.clientNetworkStatus !== this.networkStatus) {
          this.status = item.clientStatus;
          this.networkStatus = item.clientNetworkStatus;
          this.connectedCode = item.cfsCode;
          this.cashregisterId = item.id;
        }
      });
  }

  dismiss(): void {
    this.closeModal({
      closingReason: "dismiss"
    });
  }

  confirm(): void {
    if (this.pinCodeControl.valid && this.pinCode) {
      this.handleVerifyingCode();
    }
  }

  private handleVerifyingCode(): void {
    this.loading = true;

    this.cfsConnectionService
      .pairing(this.cashregisterId, this.pinCode, this.connectedCode)
      .pipe(
        filter((result: CFSRequestResult) => {
          if (!result.confirmed) {
            this.dismiss();
            this.loading = false;
          }

          return result.confirmed;
        }),
        mergeMap(() => {
          const cfs: Cfs = { cfsCode: this.pinCode };

          return this.cfsService.addCfs(this.cashregisterId, cfs).pipe(catchError(() => of(null)));
        }),
        take(1)
      )
      .subscribe(
        () => {
          this.closeModal({
            closingReason: "confirm",
            updatedCashRegister: {
              ...this.cashregister,
              cfsCode: this.pinCode
            },
            oldCode: this.connectedCode
          });
        },
        () => {
          this.dismiss();
          this.loading = false;
        }
      );
  }

  private closeModal(result: CFSConnectionModalResult): void {
    this.activeModal.close(result);
  }

  private initFormControl(): void {
    this.pinCodeControl = new UntypedFormControl("", [Validators.required]);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
