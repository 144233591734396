<app-header [brandLogo]="logo"></app-header>

<div class="card mt-4">
  <div class="card-body">
    <div class="p-5">
      <div class="col-md-12">
        <div class="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-12 col-sm-12">
          <div class="form-group">
            <h5 class="text-truncate mb-2">Choose Location</h5>
            <select
              #select
              (change)="selectLocation(+select.value)"
              class="form-select select2-search-disable select2-hidden-accessible"
              aria-hidden="true"
            >
              <option *ngFor="let loc of locations" [selected]="loc.id == locationId" [value]="loc.id">
                {{ locationRepresentation(loc) }}
              </option>
            </select>
            <button class="btn btn-primary mt-3" (click)="accept()">Accept</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div (click)="goToDashboard()" class="mt-3 text-center">
    <p><a class="cursor-pointer fw-medium text-primary">Return to the Dashboard</a></p>
  </div> -->
</div>
