<div class="cfs-connection-modal spinner-border-wrapper" [ngClass]="{ loading: loading }">
  <div class="modal-header content-space-between">
    <h5 class="modal-title">
      <ng-container *ngIf="modalInfo[status]">{{ modalInfo[status].title | transloco }}</ng-container>
      <!--      temporary decision before implementation combine modal:-->
      <ng-container *ngIf="!modalInfo[status]">{{ modalInfo[connectionStatusEnum.NOT_PAIRED].title | transloco }}</ng-container>
    </h5>

    <app-terminal-status class="connection-status" [status]="status" [networkStatus]="networkStatus"> </app-terminal-status>

    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <app-terminal-pairing-steps class="row mt-n3"></app-terminal-pairing-steps>

    <p *ngIf="status === connectionStatusEnum.PAIRED && connectedCode" class="modal-text">
      {{ "cfs.mainModal.text_paired_with_code" | transloco : { connectedCode: connectedCode } }}
    </p>

    <p *ngIf="modalInfo[status]" class="modal-text">
      {{ modalInfo[status].text | transloco }}
    </p>

    <!--      temporary decision before implementation combine modal:-->
    <p *ngIf="!modalInfo[status]" class="modal-text">
      {{ modalInfo[connectionStatusEnum.NOT_PAIRED].text | transloco }}
    </p>

    <label class="col-form-label">
      {{ "cfs.mainModal.code_placeholder" | transloco }}
    </label>

    <fieldset>
      <input type="string" class="form-control pin-code-input" [formControl]="pinCodeControl" [maxLength]="maxCodeSymbolNumber" />
    </fieldset>

    <div class="mt-4 modal-footer two-actions">
      <button type="button" class="btn btn-outline" (click)="dismiss()">
        {{ "common.controls.cancel" | transloco }}
      </button>

      <button type="button" class="btn btn-main" [ngClass]="{ disabled: pinCodeControl.invalid }" (click)="confirm()">
        <ng-container *ngIf="status === connectionStatusEnum.PAIRED">
          {{ "common.controls.reconnect" | transloco }}
        </ng-container>

        <ng-container *ngIf="status !== connectionStatusEnum.PAIRED">
          {{ "common.controls.connect" | transloco }}
        </ng-container>
      </button>
    </div>

    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"> </span>
  </div>
</div>
