import { throwError, of, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { LogEntry } from "./log.service";
import { DataService } from "../data.service";

export abstract class LogPublisher {
  location: string;

  abstract log(record: LogEntry): Observable<boolean>;
  abstract clear(): Observable<boolean>;
}

export class LogConsole extends LogPublisher {
  log(record: LogEntry): Observable<boolean> {
    // Log to the console
    console.log(record.buildLogString());

    return of(true);
  }

  clear(): Observable<boolean> {
    console.clear();

    return of(true);
  }
}

export class LogLocalStorage extends LogPublisher {
  constructor() {
    super();

    this.location = "logging";
  }

  getAll(): Observable<LogEntry[]> {
    let values: LogEntry[];

    // Retrieve all values from local storage
    const location = localStorage.getItem(this.location);
    values = location ? JSON.parse(location) : [];

    return of(values);
  }

  log(record: LogEntry): Observable<boolean> {
    const ret = false;
    let values: LogEntry[];

    try {
      const sessionValues = localStorage.getItem(this.location);
      values = sessionValues ? JSON.parse(sessionValues) : [];
      // Add new log entry to the array
      values.push(record);
      // Store the complete array into local storage
      localStorage.setItem(this.location, JSON.stringify(values));
    } catch (ex) {
      console.log(ex);
    }

    return of(ret);
  }

  clear(): Observable<boolean> {
    localStorage.removeItem(this.location);
    return of(true);
  }
}

export class LogPublisherConfig {
  loggerName: string;
  loggerLocation: string;
  isActive: boolean;
}

export class LogWebApi extends LogPublisher {
  constructor(private dataService: DataService) {
    super();
    this.location = "log";
  }

  log(record: LogEntry): Observable<boolean> {
    this.location = "log"; // investigate why location is undef
    return this.dataService.post(this.location, record).pipe(catchError(this.handleErrors));
  }

  clear(): Observable<boolean> {
    // TODO: Call Web API to clear all log entries
    return of(true);
  }

  private handleErrors(error: any): Observable<any> {
    const errors: string[] = [];
    let msg = "";

    msg = "Status: " + error.status;
    msg += " - Status Text: " + error.statusText;
    msg += " - Exception Message: " + error.message;

    errors.push(msg);

    console.error("An error occurred", errors);

    return throwError(errors);
  }
}
