import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AllowedDeliveryChannel, FeatureData, FeatureTypes } from "../models";

export class FeatureModalBaseComponent {
  title: string;
  data?: FeatureData;
  deliveryMethod: AllowedDeliveryChannel;
  constructor(public activeModal: NgbActiveModal, public featureType: FeatureTypes) {}

  dismiss() {
    this.activeModal.dismiss();
  }
}
