import { Inject, Injectable } from "@angular/core";
import * as Pubnub from "pubnub";
import { ListenerParameters, PublishResponse, PubnubStatus } from "pubnub";
import { CFSPairingState, CFSConnectionMessage, CommunicatorConfig } from "../models";
import { COMMUNICATOR_CONFIG_TOKEN } from "../cfs-communication-pack.module";

@Injectable()
export class CommunicatorService {
  communicator?: Pubnub;

  constructor(@Inject(COMMUNICATOR_CONFIG_TOKEN) private config: CommunicatorConfig) {}

  init(code: string): void {
    const userId = this.generateUserId(code);

    if (userId) {
      console.log("new Pubnub userId:", userId);
      this.communicator = new Pubnub({
        publishKey: this.config.publishKey,
        subscribeKey: this.config.subscribeKey,
        userId,
        ssl: true,
        logVerbosity: false,
        restore: true,
        presenceTimeout: 180
      });
    }
  }

  isUserIdApplied(code: string): boolean {
    const userId = this.generateUserId(code);
    const currentUserId = this.communicator?.getUUID() || "";

    return !!(userId && userId === currentUserId);
  }

  addListener(params: ListenerParameters): void {
    this.communicator?.addListener(params);
  }

  subscribe(channels: string[], withPresence = false): void {
    const filteredChannels = channels.filter((channel: string) => !this.isSubscriptionExist(channel));

    if (filteredChannels.length) {
      this.communicator?.subscribe({
        channels: filteredChannels,
        withPresence
      });
    }
  }

  publish(message: CFSConnectionMessage, channel: string, sendByPost: boolean = false): void {
    const params: Pubnub.PublishParameters = {
      message,
      channel,
      sendByPost
    };

    this.communicator?.publish(params, (status: PubnubStatus, response: PublishResponse) => {
      if (status.error) {
        console.log("pubnub publish error: ", channel, status.error, response);
      } else {
        console.log("pubnub publish response", channel, response);
      }
    });
  }

  hereNow(chanel: string): Promise<Pubnub.HereNowResponse> {
    if (!this.communicator) return Promise.reject("Communicator is not initialized");

    return this.communicator?.hereNow({
      channels: [chanel],
      includeUUIDs: true,
      includeState: true
    });
  }

  getState(chanel: string): Promise<Pubnub.GetStateResponse> {
    if (!this.communicator) return Promise.reject("Communicator is not initialized");

    return this.communicator?.getState({
      channels: [chanel]
    });
  }

  setState(chanel: string, state: CFSPairingState): Promise<Pubnub.SetStateResponse> {
    if (!this.communicator) return Promise.reject("Communicator is not initialized");

    return this.communicator?.setState({
      channels: [chanel],
      state
    });
  }

  getSubscribedChannels(): string[] {
    if (!this.communicator) return [];

    return this.communicator?.getSubscribedChannels();
  }

  unsubscribeFromChannel(channel: string): void {
    this.communicator?.unsubscribe({ channels: [channel] });
  }

  unsubscribeAll(): void {
    this.communicator?.stop();
    this.communicator?.unsubscribeAll();
  }

  reconnect(): void {
    this.communicator?.reconnect();
  }

  private isSubscriptionExist(channel: string): boolean {
    const subscriptions: string[] = this.getSubscribedChannels();

    return subscriptions.some((item: string) => item === channel);
  }

  private generateUserId(code: string): string {
    return code ? `${this.config.uuid}-${code}` : "";
  }
}
