import { Injectable } from "@angular/core";
import { DataService } from "../core/services/data.service";
import { Observable } from "rxjs";
import { CashRegister, CashRegisterPairingStatusPostDto, CashRegisterStatusPostDto, ClientCashRegister } from "cfs-communication-pack";

@Injectable({
  providedIn: "root"
})
export class CashRegisterService {
  serviceUrl = "cashregister";

  constructor(private http: DataService) {}

  getById(id: number): Observable<CashRegister> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  getAll(locationIds: number[]): Observable<CashRegister[]> {
    return this.http.post(`${this.serviceUrl}/bylocation`, { locationIds: locationIds });
  }

  addCashRegister(locationId: number, cashregister: CashRegister): Observable<CashRegister> {
    return this.http.post(`${this.serviceUrl}/${locationId}`, cashregister);
  }

  updateCashRegister(cashregister: CashRegister): Observable<number> {
    return this.http.put(`${this.serviceUrl}/${cashregister.id}`, cashregister);
  }

  deleteCashRegister(id: number) {
    return this.http.delete(`${this.serviceUrl}/${id}`);
  }

  setSelectedCashRegisterIdToStorage(cashRegisterId) {
    localStorage.setItem("cashRegisterId", JSON.stringify(cashRegisterId));
  }

  setClientCashRegisterToStorage(cashRegister: ClientCashRegister) {
    console.log("setClientCashRegisterToStorage", cashRegister);
    // todo: remove later: added temporary, must to be removed after cashRegisterId and locationId will be no longer taken from the token
    localStorage.setItem("cashRegister", JSON.stringify(cashRegister));
  }

  getSelectedCashRegisterIdFromStorage() {
    if (localStorage.getItem("cashRegisterId")) return +localStorage.getItem("cashRegisterId");
    return null;
  }

  getClientCashRegisterFromStorage(): ClientCashRegister | undefined {
    // todo: remove later: added temporary, must to be removed after cashRegisterId and locationId will be no longer taken from the token
    const value = localStorage.getItem("cashRegister");

    return value ? JSON.parse(value) : undefined;
  }

  setCashRegisterStatus(id: number, data: CashRegisterStatusPostDto): Observable<null> {
    return this.http.post(`${this.serviceUrl}/${id}/status`, data);
  }

  setCashRegisterPairingStatus(id: number, data: CashRegisterPairingStatusPostDto): Observable<null> {
    return this.http.post(`${this.serviceUrl}/${id}/status/pairing`, data);
  }
}
