import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  CFSCommunicationChannels,
  CFSConnectionMessage,
  CFSConnectionSettings,
  CFSMessageType,
  CFSPairingState,
  CMCCommunicationChannels,
  CommunicatorMessageEvent,
  ConnectionStatus,
  DisplayQrCodeInfo
} from "../models";
import { CommunicatorService } from "./communicator.service";
import { filter } from "rxjs/operators";
import * as Pubnub from "pubnub";
import { MessageEvent, PubnubStatus, StatusEvent } from "pubnub";
import { getFullChannelName } from "../utils";

@Injectable()
export class CFSMessagingService {
  constructor(private communicator: CommunicatorService) {}

  private readonly channelList: CFSCommunicationChannels[] = [
    CFSCommunicationChannels.MESSAGE,
    CFSCommunicationChannels.PAIRING,
    CFSCommunicationChannels.SET_STATUS,
    CFSCommunicationChannels.RETURN_STATUS,
    CFSCommunicationChannels.PIN_CODE,
    CFSCommunicationChannels.CONNECTION_ESTABLISHED,
    CFSCommunicationChannels.SHOW_QR_CODE,
    CFSCommunicationChannels.HIDE_QR_CODE,
    CFSCommunicationChannels.SCAN_QR_CODE,
    CFSCommunicationChannels.UPDATE_CAROUSEL,
    CFSCommunicationChannels.UPDATE_CFS,
    CFSCommunicationChannels.SHOW_TEMPLATE,
    CFSCommunicationChannels.RESET_CASH_REGISTER
  ];

  private pin = "";
  private updateStatus$ = new BehaviorSubject<CFSConnectionMessage>(null as any);
  private updatePairing$ = new BehaviorSubject<CFSConnectionMessage>(null as any);

  status$: Observable<CFSConnectionMessage> = this.updateStatus$.asObservable().pipe(filter((info: CFSConnectionMessage) => !!info));
  returnStatus$ = new Subject<CFSConnectionMessage>();
  pairing$ = this.updatePairing$.asObservable();
  showQrCode$ = new Subject<CFSConnectionMessage>();
  scanQrCode$ = new Subject<CFSConnectionMessage>();
  hideQrCode$ = new Subject<CFSConnectionMessage>();
  qrCodeScanned$ = new Subject<CFSConnectionMessage>();
  updateCarousel$ = new Subject<CFSConnectionMessage>();
  updateCarouselOnNewCircle$ = new Subject<CFSConnectionMessage>();
  forceDisplayTemplate$ = new Subject<CFSConnectionMessage>();
  resetCFS$ = new Subject<CFSConnectionMessage>();
  networkDown$ = new Subject<null>();
  networkUp$ = new Subject<null>();
  isMessagingReady$ = new BehaviorSubject<boolean>(false);
  logError$ = new Subject<string>();
  updateCfsMenu$ = new Subject<CFSConnectionMessage>();

  init(code: string, connected: boolean) {
    this.communicator.init(code);

    this.communicator.addListener({
      message: this.handleMessage.bind(this),
      status: this.handleStatuses.bind(this)
    });

    this.subscribeToChannels(code);

    if (connected) {
      this.setStatusConnected(code);
    } else {
      this.updateStatus$.next({ status: ConnectionStatus.NOT_PAIRED, connectedCode: code });
    }
  }

  setStatusConnected(code: string, settings?: CFSConnectionSettings): Promise<CFSPairingState | null> {
    return new Promise((resolve) => {
      const fullSettings = settings ? { ...settings, resolve } : settings;

      if (!settings) {
        resolve(null);
      }

      this.updateStatus$.next({ status: ConnectionStatus.PAIRED, connectedCode: code, settings: fullSettings });
    });
  }

  subscribeToChannels(code: string) {
    if (code) {
      this.pin = code;
      const channels = this.getChannelList(this.channelList, code);

      this.communicator.subscribe(channels);
    }
  }

  publishScannedQrCodeResult(result: string, pin: string): void {
    if (result && pin) {
      const channel = getFullChannelName(CMCCommunicationChannels.SCANNED_QR_CODE, pin);

      this.communicator.publish({ type: CFSMessageType.SCANNED_QR_CODE, result }, channel);
    }
  }

  publishHideQrCodeMessage(sessionId: string, pin: string): void {
    if (pin) {
      const channel = getFullChannelName(CMCCommunicationChannels.HIDE_QR_CODE, pin);
      const hideQrCodeInfo: DisplayQrCodeInfo = { sessionId };

      this.communicator.publish({ type: CFSMessageType.HIDE_QR_CODE, hideQrCodeInfo }, channel);
    }
  }

  getReturnStatusChannel(code: string): string {
    return getFullChannelName(CMCCommunicationChannels.RETURN_STATUS, code);
  }

  getState(code: string): Promise<Pubnub.GetStateResponse> {
    const channel = getFullChannelName(CMCCommunicationChannels.RETURN_STATUS, code);

    return this.communicator.getState(channel);
  }

  setState(code: string, state: CFSPairingState): Promise<Pubnub.SetStateResponse> {
    const channel = getFullChannelName(CMCCommunicationChannels.RETURN_STATUS, code);

    return this.communicator.setState(channel, state);
  }

  confirmPairing(pin: string, cashRegisterId: number, state: CFSPairingState | null): void {
    this.pin = pin;
    const settings: CFSConnectionSettings = { cashRegisterId, state };
    const channel = getFullChannelName(CMCCommunicationChannels.CONFIRM_PAIRING, pin);

    this.communicator.publish({ type: CFSMessageType.CONFIRM_PAIRING, pinCode: this.pin, settings }, channel);
  }

  confirmReset(pin: string, cashRegisterId: number): void {
    this.pin = pin;
    const settings: CFSConnectionSettings = { cashRegisterId };
    const channel = getFullChannelName(CMCCommunicationChannels.CONFIRM_RESET, pin);

    this.communicator.publish({ type: CFSMessageType.CONFIRM_RESET, pinCode: this.pin, settings }, channel);
  }

  destroyMessages(): void {
    this.pin = "";

    this.communicator.unsubscribeAll();
  }

  leave(pin: string): void {
    console.log("leave", pin); // todo: add ConsoleLogHandlerService to common project
    const channel = getFullChannelName(CMCCommunicationChannels.RETURN_STATUS, pin);

    this.communicator.unsubscribeFromChannel(channel);
  }

  join(pin: string): void {
    console.log("join  ", pin); // todo: add ConsoleLogHandlerService to common project
    const channel = getFullChannelName(CMCCommunicationChannels.RETURN_STATUS, pin);

    this.communicator.subscribe([channel]);
  }

  finishPairing(): void {
    // clear pairing data on pairing handle complete
    this.updatePairing$.next(null as any);
  }

  getSubscribedChannels(): string[] {
    return this.communicator.getSubscribedChannels();
  }

  private handleMessage(messageEvent: MessageEvent): void {
    const message: CFSConnectionMessage = messageEvent.message;
    console.log("CFS message: ", messageEvent); // todo: add ConsoleLogHandlerService to common project

    if (!message.status || message.connectedCode) {
      this.handleMessageByType(messageEvent);
    }
  }

  private handleStatuses(event: StatusEvent | PubnubStatus): void {
    const category: string = (event as StatusEvent).category;
    const pubnubStatus = event as PubnubStatus;
    const error: boolean = pubnubStatus.error;
    console.log("statuses event", event); // todo: add ConsoleLogHandlerService to common project

    if (error) {
      const { errorData, statusCode } = pubnubStatus;
      const errorMessage: string = errorData?.message || statusCode?.toString() || "Unknown PubNub error";
      this.logError$.next(errorMessage);
      this.communicator.reconnect();
    }

    if (category === "PNConnectedCategory") {
      this.isMessagingReady$.next(true);
    } else if (category === "PNNetworkDownCategory") {
      this.networkDown$.next(null);
    } else if (category === "PNNetworkUpCategory") {
      this.networkUp$.next(null);
    }
  }

  private handleMessageByType(messageEvent: CommunicatorMessageEvent): void {
    const message: CFSConnectionMessage = messageEvent.message;
    const { type } = message;

    switch (type) {
      case CFSMessageType.RETURN_STATUS:
        this.returnStatus$.next(message);
        break;
      case CFSMessageType.PAIR:
        this.updatePairing$.next(message);
        break;
      case CFSMessageType.SHOW_QR_CODE:
        this.showQrCode$.next(message);
        break;
      case CFSMessageType.HIDE_QR_CODE:
        this.hideQrCode$.next(message);
        break;
      case CFSMessageType.QR_CODE_SCANNED:
        this.qrCodeScanned$.next(message);
        break;
      case CFSMessageType.SCAN_QR_CODE:
        this.scanQrCode$.next(message);
        break;
      case CFSMessageType.UPDATE_CFS_CAROUSEL:
        this.updateCarousel$.next(message);
        break;
      case CFSMessageType.UPDATE_CFS:
        this.updateCarouselOnNewCircle$.next(message);
        break;
      case CFSMessageType.SHOW_CFS_TEMPLATE:
        this.forceDisplayTemplate$.next(message);
        break;
      case CFSMessageType.RESET_CASH_REGISTER:
        this.resetCFS$.next(message);
        break;
      case CFSMessageType.CFS_MENU_UPDATE:
        this.updateCfsMenu$.next(message);
        break;
    }
  }

  private getChannelList(titleList: CFSCommunicationChannels[], code: string): string[] {
    if (titleList?.length && code) {
      return titleList.map((title: CFSCommunicationChannels) => getFullChannelName(title, code));
    }

    return [];
  }
}
