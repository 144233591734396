<div
  *transloco="let t; read: 'features.common'"
  class="item-list-view d-flex opacity"
  [ngClass]="{ 'cursor-pointer': !disabled, disabled: disabled }"
  [ngbTooltip]="!disabled ? text : isManager ? t('appNotActiveManager') : t('appNotActive')"
  [tooltipClass]="disabled ? 'disabled' : ''"
  #tooltip="ngbTooltip"
  (click)="click(); tooltip.close()"
>
  <span class="icon-list-view">
    <i class="ri-admin-line ri-2x" [ngClass]="icon"></i>
  </span>

  <span class="text-list-view">
    {{ text }}
  </span>
</div>
