import { PlatformAccount } from "./platform-account";
import { Role } from "./role";

export enum UserStatus {
  Undefined = 0,
  New = 1,
  PendingConfiguration = 2,
  ConfigurationInProgress = 3,
  Active = 4,
  Inactive = 5
}

export class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  managerId?: number;
  password: string;
  status: UserStatus;
  role?: Role;
  token?: string;
  partner?: number;
  platformAccounts?: PlatformAccount[] = [];
}
