import { Injectable } from "@angular/core";
import { DataService } from "@app-cmc/core/services/data.service";
import {
  LoyaltyCard,
  LoyaltyCardUpdateAmount,
  LoyaltyCardUpdateResult,
  LoyaltyCardUserResult,
  LoyaltyCardUserSearchModel
} from "@app-cmc/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LoyaltyCardService {
  private serviceUrl = "LoyaltyCard";

  constructor(private http: DataService) {}

  getAccountLoyaltyCard(accountId?: number): Observable<LoyaltyCard> {
    let url = `${this.serviceUrl}/account`;
    if (accountId) url += `/${accountId}`;
    return this.http.get(url);
  }

  addUserLoyaltyCardAmount(update: LoyaltyCardUpdateAmount): Observable<LoyaltyCardUpdateResult> {
    return this.http.post(`${this.serviceUrl}/addUserAmount`, update).pipe(
      map((result: LoyaltyCardUpdateResult) => {
        return result;
      })
    );
  }
  getUserLoyaltyCard(search: LoyaltyCardUserSearchModel): Observable<LoyaltyCardUserResult> {
    return this.http.post(`${this.serviceUrl}/userCardDetails`, search).pipe(
      map((card: LoyaltyCardUserResult) => {
        return card;
      })
    );
  }
}
