<div class="custom" *transloco="let t; read: i18nScope">
  <div class="modal-header with-subtitle">
    <h5 class="modal-title">
      {{ t('modal.sendingOptions.title') }}
    </h5>

    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
  </div>

  <div class="modal-body pt-3 pb-2">
    <app-toggle-group-with-label
      class="d-block mt-3"
      [groupClasslist]="'btn-group w-100'"
      [items]="contactTypeButtons"
      (change)="onModeChange($event.value)"
    ></app-toggle-group-with-label>

    <app-input
      *ngIf="selectedContactType === ContactType.Email"
      class="d-block mt-3"
      [control]="form.controls.customerEmail"
      [label]="t('modal.sendingOptions.emailLabel')"
      [placeholder]="t('modal.sendingOptions.emailLabel')"
    >
    </app-input>

    <app-input
      *ngIf="selectedContactType === ContactType.Phone"
      class="d-block mt-3"
      [control]="form.controls.customerPhone"
      [label]="t('modal.sendingOptions.phoneLabel')"
      [placeholder]="t('modal.sendingOptions.phoneLabel')"
      [mask]="phoneMask"
    >
    </app-input>
  </div>

  <div class="modal-footer mt-4">
    <div class="d-flex justify-content-between w-100">
      <button
        class="btn btn-outline me-3"
        (click)="onClose()">
        {{ t('common.controls.close') }}
      </button>

      <button
        class="btn btn-primary ms-3"
        [disabled]="!form.valid"
        (click)="onConfirm()">
        {{ t('common.controls.send') }}
      </button>
    </div>
  </div>
</div>
