import { RecipientProfile } from "@app-cmc/models/recipient-profile";

export enum AllowedDeliveryChannel {
  undefined = 0,
  email = 1,
  sms = 2,
  // tslint:disable-next-line:no-bitwise
  emailAndSms = email | sms,
  qr = 64,
  hyperLink = 128,
  all = 16383
}

export enum DeliverySource {
  LandingPage,
  Cmc,
  Printed,
  Cfs,
  Kiosk,
  Pos
}

export class WidgetModel {
  public id: number;
  public name: string;
  public deliveryMethod: AllowedDeliveryChannel;
  public disabled: boolean;
  public configured: boolean;
}

export class WidgetMessagePostDto {
  locationId: number;
  campaignId: number;
  data?: {};
  profile: RecipientProfile;
  deliveryMethod: AllowedDeliveryChannel;
  deliverySource: DeliverySource;
  sessionId?: string;
}

export interface WidgetMessageResultDto {
  shortLinkUrl?: string;
  notificationMessageId?: string;
  profile: RecipientProfile;
  qrCode: QrCodeLinkSet;
  sessionId?: string;
}

export interface QrCodeLinkSet {
  htmlLinkUrl: string;
  previewHtmlLinkUrl: string;
}
export interface WidgetMessageInfo {
  recipients: RecipientProfile[];
  deliveryMethod: AllowedDeliveryChannel;
  deliverySource: DeliverySource;
  sessionId?: string;
}
