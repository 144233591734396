export class Location {
  id: number;
  companyName: string;
  accountId: string;
  city: string;
  state: string;
  address: string;
  website: string;
  contactEmail: string;
  contactLastName: string;
  contactFirstName: string;
  email: string;
  longitude: number;
  latitude: number;
  zip: number;
}

export class LocationShort {
  id: number;
  name: string;
}

export class LocationUser {
  locationId: number;
  userId: number;
}
