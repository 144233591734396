import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "matchBitMask"
})
export class MatchBitMaskPipe implements PipeTransform {
  transform(value: number, bitmask: number): boolean {
    return (value & bitmask) == bitmask;
  }
}
