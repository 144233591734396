import { Injectable } from "@angular/core";
import { CFSStatusInfo, ConnectionStatus, NetworkStatus } from "cfs-communication-pack";
import { DatePipe } from "@angular/common";

type StatusesAsset = {
  [key in ConnectionStatus | NetworkStatus]: CFSStatusInfo;
};

@Injectable({ providedIn: "root" })
export class CashRegisterUtilsService {
  constructor(private datePipe: DatePipe) {}

  private readonly connectCodeDigitNumber = 8;
  private readonly statusesAsset: StatusesAsset = {
    [ConnectionStatus.PAIRED]: {
      label: "cfs.status.paired",
      type: "successStatus"
    },
    [ConnectionStatus.NOT_PAIRED]: {
      label: "cfs.status.not_paired",
      type: "alertStatus"
    },
    [ConnectionStatus.UNAVAILABLE]: {
      label: "cfs.status.unavailable",
      type: "inertStatus"
    },
    [NetworkStatus.ONLINE]: {
      label: "cfs.status.online",
      type: "successStatus"
    },
    [NetworkStatus.OFFLINE]: {
      label: "cfs.status.offline",
      type: "alertStatus"
    }
  };
  private readonly _pairingDateFormat = "MM.dd.yyyy hh:mm:ss";

  getCashregisterStatusLabel(status?: ConnectionStatus, networkStatus?: NetworkStatus): CFSStatusInfo | null {
    if (status === ConnectionStatus.UNAVAILABLE) {
      return this.statusesAsset[status];
    } else if (networkStatus === NetworkStatus.ONLINE) {
      return status === ConnectionStatus.NOT_PAIRED
        ? this.statusesAsset[ConnectionStatus.NOT_PAIRED]
        : this.statusesAsset[NetworkStatus.ONLINE];
    } else if (networkStatus === NetworkStatus.OFFLINE) {
      return this.statusesAsset[networkStatus];
    }

    return null;
  }

  getCashregisterPairingDateLabel(pairingDate: number): string {
    if (!pairingDate) {
      return "";
    }

    return this.datePipe.transform(pairingDate, this._pairingDateFormat) as string;
  }

  getNewConnectionCode(): string {
    return this.getRandomNumberByDigitCount(this.connectCodeDigitNumber).toString();
  }

  private getRandomNumberByDigitCount(digitCount: number): number {
    const min = parseFloat(`1e${digitCount - 1}`);
    const max = parseFloat(`1e${digitCount}`) - 1;

    return this.getRandomNumber(min, max);
  }

  private getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
