import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { FeatureModalBaseComponent } from "@app-cmc/features/feature-modal-base.component";
import { FeatureTypes } from "@app-cmc/models";
import { CouponSessionUser, UpdateCouponSession } from "@app-cmc/models/coupons";
import { CouponInfo } from "@app-cmc/models/coupons/coupon-info";
import { CouponSession } from "@app-cmc/models/coupons/coupon-session";
import { CouponSessionStatus } from "@app-cmc/models/coupons/coupon-session-status";
import { CFSConnectionService, CompanyDataService } from "@app-cmc/services";
import { CouponService } from "@app-cmc/services/coupon.service";
import { ToastService } from "@app-cmc/shared/components/app-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { WizardComponent } from "angular-archwizard";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-validate-coupon-modal",
  templateUrl: "./validate-coupon-modal.component.html",
  styleUrls: ["./validate-coupon-modal.component.scss"]
})
export class ValidateCouponModalComponent extends FeatureModalBaseComponent implements OnInit, OnDestroy {
  private _hideValidateButton: boolean;
  private unsubscribe$ = new Subject();

  code = new UntypedFormControl("", [Validators.required, Validators.minLength(5)]);
  isValidated = false;
  isUsed = false;
  loading = false;
  coupon: CouponInfo;

  couponStatus: CouponSessionStatus;
  couponSession: CouponSession;
  user: CouponSessionUser;
  invalidCoupon = false;

  get isValid() {
    return this.code.valid;
  }

  get hideValidateButton() {
    return this._hideValidateButton;
  }

  set hideValidateButton(value: boolean) {
    this._hideValidateButton = value;
  }

  @ViewChild("wizard") wizard: WizardComponent;

  constructor(
    public couponSvc: CouponService,
    private cdr: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    public toastSvc: ToastService,
    private companyDataService: CompanyDataService,
    private cfsService: CFSConnectionService
  ) {
    super(activeModal, FeatureTypes.ValidateCoupon);
  }

  ngOnInit(): void {
    this.code.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.invalidCoupon = false;
    });
  }

  search() {
    if (!this.isValid) return;

    this.loading = true;
    this.invalidCoupon = false;
    this.isUsed = false;
    this.couponSvc.getCouponSessionByCode(this.code.value).subscribe(
      (couponSes) => {
        this.loading = false;
        if (couponSes) {
          // this.code.disable();
          this.couponSession = couponSes;
          this.coupon = couponSes.campaign;
          this.couponStatus = couponSes.status;
          this.user = couponSes.user;
          this.hideValidateButton = this.isUsed = !!+this.couponStatus.validated;
          this.wizard.goToNextStep();
        } else {
          this.invalidCoupon = true;
          this.code.markAsTouched();
        }
        this.cdr.detectChanges();
      },
      () => {
        this.loading = false;

        this.invalidCoupon = true;
      }
    );
  }

  scan(code: string) {
    this.onScanModalClose();

    if (code && code.startsWith("http")) {
      code = code.split("/").pop();
    }
    this.code.setValue(code);
    this.search();
  }

  validate() {
    const updateCouponSession = new UpdateCouponSession();
    updateCouponSession.couponsession = this.couponSession.session;
    this.loading = true;
    this.couponSvc.validateCoupon(updateCouponSession).subscribe(
      (res) => {
        if (res.operation === "validate_successful") {
          this.isValidated = true;
          this.hideValidateButton = true;
          this.toastSvc.success("Coupon validated. Thank You.");
        }
        this.companyDataService.retriveData();
        this.loading = false;
        // this.dismiss();
      },
      () => {
        this.loading = false;
      }
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onScanModalOpened(): void {
    this.cfsService.toggleScanQrCodeModal(true).pipe(take(1)).subscribe();
  }

  private onScanModalClose(): void {
    this.cfsService
      .toggleScanQrCodeModal(false)
      .pipe(take(1))
      .subscribe(() => {});
  }
}
