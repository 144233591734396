import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LoyaltyCard } from "@app-cmc/models";

@Component({
  selector: "app-loyalty-cards",
  templateUrl: "./loyalty-cards.component.html",
  styleUrls: ["./loyalty-cards.component.scss"]
})
export class LoyaltyCardsComponent {
  @Input() loyaltyCards: Array<LoyaltyCard>;
  @Input() selectedCard: LoyaltyCard;
  @Input() loading = false;

  @Output() selectChanged = new EventEmitter<LoyaltyCard>();

  scrollbarOptions: any = {
    forceVisible: false,
    autoHide: false
  };

  constructor() {}

  select(card: LoyaltyCard) {
    this.selectChanged.emit(card);
  }
}
