import { ConnectionStatus, ContentProviderType, NetworkStatus } from "cfs-communication-pack";

export interface CashRegister {
  id?: number;
  name?: string;
  cfsCode?: string;
  enabled?: boolean;
  terminalId?: string;
  locationId?: number;
  displayTypeId?: number;
  paringStatus?: CashRegisterParingStatuses;
  networkStatus?: CashRegisterNetworkStatuses;
  pairedBy?: number;
  pairedOn?: string;
  createdBy?: number;
  createdOn?: string;
  modifiedBy?: number;
  modifiedOn?: string;
  lastOnlineDate?: string;
  createdByName?: string;
  modifiedByName?: string;
  pairedByName?: string;
  externalConnection: CashRegisterExternalConnectionDto;
}

export interface CashRegisterBaseConnectionInfo {
  cfsCode: string;
  id: number;
  locationId: number;
}

export interface ClientCashRegister extends CashRegister {
  locationName?: string;
  clientStatus?: ConnectionStatus;
  clientNetworkStatus?: NetworkStatus;
  pairingDate?: number;
  displayType?: string;
  contentProviders?: CashRegisterContentProviderDto[];

  // todo: remove or rename, it is temporary names to use for dev env only:
  backEndStatus?: ConnectionStatus;
  backEndNetworkStatus?: NetworkStatus;
  backEndPairingDate?: number;
  backEndLastOnlineDate?: number;
}

export interface ClientCashRegisterBackEndStatuses {
  backEndStatus?: ConnectionStatus;
  backEndNetworkStatus?: NetworkStatus;
  backEndPairingDate?: number;
  backEndLastOnlineDate?: number;
}

export interface CashRegisterStatusGetResponseDto {
  id: number;
  pairedBy?: number; // Paired by user id
  pairedOn?: string;
  pairedByName?: string;
  paringStatus: CashRegisterParingStatuses;
  networkStatus: CashRegisterNetworkStatuses;
  lastOnlineDate?: string;
}

export interface CashRegisterStatusPostDto {
  status: CashRegisterStatuses;
  message?: string;
}

export interface CashRegisterPairingStatusPostDto {
  paringStatus: CashRegisterParingStatuses;
  message?: string;
}

export interface CashRegisterStatusGetInfo {
  id: number;
  postDto: CashRegisterStatusPostDto | CashRegisterPairingStatusPostDto;
}

export enum CashRegisterParingStatuses {
  Paired = "Paired",
  NotPaired = "NotPaired"
}
export enum CashRegisterNetworkStatuses {
  Online = "Online",
  Offline = "Offline",
  Unavailable = "Unavailable"
}

export enum CashRegisterStatuses {
  Online = "Online",
  CfsRefresh = "CfsRefresh",
  CarouselRefresh = "CarouselRefresh",
  Reset = "Reset",
  Error = "Error"
}

export enum CfsStatusError {
  FailedReset = "FailedReset",
  FailedCfsRefresh = "FailedCfsRefresh"
}

export interface CashRegisterExternalConnectionDto {
  contentProviders?: CashRegisterContentProviderDto[];
}

export interface CashRegisterContentProviderDto {
  title?: string;
  providerType: ContentProviderType;
  contentUrl?: string;
  pairedBy: number;
  pairedByName?: string;
  pairedOn: string;
}

export interface ContentProviderConnectCashRegisterRequestDto {
  contentUrl: string;
  contentProviderCode?: string;
}

export interface ContentProviderConnectCashRegisterResponseDto {
  hasError: boolean;
  error: string;
}
