import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { BehaviorSubject, Observable } from "rxjs";
import { ProfileModel } from "../models/profile-model";
import { JwtHelperService } from "./jwtHelperService";

@Injectable()
export class AccountService {
  public jwtHelper: JwtHelperService = new JwtHelperService();

  $userSbj: BehaviorSubject<ProfileModel> = new BehaviorSubject<ProfileModel>(this.user);
  public userChanged: Observable<ProfileModel> = this.$userSbj.asObservable();

  constructor(private oAuthService: OAuthService) {}

  public get isLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }
  public get user(): ProfileModel | undefined {
    if (this.accessToken) {
      return this.jwtHelper.decodeToken(this.accessToken);
    }
    return undefined;
  }
  get userId() {
    let userId = null;
    if (this.isLoggedIn && this.user) userId = +this.user.sub;
    return userId;
  }
  public refreshUser() {
    this.$userSbj.next(this.user);
  }

  public get accessToken(): string {
    return this.oAuthService.getAccessToken();
  }
  // Used to access user information
  public get idToken(): string {
    return this.oAuthService.getIdToken();
  }
}
