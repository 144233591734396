import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslocoRootModule } from "@app-cmc/core/transloco/transloco-root.module";
import { CouponCarouselComponent, QrCodeCouponComponent, WidgetMessageComponent } from "@app-cmc/features/common";
import { SharedModule } from "@app-cmc/shared";
import { NgSelectModule } from "@ng-select/ng-select";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { SimplebarAngularModule } from "simplebar-angular";
import { BadgetsSelectionComponent } from "./badgets-selection/badgets-selection.component";
import { QrCodeFullPreviewModalComponent, QrCodePreviewComponent } from "./qr-code-preview";
import { QrCodeReaderModule } from "ui-kit";
import { RecepientItemComponent } from "./recipient-item/recipient-item.component";
import { RecipientsComponent } from "./recipients/recipients.component";

const maskConfig: Partial<IConfig> = {
  validation: false
};

@NgModule({
  declarations: [
    RecepientItemComponent,
    BadgetsSelectionComponent,
    RecipientsComponent,
    QrCodeFullPreviewModalComponent,
    QrCodePreviewComponent,
    CouponCarouselComponent,
    QrCodeCouponComponent,
    WidgetMessageComponent
  ],
  imports: [
    NgxMaskModule.forRoot(maskConfig),
    CommonModule,
    SharedModule,
    TranslocoRootModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    SimplebarAngularModule,
    QrCodeReaderModule
  ],
  exports: [
    CommonModule,
    SharedModule,
    TranslocoRootModule,
    FormsModule,
    ReactiveFormsModule,
    RecepientItemComponent,
    BadgetsSelectionComponent,
    RecipientsComponent,
    QrCodePreviewComponent,
    CouponCarouselComponent,
    QrCodeCouponComponent,
    WidgetMessageComponent
  ]
})
export class CommonFeatureModule {}
