import { PlatformAccount } from "./platform-account";
import { PlatformAccountBase } from "./platform-account-base";

export class SmsUsaAccount extends PlatformAccountBase {
  public id: string;
  public username: string;
  public password: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public city: string;
  public phone1: number;
  public phoneExt: number;
  public zip: number;
  public address: string;
  public address2: string;
  public companyName: string;
}
