import { Injectable } from "@angular/core";

import { DataService } from "@app-cmc/core/services/data.service";
import { User } from "@app-cmc/models";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class UserService {
  serviceUrl = "user";
  constructor(private http: DataService) {}

  getAll(): Observable<User[]> {
    return this.http.get(`${this.serviceUrl}/allUsers`);
  }
  getById(id: number): Observable<User> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }
  createUser(user: User): Observable<number> {
    return this.http.post(`${this.serviceUrl}/addUser`, user);
  }
  updateUser(user: User) {
    return this.http.post(`${this.serviceUrl}/updateUser`, user);
  }
  deleteUser(userId: number) {
    return this.http.delete(`${this.serviceUrl}/deleteUser/${userId}`);
  }
}
