import { Component, Input } from "@angular/core";
import { LoyaltyCardReward } from "@app-cmc/models";

@Component({
  selector: "app-card-reward",
  templateUrl: "./card-reward.component.html",
  styleUrls: ["./card-reward.component.scss"]
})
export class CardRewardComponent {
  constructor() {}

  @Input() item: LoyaltyCardReward;
}
