<div *transloco="let tc; read: 'common'">
  <button mat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" [disabled]="disabled" class="select-button w-100">
    {{ label }}
    <span
      *ngIf="formControl.value?.length"
      class="selected-value"
      [ngbTooltip]="itemsCountTooltip"
      container="body"
      placement="bottom-right"
      tooltipClass="items-count-tooltip"
    >
      {{ formControl.value.length }}
    </span>
    <ng-template #itemsCountTooltip>
      <div *ngFor="let item of tooltipItems" class="text-truncate">{{ item }}</div>
    </ng-template>
  </button>

  <mat-menu #menu="matMenu" [xPosition]="xPosition" [yPosition]="yPosition" class="filter-select-menu-container position-absolute">
    <div (click)="$event.stopPropagation(); searchInput.focus()" class="menu-content px-2">
      <div class="input-group d-flex p-0">
        <input
          #searchInput
          mat-menu-item
          [class.mat-mdc-menu-item]="false"
          [(ngModel)]="filterText"
          (ngModelChange)="filter()"
          (keydown)="filterInputKeydown($event)"
          [placeholder]="tc('controls.search')"
          class="form-control"
        />
      </div>

      <div class="mt-2 ms-1">
        <div class="form-check checkbox-lg d-inline-block">
          <input
            [(ngModel)]="isAllSelected"
            (ngModelChange)="selectAllChanged($event)"
            type="checkbox"
            id="filter-select-all-items"
            class="form-check-input"
          />
          <label for="filter-select-all-items" class="form-check-label">
            {{ tc("components.selectAll") }}
          </label>
        </div>

        <div class="selection-info d-inline-block float-end me-1">
          {{ tc("components.selected") }} <span> {{ menuSelectedCount }}</span
          >/{{ items.length }}
        </div>
      </div>

      <ngx-simplebar [style.max-height]="itemsMaxHeightPx + 'px'" [style.width]="itemsWidthPx + 'px'" class="splitter mt-1 pt-2">
        <div *ngFor="let item of filteredItems" class="mt-2">
          <div [class.menu-item-selected]="item.isSelected" class="form-check checkbox-lg ms-1">
            <input
              [(ngModel)]="item.isSelected"
              (ngModelChange)="selectionChanged()"
              type="checkbox"
              [id]="'filter-select-item-' + item.value"
              class="form-check-input"
            />
            <label [for]="'filter-select-item-' + item.value" class="form-check-label">
              <div>{{ item.title }}</div>
              <div class="menu-item-description">{{ item.description }}</div>
            </label>
          </div>
        </div>
      </ngx-simplebar>

      <div>
        <div class="action-area splitter mt-1">
          <button (click)="resetClick()" type="button" [disabled]="!menuSelectedCount" class="btn btn-outline-light btn-card-action mt-2">
            {{ tc("controls.reset") }}
          </button>

          <button
            (click)="okClick()"
            type="button"
            [disabled]="!canClear && !menuSelectedCount"
            class="btn btn-primary btn-card-action float-end mt-2"
          >
            {{ tc("controls.ok") }}
          </button>
        </div>
      </div>
    </div>
  </mat-menu>
</div>
