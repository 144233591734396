import { Injectable } from "@angular/core";
import { DataService } from "@app-cmc/core/services/data.service";
import { TwoRewardAccount } from "@app-cmc/models";

import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class TwoRewardService {
  private serviceUrl = "TwoRewardPlatform";
  constructor(private http: DataService) {}
  getAccount(accountId: string): Observable<TwoRewardAccount> {
    return this.http.get(`${this.serviceUrl}/getAccount/${accountId}`);
  }
  getAccountByUsername(username: string): Observable<TwoRewardAccount> {
    return this.http.get(`${this.serviceUrl}/getAccountByUsername/${username}`);
  }
  getAllAccounts(): Observable<TwoRewardAccount[]> {
    return this.http.get(`${this.serviceUrl}/getAccounts`);
  }
  createAccount(account: TwoRewardAccount): Observable<TwoRewardAccount> {
    return this.http.post(`${this.serviceUrl}/createAccount`, account);
  }
  updateAccount(account: TwoRewardAccount): Observable<TwoRewardAccount> {
    return this.http.post(`${this.serviceUrl}/updateAccount`, account);
  }
  deleteAccount(username: string): Observable<TwoRewardAccount> {
    return this.http.post(`${this.serviceUrl}/deleteAccount/${username}`);
  }
}
