export class CouponInfo {
  id: string;
  code: string;
  url: any;
  couponImage: string;
  status: string; // (example | active | archived | template | deleted)
  name: string;
  title: string;
  subtitle: string;
  description: string;
  couponCategoryId: string;
  couponCategoryName: string;
  banner1: string;
  banner2: string;
  banner3: string;
  banner4: string;
  banner5: string;
  subaccount: string;
  logoUrl: string;
  couponTags: string;
  expiryDate: string;
  isSelected: boolean;
}
