import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TranslocoModule } from "@ngneat/transloco";
import { ToggleGroupWithLabelComponent } from "../../components";
import { atLeastOneRequiredValidator, emailValidator, FormComponentsModule, phoneMask, phoneValidator } from "../../form-components";
import { i18nScope, provideUikitTranslocoScope } from "../../utils";

export interface SendingOptionsResult {
  customerEmail?: string;
  customerPhone?: string;
}

enum ContactType {
  Email = "email",
  Phone = "phone"
}
// fixme: temporary until common models lib will be implemented
type ItemValue<T = string> = number | T;
interface ItemWithLabel<T = string> {
  label: string;
  value: ItemValue<T>;
  disabled?: boolean;
}

@Component({
  selector: "app-sending-options",
  templateUrl: "./sending-options.component.html",
  styleUrls: ["./sending-options.component.scss"],
  standalone: true,
  imports: [CommonModule, MatDialogModule, TranslocoModule, FormComponentsModule, ToggleGroupWithLabelComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideUikitTranslocoScope()]
})
export class SendingOptionsComponent {
  readonly i18nScope = i18nScope;
  readonly phoneMask = phoneMask;
  readonly ContactType = ContactType;

  selectedContactType: ContactType = ContactType.Email;
  form = new FormGroup(
    {
      customerEmail: new FormControl("", [emailValidator()]),
      customerPhone: new FormControl("", [phoneValidator(true)])
    },
    [atLeastOneRequiredValidator()]
  );
  contactTypeButtons: ItemWithLabel<ContactType>[] = [
    { label: `${i18nScope}.modal.sendingOptions.emailType`, value: ContactType.Email },
    { label: `${i18nScope}.modal.sendingOptions.phoneType`, value: ContactType.Phone }
  ];

  constructor(private dialogRef: MatDialogRef<SendingOptionsComponent>) {}

  onModeChange(value: string | number): void {
    this.selectedContactType = <ContactType>value;
  }

  onClose(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(this.form.getRawValue());
  }
}
