import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LoyaltyCard } from "@app-cmc/models";

@Component({
  selector: "app-loyalty-card",
  templateUrl: "./loyalty-card.component.html",
  styleUrls: ["./loyalty-card.component.scss"]
})
export class LoyaltyCardComponent {
  constructor() {}

  @Input() type: "simple" | "full" = "full";
  @Input() item: LoyaltyCard;
  @Input() selectedCard: LoyaltyCard;

  @Output() selectCard = new EventEmitter<LoyaltyCard>();

  clickSelect(selectedCard: LoyaltyCard): void {
    if (this.type === "full") {
      this.selectCard.emit(selectedCard);
    }
  }
}
