export type RecipientType = "email" | "phone" | "";
export type RecipientAllowedType = "email" | "phone" | "phoneOrEmail";

export interface RecipientItem {
  value: string;
  valid: boolean;
  loading: boolean;
  type: RecipientType;
}

export class Recipient {
  value: string;
  valid: boolean;
  type: RecipientType;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  purchaseName: string;
  purchaseId: string;
  purchaseCategory: string;
  purchasePrice: number;
  isEdit: boolean;
}
