import { Component, Input } from "@angular/core";
import { CouponInfo } from "@app-cmc/models";
import { CouponSessionStatus } from "@app-cmc/models/coupons";

@Component({
  selector: "app-validate-coupon-details",
  templateUrl: "./validate-coupon-details.component.html",
  styleUrls: ["./validate-coupon-details.component.scss"]
})
export class ValidateCouponDetailsComponent {
  constructor() {}

  @Input() isUsed = false;
  @Input() coupon: CouponInfo;
  @Input() couponStatus: CouponSessionStatus;
}
