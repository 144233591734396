<div class="card">
  <div class="card-body" *transloco="let t; read: 'accessDenied'">
    <div class="p-2">
      <div class="text-center">
        <div class="avatar-md mx-auto">
          <div class="avatar-title rounded-circle bg-light">
            <i class="mdi mdi-lock h1 mb-0 text-primary"></i>
          </div>
        </div>
        <div class="p-2 mt-4">
          <h4>{{ t("title") }}</h4>
          <p>
            {{ t("notPermited") }}<br />
            {{ t("contact") }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div (click)="goToDashboard()" class="mt-3 text-center">
    <p><a class="cursor-pointer fw-medium text-primary">Return to the Dashboard</a></p>
  </div> -->
</div>
