import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "@app-cmc/core/services/account.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
  template: ""
})
export class CallbackComponent implements OnInit, OnDestroy {
  private unsubscriber$ = new Subject<void>();

  constructor(private router: Router, private accSvc: AccountService) {}

  ngOnInit() {
    const message = window.location.hash;
    if (window.parent && window.parent !== window) {
      // if loaded as an iframe during silent refresh
      window.parent.postMessage(message, location.origin);
    } else if (window.opener && window.opener !== window) {
      // if loaded as a popup during initial login
      window.opener.postMessage(message, location.origin);
    }

    this.accSvc.userChanged.pipe(takeUntil(this.unsubscriber$)).subscribe((u) => {
      if (this.accSvc.isLoggedIn) this.router.navigate(["/"]);
    });
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
