import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "@app-cmc/core";
import { Location, LocationShort } from "@app-cmc/models";

@Injectable({ providedIn: "root" })
export class LocationService {
  serviceUrl = "locations";
  constructor(private http: DataService) {}

  getAll(): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}`);
  }

  getLocationsShort(includeLocationIds: number[] = [], top: number = 20, searchValue: string = ""): Observable<LocationShort[]> {
    const params = {
      includeLocationIds,
      top,
      searchValue
    };
    return this.http.get(`${this.serviceUrl}/short`, params);
  }

  getUserLocations(userId: number): Observable<Location[]> {
    return this.http.get(`${this.serviceUrl}/user/${userId}`);
  }

  create(location: Location): Observable<Location> {
    return this.http.post(`${this.serviceUrl}`, location);
  }

  setSelectedLocationLocalStorage(locationId) {
    localStorage.setItem("locationId", JSON.stringify(locationId));
  }

  getSelectedLocationLocalStorage() {
    if (localStorage.getItem("locationId")) return +localStorage.getItem("locationId");
    return null;
  }
}
