import { Component, Input, OnInit } from "@angular/core";
import { StepItem } from "./step-item";

@Component({
  selector: "app-step-wizard",
  templateUrl: "./step-wizard.component.html",
  styleUrls: ["./step-wizard.component.scss"]
})
export class StepWizardComponent implements OnInit {
  @Input() steps: StepItem[];
  constructor() {}

  ngOnInit(): void {}
}
