<header id="page-topbar" class="container" *ngIf="!loginOrLandingOnly">
  <div class="navbar-header" *transloco="let t; read: 'common.topBar'">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="header-item">
        <a class="logo">
          <span class="logo-lg">
            <img [src]="brandLogo" class="image-logo" alt="" />
          </span>
          <span class="logo-sm">
            <img [src]="brandLogo" class="sm-image-logo" alt="" />
          </span>
        </a>
      </div>
      <div *ngIf="windowWidth > mobileScreenWidth" class="d-flex">
        <app-header-select-location [user]="user" *ngIf="user"></app-header-select-location>
      </div>
    </div>

    <div class="d-flex">
      <div *ngIf="user" class="d-inline-block dropdown" ngbDropdown #dropdownRef>
        <button
          ngbDropdownToggle
          type="button"
          class="dropdown-toggle btn header-item waves-effect"
          id="page-header-user-dropdown"
          (click)="toggleDropdown()"
        >
          <span class="d-xl-inline-block font-bold" key="t-henry">{{ user?.name }} {{ user?.family_name }} </span>
          <i class="mdi mdi-chevron-down d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <!-- <a class="dropdown-item" href="#"><i class="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">Profile</span></a>
          <a class="dropdown-item d-block" href="#"><span class="badge bg-success float-end">11</span><i class="bx bx-wrench font-size-16 align-middle me-1"></i> <span key="t-settings">Settings</span></a>-->
          <a class="dropdown-item" (click)="goToProfile()"
            ><i class="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-logout">{{ t("userProfile") }}</span></a
          >

          <ng-container *ngIf="subscription.periodLimit">
            <div class="dropdown-divider"></div>

            <div class="text-item">
              <b>{{ t("creditBalance") }}</b>
            </div>
            <div class="text-item" *ngIf="!subscription.isTrial">
              {{ t("p&bDate") }} <i>{{ periodEndDate }}</i>
            </div>
            <div class="text-item" *ngIf="subscription.isTrial && periodEndDays > 0" style="color: red">
              {{ periodEndDays }} {{ t("trialDaysLeft") }}
            </div>
            <div class="text-item" *ngIf="subscription.isTrial && periodEndDays <= 0" style="color: red">
              {{ t("trialPeriodEnded") }}
            </div>
            <!-- <div class="text-item">
              {{t("dailyLimit")}}
               <i *ngIf="subscription.dailyLimit<1000">{{t("used")}} <b>{{subscription.dailyCount}}</b> {{t("of")}} <b>{{subscription.dailyLimit}}</b> {{t("messages")}}</i>
               <i *ngIf="subscription.dailyLimit>=1000">No Limit*</i>
            </div>-->
            <div class="text-item">
              {{ subscription.isTrial ? t("trialLimit") : t("monthlyLimit") }}
              <i *ngIf="subscription.periodLimit < 30000"
                >{{ t("used") }} <b>{{ subscription.count }}</b> {{ t("of") }} <b>{{ subscription.periodLimit }}</b> {{ t("messages") }}</i
              >
              <i *ngIf="subscription.periodLimit >= 30000">No Limit*</i>
            </div>

            <!--
          <div class="dropdown-divider"  *ngIf="locations.length > 1"></div>

          <a class="dropdown-item"
            *ngIf="locations.length > 1"
            href="/choose-location">
            <i class="bx bx-store font-size-16 align-middle me-1"></i>
            <span>Change location</span>
          </a>
-->
            <div *ngIf="(isAdmin || isManager || isLocationManager) && !isMobileApp">
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="{{ adminPortalUrl }}" target="blank">
                <i class="bx bx-dollar-circle font-size-16 align-middle me-1"></i>
                <span>{{ t("adminPanel") }}</span>
              </a>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="{{ selfServicePortalUrl }}" target="blank">
                <i class="bx bx-dollar-circle font-size-16 align-middle me-1"></i>
                <span>{{ t("myPlan") }}</span>
              </a>
            </div>
          </ng-container>

          <div class="dropdown-divider"></div>

          <a class="dropdown-item" (click)="customerScreen()">
            <img class="dropdown-item__icon me-1" src="assets/images/link.svg" alt="" />
            <span key="t-logout">{{ t("connectToCFS") }}</span></a
          >

          <div class="dropdown-divider"></div>

          <a class="dropdown-item" (click)="updateCFSCarousel()">
            <img class="dropdown-item__icon me-1" src="assets/images/link.svg" alt="" />
            <span key="t-logout">{{ t("updateCFSCarousel") }}</span></a
          >

          <div class="dropdown-divider"></div>

          <a class="dropdown-item text-danger" (click)="logout()"
            ><i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span key="t-logout">{{ t("logout") }}</span></a
          >

          <div class="dropdown-divider"></div>

          <a class="dropdown-item" href="https://clearline.crunch.help" target="_blank">
            <i class="bx bx-help-circle font-size-16 align-middle me-1"></i>
            <span>{{ t("help&FAQ") }}</span>
          </a>
        </div>
      </div>
      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect">
          <!-- <i class="bx bx-cog bx-spin"></i> -->
        </button>
      </div>

      <!-- <div class="dropdown d-inline-block" >
        <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->
    </div>
  </div>
  <div *ngIf="windowWidth <= mobileScreenWidth" class="navbar-header d-block header-item-xs">
    <app-header-select-location [user]="user" *ngIf="user"></app-header-select-location>
  </div>
</header>
