<h4 class="widget-message {{ type }}">
  <div class="widget-message__content">
    <img *ngIf="type === 'fail'" alt="" src="assets/images/x-mark-circle-red.svg" class="widget-message__img" />

    <img *ngIf="type === 'success'" alt="" src="assets/images/x-mark-circle.svg" class="widget-message__img" />

    <span class="widget-message__text">
      <ng-container *ngIf="type === 'success'">
        {{ successMessage }}
      </ng-container>

      <ng-container *ngIf="type === 'fail'">
        {{ failMessage }}
      </ng-container>

      <ng-container *ngIf="(type === 'success' && !successMessage) || (type === 'fail' && !failMessage)">
        <ng-content></ng-content>
      </ng-container>
    </span>
  </div>
</h4>
