import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-tile",
  templateUrl: "tile.component.html",
  styleUrls: ["tile.component.scss"]
})
export class TileComponent {
  @Input() text: string;
  @Input() icon: string;
  @Input() disabled = false;

  @Output() clicked = new EventEmitter();

  click() {
    if (!this.disabled) this.clicked.emit();
  }
}
