export class CouponSessionStatus {
  public claimDateUtc: string;
  public claimtype: string;
  public value: string;

  public claimed: boolean;
  public opened: boolean;
  public played: boolean;
  public savedToAndroidpay: boolean;
  public validated: boolean;
  public voided: boolean;
  public locked: boolean;
  public createdDateUtc: string;
  public openDateUtc: string;
  public playedDateUtc: string;
  public savedToAndroidpayDateUtc: string;
  public validationDateUtc: string;
  public ownValidationCode: string;
  public redeemLocationId: string;
  public unvoidedDateUtc: string;
  public unlockedDateUtc: string;
}
